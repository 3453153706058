import {withStyles} from '@material-ui/styles'
import React from 'react';
import {TopToolbar,CreateButton, List, Datagrid, ImageField, TextField, DateField} from 'react-admin'

const styles = theme =>({
    title:{
        overflow: 'hidden',
        whiteSpace : 'noWrap',
    },
    hhiddenOnSmallScreens :{
        [theme.breakpoints.down('md')]:{
            display : 'none',
        },
    }
})

const ThumbnailImageField = withStyles({image : {height :60}})(ImageField);

const Actions = ({basePath,}) => (
    <TopToolbar>
        <CreateButton basePath={basePath}/>
    </TopToolbar>
)

export default withStyles(styles)(({classes, ...props}) => {
    return (
        <List {...props}
            actions={<Actions/>}
            bulkActionButtons={false}>
            <Datagrid rowClick="edit">
                <TextField source="id" sortable={true}/>
                <ThumbnailImageField source="image" sortable={false}/>
                <TextField source="name" sortable={false}/>
                <DateField source="createdAt" locales='ko' sortable={true}/>
            </Datagrid>
        </List>
    )
})