import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  CardActions, CreateButton, Datagrid, DateField,
  List, TextField,
  Filter, SearchInput
} from 'react-admin'

const styles = theme => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }
})
const ListActions = ({basePath,}) => (
  <CardActions>
    <CreateButton basePath={basePath}/>
  </CardActions>
)

const ListFilter = props => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn/>
  </Filter>
)

export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props}
          actions={<ListActions/>}
          filters={<ListFilter/>}
          bulkActionButtons={false}>
      <Datagrid rowClick="edit">
        <TextField source="id" sortable={false}/>
        <TextField source="name" sortable={false}/>
        <TextField source="url" sortable={false}/>
        <TextField source="accountId" sortable={false}/>
        <DateField source="createdAt" locales='ko' showTime sortable={false}/>
      </Datagrid>
    </List>
  )
})

