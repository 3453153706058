import React from 'react'
import 'moment/locale/ko'
import {createBrowserHistory} from 'history'
import {Admin, Resource} from 'react-admin'
import {reducer as tree} from 'ra-tree-ui-materialui'
import authProvider from './providers/authProvider'
import _dataProvider from './providers/data'
import i18nProvider from './providers/i18n'
import Auth from './custom/auth'
import Layout from './custom/Layout'
import {Dashboard} from './components/dashboard'
import customRoutes from './routes'
import admins from './components/admins'
import blogs from './components/blogs'
import brands from './components/brands'
import boards from './components/boards'
import brandCategories from './components/brandCategories'
import categories from './components/categories'
import events from './components/events'
import goods from './components/goods'
import inspections from './components/inspections'
import keywords from './components/keywords'
import orders from './components/orders'
import prices from './components/prices'
import products from './components/products'
import questions from './components/questions'
import researches from './components/researches'
import reviews from './components/reviews'
import stocks from './components/stocks'
import users from './components/users'
import wishList from './components/wishList'
import affiliations from './components/affiliations'
import emailSettings from './components/emailSettings'
import banners from './components/banners'
import devices from './components/devices'
import shops from './components/shops'
import popups from './components/popups'
import faqs from './components/faqs'
import defaultImages from './components/defaultImages'
import pointsEvents from './components/pointsEvents'
import coupons from './components/coupons'
import notices from './components/notices'
import couponsBases from './components/couponsBases'
import sales from './components/sales'
import stockReviews from './components/stockReviews'
import stocksByProducts from './components/stocksByProducts'
import stocksByGoods from './components/stocksByGoods'
import devPosts from './components/devPosts'
import operatorPosts from './components/operatorPosts'
import businesses from './components/businesses'
import businessReceipts from './components/businessReceipts'
import userSells from './components/userSells'
import userSellOrders from './components/userSellOrders'

export const history = createBrowserHistory()
export const dataProvider = _dataProvider(process.env.REACT_APP_URL)

const App = () => <Admin
  locale='en'
  title={process.env.NODE_ENV === 'production' ? '마이바로 관리자' : '마이바로 관리자 - DEV'}
  dashboard={Dashboard}
  layout={Layout}
  loginPage={Auth}
  authProvider={authProvider}
  dataProvider={dataProvider}
  i18nProvider={i18nProvider}
  customReducers={{tree}}
  customRoutes={customRoutes}
  history={history}>
  {permissions => {
    return permissions === 'manager' ?
      [
        <Resource name='orders' {...orders} />,
        <Resource name='goods' {...goods} />,
        <Resource name='stocks' {...stocks} />,
        <Resource name='wishList' {...wishList} />,
        <Resource name='reviews' {...reviews} />,
        <Resource name='users' {...users} />,
        <Resource name='questions' {...questions} />,
        <Resource name='devices' {...devices} />,
        <Resource name="devPosts" {...devPosts} />,
        <Resource name="operatorPosts" {...operatorPosts} />
      ]
      :
      [
        <Resource name='orders' {...orders} />,
        <Resource name='goods' {...goods} />,
        <Resource name='stocks' {...stocks} />,
        <Resource name='wishList' {...wishList} />,
        <Resource name='reviews' {...reviews} />,
        <Resource name='products' {...products} />,
        <Resource name='categories' {...categories} />,
        <Resource name='inspections' {...inspections} />,
          <Resource name='blogs' {...blogs} />,
        <Resource name='brandCategories' {...brandCategories}/>,
        <Resource name='brands' {...brands} />,
          <Resource name='boards' {...boards} />,
        <Resource name='events' {...events} />,
        <Resource name='users' {...users} />,
        <Resource name='prices' {...prices} />,
        <Resource name='researches' {...researches} />,
        <Resource name='keywords' {...keywords} />,
        <Resource name='questions' {...questions} />,
        <Resource name='affiliations' {...affiliations} />,
        <Resource name='emailSettings' {...emailSettings} />,
        <Resource name='admins' {...admins(permissions)} />,
        <Resource name='banners' {...banners} />,
        <Resource name='devices' {...devices} />,
        <Resource name='shops' {...shops} />,
        <Resource name='files'/>,
        <Resource name='popups' {...popups} />,
        <Resource name='faqs' {...faqs} />,
        <Resource name='sites'/>,
        <Resource name='defaultImages' {...defaultImages} />,
        <Resource name='pointsEvents' {...pointsEvents} />,
        <Resource name='points' />,
        <Resource name='coupons' {...coupons} />,
        <Resource name='couponsBases' {...couponsBases} />,
        <Resource name='notices' {...notices}/>,
        <Resource name='sales' {...sales} />,
        <Resource name='stockReviews' {...stockReviews} />,
        <Resource name='stocksByProducts' {...stocksByProducts} />,
        <Resource name='stocksByGoods' {...stocksByGoods} />,
        <Resource name="devPosts" {...devPosts} />,
        <Resource name="operatorPosts" {...operatorPosts} />,
        <Resource name="businesses" {...businesses} />,
        <Resource name="businessReceipts" {...businessReceipts} />,
        <Resource name="userSells" {...userSells} />,
        <Resource name="userSellOrders" {...userSellOrders} />
      ]
  }}
</Admin>

export default App

