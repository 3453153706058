import React from 'react'

import {
  BooleanField,
  DateField,
  SelectField,
  Show,
  SimpleShowLayout,
  TextField,
  translate,
} from 'react-admin'
import ShowActions from '../../custom/common/ShowActions'

class AdminShow extends React.Component {
  render() {
    const {...props} = this.props
    return (
      <Show {...props} actions={<ShowActions/>}>
        <SimpleShowLayout>
          <TextField source="id"/>
          <TextField source="name"/>
          <TextField source="adminName"/>
          <SelectField source="authority" choices={[
            {id: 'admin', name: '관리자'},
            {id: 'manager', name: '매니저'},
          ]}/>

          <TextField source="affiliation"/>
          <TextField source="email" type='email'/>
          <BooleanField source="emailNoti"/>
          <TextField source="phone" type='phone'/>
          <DateField source="createdAt" locales='ko' showTime/>
          <DateField source="updatedAt" locales='ko' showTime/>
        </SimpleShowLayout>
      </Show>
    )
  }
}

export default translate(AdminShow)
