import React from 'react'
import {
  TopToolbar,
  ListButton,
  Show,
  SimpleShowLayout,
  useShowController,
  TextField,
  SelectField,
  NumberField,
  DateField,
  TabbedShowLayout,
  Tab,
  ReferenceField
} from 'react-admin'
import * as choices from './choices'
import ImageClickField from '../../custom/common/ImageClickField'
import UserInspections from '../../custom/inspection/UserInspections'
import CustomDeleteButton from '../../custom/common/CustomDeleteButton'

const ShowActions = (props) => {
  const {basePath, data} = props
  console.log(data)
  return (
    <TopToolbar>
      <ListButton basePath={basePath}/>
      {
        data?.id && <CustomDeleteButton {...props} record={data}
                                       submitOnEnter={false}
                                       variant="dense"/>
      }
    </TopToolbar>
  )
}

export default (props) => {
  const {record} = useShowController(props)
  return <Show {...props} actions={<ShowActions/>}>
    <SimpleShowLayout>
      <TabbedShowLayout>
        <Tab label="기본 정보">
          <TextField source="id"/>
          <ReferenceField source="user.id" reference="users" link="show" allowEmpty>
            <TextField source="id"/>
          </ReferenceField>
          <TextField source="user.name"/>
          <TextField source="title"/>
          <SelectField source="status" choices={choices.status}/>
          <ImageClickField addLabel={true} source="mainImage"/>
          <ImageClickField addLabel={true} source="detailImages"/>
          <NumberField source="price"/>
          <ReferenceField source="product.id" reference="products" link="show" allowEmpty>
            <TextField source="id"/>
          </ReferenceField>
          <TextField source="product.name"/>
          <TextField source="description"/>
          <NumberField source="likedCount"/>
          <DateField source="createdAt" locales="ko" showTime/>
        </Tab>
        <Tab label="검수 항목">
          <UserInspections inspections={record?.inspections} userInspections={record?.userInspections}/>
        </Tab>
      </TabbedShowLayout>
    </SimpleShowLayout>
  </Show>
}
