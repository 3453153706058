import React, {Component} from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import {translate} from 'react-admin'
import compose from 'recompose/compose'
import withStyles from '@material-ui/core/styles/withStyles'
import moment from 'moment'
import {couriers} from '../../custom/delivery/choices'

const styles = {
  container: {width: '32em', marginBottom: 20},
  rightAlignedCell: {textAlign: 'right'},
  boldCell: {fontWeight: 'bold'},
}

const paymentStatus = {
  registered: '주문완료',
  ready: '가상계좌 발급',
  paid: '결제완료',
  sent: '배송출발',
  delivered: '배송완료',
  cancelled: '결제취소',
}

class UserInspection extends Component {
  render() {
    const {classes, record} = this.props
    return record.statusLog ? <Paper className={classes.container}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>주문상태</TableCell>
            <TableCell>날짜</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {record.statusLog.map((s, i) => (
            <TableRow key={i}>
              <TableCell>{paymentStatus[s.status]}</TableCell>
              <TableCell style={{whiteSpace: 'pre', lineHeight: 1.6}}>
                {
                  s.status === 'sent' &&
                  (couriers.find(courier => courier.id === record.courierCode)).name
                  + (record.trackingNumber ? ': ' + record.trackingNumber : '') + '\n'}
                {moment(s.createdAt).format('YYYY-MM-DD A hh:mm')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper> : ''
  }

}

const enhance = compose(
  translate,
  withStyles(styles),
)

export default enhance(UserInspection)
