const showStatus = [
  {id: 'paid', name: '결제 완료'},
  {id: 'cancelled', name: '결제 취소 완료'},
  {id: 'sent', name: '판매자 발송'},
  {id: 'inStorage', name: '입고 대기'},
  {id: 'import', name: '입고 완료'},
  {id: 'inspection', name: '검수중'},
  {id: 'pass', name: '검수 완료'},
  {id: 'reject', name: '검수 반려'},
  {id: 'export', name: '구매자 발송'},
  {id: 'delivered', name: '구매자 배송 완료'}
]

const status = [
  {id: 'registered', name: '주문 등록'},
  ...showStatus
]

const divisions = [
  {id: 'sell', name: '판매자'},
  {id: 'purchase', name: '구매자'}
]

export {showStatus, status, divisions}
