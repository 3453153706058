import React from 'react'
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"

const getUserInspection = (inspection, userInspections) => {
  const userInspection = userInspections.find((userInspection) => userInspection.id === inspection.id)
  if (userInspection) {
    inspection.option = inspection.options.find((inspection) => inspection.id === userInspection.optionId)
  } else {
    inspection.option = {name: '미선택'}
  }

  return inspection
}

export default (props) => {
  const {inspections, userInspections} = props
  return <Table>
    <TableHead>
      <TableRow>
        <TableCell>ID</TableCell>
        <TableCell>이름</TableCell>
        <TableCell>선택옵션</TableCell>
      </TableRow>
    </TableHead>
    <TableHead>
      {
        inspections?.map((inspection) => {
          inspection = getUserInspection(inspection, userInspections)
          return <TableRow>
            <TableCell>{inspection.id}</TableCell>
            <TableCell>{inspection.name}</TableCell>
            <TableCell>{inspection.option.name}</TableCell>
          </TableRow>
        })
      }
    </TableHead>
  </Table>
}
