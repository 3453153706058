import {withStyles} from '@material-ui/core/styles'
import React, {useState, useEffect} from 'react'
import {dataProvider, history} from "../../App";
import numeral from 'numeral'

import {
    Datagrid,
    Filter,
    ImageField,
    List,
    NumberField,
    TextField,
    translate,
    SelectInput, GET_LIST
} from 'react-admin'

const styles = {
    root: {
        '& > div': {
            overflow: 'visible'
        },
    },
    select: {
        height:'3rem',
        padding: '0 0',
        margin: 0,
        overflow: 'hidden',
        width: '6rem',
    }
}

const ThumbnailImageField = withStyles({image: {height: 60}})(ImageField)

const loadRelatedCategories = (props, change) => {
    let temp = {title:[], size: [], year: [], category: []}
    const {categories, filterValues } = props

    if(change.name === 'brandId') {
        categories.forEach(row => {
            if (row.brandId === change.value) {
                let t_row = {id: (row.id + ','+ row.name), name: row.name}
                temp.title.push(t_row)
            }
        })
        props.setFilters({brandId: change.value})
    } else if(filterValues.brandId) {
        categories.forEach(row => {
            if (row.brandId === filterValues.brandId) {
                let t_row = {id: (row.id + ','+ row.name), name: row.name}
                temp.title.push(t_row)
            }
        })
    }

    if(change.name === 'title') {
        categories.forEach(row => {
            if(row.parentId === Number((change.value).split(',')[0])) {
                let t_row = {id: (row.id + ','+ row.name), name: row.name}
                temp.size.push(t_row)
            }
        })
        props.setFilters({brandId: filterValues.brandId, title: change.value})
    } else if(filterValues.title && change.name !== 'brandId') {
        categories.forEach(row => {
            if(row.parentId === Number((filterValues.title).split(',')[0])) {
                let t_row = {id: (row.id + ','+ row.name), name: row.name}
                temp.size.push(t_row)
            }
        })
    }

    if(change.name === 'size') {
        categories.forEach(row => {
            if(row.parentId === Number((change.value).split(',')[0])) {
                let t_row = {id: (row.id + ','+ row.name), name: row.name}
                temp.year.push(t_row)
            }
        })
        props.setFilters({brandId: filterValues.brandId, title: filterValues.title, size: change.value})
    } else if(filterValues.size && change.name !== 'brandId' && change.name !== 'title') {
        categories.forEach(row => {
            if(row.parentId === Number((filterValues.size).split(',')[0])) {
                let t_row = {id: (row.id + ','+ row.name), name: row.name}
                temp.year.push(t_row)
            }
        })
    }

    if(change.name === 'year') {
        categories.forEach(row => {
            if(row.parentId === Number((change.value).split(',')[0])) {
                let t_row = {id: (row.id + ','+ row.name), name: row.name}
                temp.category.push(t_row)
            }
        })
        props.setFilters({brandId: filterValues.brandId, title: filterValues.title, size: filterValues.size, year: change.value})
    } else if(filterValues.year && change.name !== 'brandId' && change.name !== 'title' && change.name !== 'size') {
        categories.forEach(row => {
            if(row.parentId === Number((filterValues.year).split(',')[0])) {
                let t_row = {id: (row.id + ','+ row.name), name: row.name}
                temp.category.push(t_row)
            }
        })
    }

    const result = {title: temp.title, size: temp.size, year: temp.year, category: temp.category}

    return result
}

const ListActions = (props) => {
    let sumSellCount = 0, sumSellTotalPrice = 0, sumSaleTotalPrice = 0
    props.ids.map(row => {
        sumSellCount += props.data[row].quantity
        sumSellTotalPrice += props.data[row].sellTotalPrice
        sumSaleTotalPrice += props.data[row].saleTotalPrice
    })

    sumSellCount = numeral(sumSellCount).format('0,0')
    sumSellTotalPrice = numeral(sumSellTotalPrice).format('0,0')
    sumSaleTotalPrice = numeral(sumSaleTotalPrice).format('0,0')

    return (
        <div>
            <div style={{textAlign: 'right'}}>총 {sumSellCount}대</div>
            <div style={{textAlign: 'right'}}>매입 {sumSellTotalPrice}원</div>
            <div style={{textAlign: 'right'}}>판매 {sumSaleTotalPrice}원</div>
        </div>

    )
}

const ListFilter = (props) => {
    const [relatedCategories, setRelatedCategories] = useState({title: [], size:[], year:[], category:[]})

    useEffect(() => {
        setRelatedCategories(loadRelatedCategories(props, {name: null, value: null}))
    }, [props.categories])

    const selectInput = (category, index) => {
        return (
        relatedCategories[category].length ?
            <SelectInput source={category}
                         choices={relatedCategories[category]}
                         className={props.classes.select}
                         label={index + "차 카테고리"}
                         onChange={(e) => setRelatedCategories(loadRelatedCategories(props, e.target))}
                         alwaysOn /> : <></>
        )
    }

    return (
        props.brands ? <Filter {...props}>
            <SelectInput source="brandId"
                         choices={props.brands.data}
                         className={props.classes.select}
                         onChange={(e) => setRelatedCategories(loadRelatedCategories(props, e.target))}
                         alwaysOn/>
            {selectInput('title', 1)}
            {selectInput('size', 2)}
            {selectInput('year', 3)}
            {selectInput('category', 4)}
        </Filter> : <Filter>
        </Filter>
    )
}

const handleRowClick = (id) => {
    history.push(`/products/${id}/show`)
}

export default withStyles(styles)(translate(({classes, translate, ...props}) => {
    const [brands, setBrands] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const loadBrands = async () => {
            let options = {
                pagination: {
                    page: 1,
                    perPage: 100
                },
                sort: {
                    field: 'id',
                    order: 'ASC',
                }
            }
            const result = await dataProvider(GET_LIST, 'brands', options)
            setBrands(result);
        }
        const loadCategories = async () => {
            const result = await dataProvider(GET_LIST, 'categories')

            setCategories(result.data)
        }
        loadBrands()
        loadCategories()
    }, [])

    return (
        <List {...props}
              classes={classes}
              actions={<ListActions props={props} />}
              filters={<ListFilter props={props} brands={brands} categories={categories} classes={classes}/>}
              sort={{field: 'saleTotalPrice', order: 'DESC'}}
              perPage={500}
              title='품목별 재고'
              bulkActionButtons={false}>
            <Datagrid rowClick={handleRowClick}>
                <TextField source="id" label="품목 번호" sortable={false} />
                <ThumbnailImageField source="titleImage" label="대표 이미지" sortable={false}/>

                <TextField source="title" label="카" />
                <TextField source="size" label="테" />
                <TextField source="year" label="고" />
                <TextField source="category" label="리" />

                <TextField source="name" label="품목명" sortable={false}/>
                <NumberField source="quantity" label="재고수량" sortable={false}/>
                <NumberField source="sellTotalPrice" label="총 매입금액" />
                <NumberField source="saleTotalPrice" label="총 판매금액" />
            </Datagrid>
        </List>
    )
}))

