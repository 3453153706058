import {
  CREATE,
  DELETE,
  GET_LIST,
  GET_ONE,
  UPDATE,
} from 'react-admin'

export function request(apiUrl, type, params) {
  let url = apiUrl + '/admin/inspections'
  let options = {}
  switch (type) {
    case CREATE:
      options.method = 'POST'
      options.data = params.data
      break
    case UPDATE:
      url += `/${params.id}`
      options.method = 'PUT'
      options.data = params.data
      break
    case GET_LIST:
      const {categoryId, productId} = params
      if (categoryId || productId) {
        options.params = params
      }
      else {
        const {page, perPage} = params.pagination
        const {field, order} = params.sort
        options.params = {
          ...params.filter,
          sort: field,
          order: order,
          start: (page - 1) * perPage,
          perPage: perPage,
        }
      }
      break
    case GET_ONE:
      url += `/${params.id}`
      break
    case DELETE:
      options.method = 'DELETE'
      url += `/${params.id}`
      break
    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res

  switch (type) {
    case GET_LIST:
      ret = data
      break
    case GET_ONE:
      data.options = data.options.sort((a,b) => a.order - b.order)
      ret = {data}
      break
    default:
      if (data) ret = {data}
      break
  }
  return ret
}
