import React from 'react'

import {
  ArrayInput,
  BooleanInput,
  CheckboxGroupInput,
  Create,
  FormDataConsumer,
  GET_LIST,
  GET_ONE,
  ImageField,
  NumberField,
  ImageInput,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  translate,
  DateInput
} from 'react-admin'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import {required} from '../../validators'
import SelectCategories from '../categories/SelectCategories'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'
import {dataProvider, history} from '../../App'
import SelectImageInput from '../../custom/common/SelectImageInput'

const QuantityField = ({classes, label, value, formData}) => {
  return <TextField label={label} margin="normal"
                    disabled value={value}/>
}

const styles = theme => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }
})

const inlineStyles = {
  root: {
    '& .ra-input': {
      display: 'inline-block',
    },
    '& section': {
      display: 'inline-flex',
    }
  }
}

const inlineNumberStyles = {
  root: {
    width: 100
  },
}

const inlineDateInputStyles = {
  root: {
    display: 'inline-block',
    marginRight: 20
  }
}

const InlineNumberInput = withStyles(inlineNumberStyles)(NumberInput)
const InlineArrayInput = withStyles(inlineStyles)(ArrayInput)
const InlineDateInput = withStyles(inlineDateInputStyles)(DateInput)

class GoodsCreate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {categories: [], stock: null, product: null}
    this.handleProductChange = this.handleProductChange.bind(this)
  }

  async componentDidMount() {
    const query = new URLSearchParams(this.props.location.search)
    const stockId = query.get('stockId')

    if (stockId) {
      const {data} = await dataProvider('GET_STOCK', 'goods', {id: stockId})
      if (data.length) {
        alert('이미 생성한 매입 항목입니다.')
        return history.push('/stocks')
      } else {
        const {data: stock} = await dataProvider(GET_ONE, 'stocks', {id: stockId})
        const {data: product} = await dataProvider(GET_ONE, 'products', {id: stock.productId})
        this.setState({stock: stock, product: product})
      }
    }
    try {
      const {data} = await dataProvider(GET_LIST, 'categories', {filter: {}})
      this.setState({categories: data})
    } catch (e) {
    }
  }

  async handleProductChange(formData, dispatch, value) {
    if (formData.productId !== value) {
      const {data} = await dataProvider(GET_ONE, 'products', {id: value})
      this.setState({product: data})
    }
  }

  render() {
    const {translate, classes, ...props} = this.props
    let productId = null
    const {stock, product, categories} = this.state
    let detailImages = []
    let mainImages = []
    let originalPrice = null
    let buyPrice = null
    let serial = null
    let stockId = null

    let marginCheck = false;
    let priceCheck = false;

    if (stock && product) {
      serial = stock.serial
      productId = stock.productId
      mainImages = product.images
      detailImages = stock.images
      originalPrice = stock.price
      buyPrice = stock.price
      stockId = stock.id
    }
    const productSelected = product !== null

    return (
      <Create {...props} actions={<CreateActions/>}>
        <SimpleForm
          toolbar={<CreateToolbar/>}
          defaultValue={{
            parentIds: [], eventIds: [], properties: [], count: 1, optionType: 'none', options: [], serial, stockId,
            additionalGoods: [], mainImages, detailImages, originalPrice, buyPrice, onSale: true, productId,
            isHtmlOverall: false, isTimeSale: false
          }}>
          {!stock && <FormDataConsumer>
            {props => <SelectCategories {...props} categories={categories}/>}
          </FormDataConsumer>}

          <FormDataConsumer>
            {(props) => {
              if (!props.formData.parentIds) return ''
              const categoryId = props.formData.parentIds[props.formData.parentIds.length - 1]
              return <ReferenceInput
                source="productId"
                resource={props.resource}
                filter={{categoryId}}
                disabled={productId !== null}
                reference="products"
                onChange={(e) => this.handleProductChange(props.formData, props.dispatch, e.target.value)}
                validate={required}>
                <SelectInput optionText="name"/>
              </ReferenceInput>
            }
            }
          </FormDataConsumer>

          <TextInput source="name" validate={required}/>
          <TextInput source="serial"/>
          <TextInput source="naverCode"/>
          <NumberInput source="buyPrice" disabled={true}/>

          <FormDataConsumer>
            {({formData, ...rest}) => {
              const {buyPrice, originalPrice} = formData

              if (buyPrice && priceCheck) {
                formData.margin = Math.ceil((originalPrice - buyPrice) / originalPrice * 100)
              }

              return <NumberInput {...rest} source="originalPrice" validate={required} disabled={!productSelected} onChange={(e) => {marginCheck = false; priceCheck = true;}}/>
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({formData, ...rest}) => {
              const {originalPrice, buyPrice, margin} = formData

              if(buyPrice) {
                if(marginCheck) {
                  formData.originalPrice = Math.ceil(buyPrice / (1 - margin / 100) / 1000) * 1000
                } else if(!margin) {
                  formData.margin = Math.ceil((originalPrice - buyPrice) / originalPrice * 100)
                }
                return <NumberInput {...rest} source="margin" label="예상 마진(%)" onChange={(e) => {marginCheck = true; priceCheck = false;}}/>
              }
            }}
          </FormDataConsumer>

          <NumberInput source="discount" validate={required} disabled={!productSelected}/>
          <FormDataConsumer>
            {({formData, ...rest}) => {
              if(!formData.originalPrice && product && product.price) formData.originalPrice = product.price
              if(!formData.mainImages && product && product.mainImages) formData.mainImages = product.mainImages
              const {originalPrice, discount, margin, price} = formData

              if (originalPrice && discount) {
                if (discount < 100 || discount >= 0) {
                  formData.price = Math.ceil(originalPrice * (100 - discount) / 100 / 1000) * 1000
                } else {
                  delete formData.price
                }
              }

              return <NumberInput {...rest} source="price" validate={required} disabled={!productSelected}/>
            }}
          </FormDataConsumer>
          <BooleanInput source="onSale"/>

          <FormDataConsumer>
            {({formData, ...rest}) => {
              if (formData.optionType === 'single') {
                let count = 0
                console.log(formData.options)
                formData.options.forEach(o => {
                  console.log(o)
                  if (o && o.quantity) count += o.quantity
                })
                return <QuantityField label={translate('resources.goods.fields.quantity')}
                                      formData={formData}
                                      value={count}/>
              }
              return <NumberInput label="resources.goods.fields.quantity"
                                  source={'quantity'}
                                  validate={required}/>
            }}
          </FormDataConsumer>

          <RadioButtonGroupInput source="optionType" choices={[
            {id: 'none', name: '설정안함'},
            {id: 'single', name: '설정함'}
          ]}/>

          <FormDataConsumer>
            {({formData, ...rest}) => {
              return formData.optionType === 'single' ?
                <InlineArrayInput label="resources.goods.fields.options" source="options">
                  <SimpleFormIterator>
                    <TextInput label="resources.goods.fields.optionName" source="name" validate={required}/>
                    <InlineNumberInput label="resources.goods.fields.optionPrice"
                                       source="price"
                                       style={{marginLeft: 20}}
                                       validate={required}/>
                    <InlineNumberInput label="resources.goods.fields.optionQuantity"
                                       source="quantity"
                                       style={{marginLeft: 20}}
                                       validate={required}/>
                  </SimpleFormIterator>
                </InlineArrayInput> : ''
            }}
          </FormDataConsumer>

          <CheckboxGroupInput source="properties" choices={[
            {id: 'sale', name: 'SALE'},
            {id: 'new', name: 'NEW'},
            {id: 'recommend', name: '추천'},
            {id: 'direct', name: '직구'}
          ]}/>

          <BooleanInput source="isTimeSale" defualtValue={false}/>

          <FormDataConsumer>
            {({formData}) => {
              return formData.isTimeSale ?
                <>
                  <InlineDateInput className={'date_input'} label="from" source="startDate" validate={required}/>
                  <InlineDateInput className={'date_input'}  label="to" source="endDate" validate={required}/>
                  <InlineNumberInput source="timeSalePrice" validate={required}/>
                </>: ''
            }}
          </FormDataConsumer>

          <ReferenceArrayInput source="eventIds" reference="events">
            <SelectArrayInput optionText="name"/>
          </ReferenceArrayInput>

          {product &&
          <ReferenceInput
            source="titleImage"
            filter={{productId: product.id}}
            reference="files"
            validate={required}
            disabled={!productSelected}>
            <SelectImageInput optionText="src" title="title"/>
          </ReferenceInput>}

          <ImageInput multiple source="mainImages" accept="image/*">
            <ImageField source="src" title="title"/>
          </ImageInput>

          <ImageInput multiple source="detailImages" accept="image/*">
            <ImageField source="src" title="title"/>
          </ImageInput>

          <BooleanInput source="isHtmlOverall"/>
          <TextInput multiline source="overall" validate={required}/>
          <TextInput multiline source="memo"/>

        </SimpleForm>
      </Create>
    )
  }
}

export default translate(withStyles(styles)(GoodsCreate))
