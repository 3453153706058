import React, {useState} from 'react'
import {ThemeProvider} from '@material-ui/styles'
import {createMuiTheme} from '@material-ui/core/styles'
import Login from './Login'
import Admin from './Admin'
import {lightTheme} from '../themes'

const Auth = (props) => {
  const [isAdmin, setIsAdmin] = useState(false)
  const toggleAdmin = () => {
    setIsAdmin((value) => !value)
  }

  return (
    <ThemeProvider theme={createMuiTheme(lightTheme)}>
      {!isAdmin && <Login {...props} toggleAdmin={toggleAdmin} />}
      {isAdmin && <Admin {...props} />}
    </ThemeProvider>
  )
}

export default Auth
