import {GET_LIST, GET_MANY, GET_ONE, GET_MANY_REFERENCE} from 'react-admin'

export async function request(apiUrl, type, params) {
  let url = apiUrl + '/admin/businesses/receipts'
  let options = {}

  switch (type) {
    case GET_MANY_REFERENCE:
    case GET_LIST:
      url += `/list`
      const {categoryId} = params
      if (categoryId) {
        options.params = params
      } else {
        const {page, perPage} = params.pagination
        const {field, order} = params.sort
        options.params = {
          ...params.filter,
          sort: field,
          order: order,
          start: (page - 1) * perPage,
          perPage: perPage
        }
      }
      break
    case GET_ONE:
      url += `/list/${params.id}`
      break
    case GET_MANY:
      if (params.ids.length === 1) url += `/${params.ids[0]}`
      break
    case 'REQUEST_RECEIPT':
      url += `/request`
      options = {
        method: 'post',
        data: params.data
      }
      break
    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res
  switch (type) {
    case GET_MANY_REFERENCE:
    case GET_LIST:
      ret = {data: data.list, total: data.total}
      break
    case GET_MANY:
      if (params.ids.length === 1) ret = {data: [data]}
      else ret = {data}
      break
    case GET_ONE:
      ret = {data}
      break
    default:
      if (data) ret = {data}
      break
  }
  return ret
}
