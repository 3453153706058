import {withStyles} from '@material-ui/core/styles';
import React from 'react';

import {
    TopToolbar, CreateButton, Datagrid, DateField, 
    EditButton, DeleteButton, List, TextField, translate,
    Filter, SelectInput, ReferenceField
  } from 'react-admin'

  const styles = theme => ({
    title: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    hiddenOnSmallScreens: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    select: {
      height:'3rem',
      padding: '0 0',
      margin: 0
    }
  })

  const Actions = ({basePath,}) => (
    <TopToolbar>
      <CreateButton basePath={basePath}/>
    </TopToolbar>
  )
  
  const siteChoices =[
    {id: 'mybaro', name: 'mybaro'},
    {id: 'sell.mybaro', name: 'sell.mybaro'},
  ]
  
  const ListFilter=props =>(
    <Filter {...props}>
      <SelectInput source="site" className={props.classes.select} choices={siteChoices} initialValue='mybaro' alwaysOn/>
    </Filter>
  )
  
  
  export default withStyles(styles)(translate(({classes, ...props}) => {
    return (
      <List {...props}
          // actions={<Actions/>}
            actions={''}
            filters={<ListFilter classes={classes}/>}
          bulkActionButtons={false}>
          <Datagrid rowClick="edit">
            <TextField source="id" sortable={false}/>
            {/*<TextField source="userId" sortable={false}/>*/}
            <ReferenceField source="userId" reference="users" allowEmpty link={'show'}>
              <TextField source="name"/>
            </ReferenceField>
            <TextField source="title" sortable={false}/>
            <DateField source="createdAt" locales='ko' sortable={false}/>
          </Datagrid>
      </List>
    )
  }))