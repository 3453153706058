import React from 'react'
import {ArrayInput, NumberInput, SelectInput, SimpleFormIterator, TextInput, translate,} from 'react-admin'
import {required} from '../../validators'
import get from 'lodash/get'

function handleChange(id, formData, val) {
  const selectedItem = get(formData, id)
  const beforeSelected = formData.options.find(item => item.order === val)
  if (beforeSelected) {
    beforeSelected.order = selectedItem.order
  }
}

const OptionContent = ({id, formData, choices, status}) => {
  return <React.Fragment>
    <TextInput label="name" source={id + '.name'} validate={required}/>
    <NumberInput label="price" source={id + '.price'} style={{marginLeft: 20}} validate={required}/>
    <SelectInput label="order"
                 style={{marginLeft: 20}}
                 translateChoice={false}
                 source={id + '.order'}
                 optionText="id"
                 validate={required}
                 onChange={(e, val) => handleChange(id, formData, val)}
                 choices={choices}/>
    <SelectInput label="status"
                 style={{marginLeft: 20}}
                 translateChoice={false}
                 source={id + '.status'}
                 optionText="id"
                 validate={required}
                 choices={status}/>
  </React.Fragment>
}

class OptionSelect extends React.Component {
  render() {
    const {formData, translate} = this.props
    if (!formData || !formData.options) return ''
    const orderChoices = Array.from({length: formData.options.length})
      .map((v, i) => ({id: i + 1}))
    console.log(orderChoices)
    const status = [{id: 'no'},{id: 'good'},{id: 'soso'},{id: 'bad'}]
    return (
      <ArrayInput source="options"
                  label={translate('resources.inspections.fields.options')}>
        <SimpleFormIterator>
          <OptionContent  choices={orderChoices} status={status} {...this.props}/>
        </SimpleFormIterator>
      </ArrayInput>
    )
  }

}

export default translate(OptionSelect)
