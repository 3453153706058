import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core'
import {crudGetOne} from 'react-admin'
import { makeStyles } from '@material-ui/styles'
import { dataProvider } from '../../../App'
import {useDispatch} from 'react-redux'

const useStyles = makeStyles({
  selectBox: {
    width: '200px',
    marginBottom: '30px',
  },
})

const EnrollDeliveryForm = ({open, onClose, resource, basePath, data: {divisions, ...rest}}) => {
  const classes = useStyles()
  const [division, setDivision] = useState('sell')
  const [deliveryCompanies, setCompanies] = useState([])
  const [courierCode, setCourierCode] = useState('')
  const [trackingNumber, setTrackingNumber] = useState('')
  const dispatch = useDispatch()

  const getDeliveryCompany = (division) => {
    const {delivery, deliveryCouriers} = rest

    const courierCode = delivery[division].courierCode
    const trackingNumber = delivery[division].trackingNumber

    if (courierCode) setCourierCode(courierCode)
    else setCourierCode('')
    if (trackingNumber) setTrackingNumber(trackingNumber)
    else setTrackingNumber('')

    setCompanies(deliveryCouriers)
  }

  useEffect(() => {
    getDeliveryCompany(division)
  }, [open])

  function handleTypeChange(e) {
    const { value } = e.target
    setDivision(value)
    getDeliveryCompany(value)
  }

  const handleCourierChange = (e) => {
    const { value } = e.target
    setCourierCode(value)
  }

  const handleTrackingNumChange = (e) => {
    setTrackingNumber(e.target.value)
  }

  function resetData() {
    getDeliveryCompany(division)
  }

  function handleClose() {
    // resetData()
    onClose()
  }

  const submitDeliveryInfo = async () => {
    if (courierCode === '') {
      alert('택배사를 선택해 주세요.')
      return
    }
    if (trackingNumber === '') {
      alert('송장번호를 입력해 주세요.')
      return
    }

    try {
     const ret = await dataProvider('UPDATE_DELIVERY', 'userSellOrders', {data: {
          division,
          id: rest.id,
          courierCode,
          trackingNumber,
        }})

      if (!ret || ret.status) {
        alert('정보 등록에 실패 했습니다.')
      }
    } catch (error) {
      alert('정보 등록에 실패 했습니다.')
    }
    dispatch(crudGetOne(resource, rest.id, basePath, true))
    handleClose()
  }

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>택배사 등록</DialogTitle>
      <DialogContent>
        <div>
          <InputLabel>구분</InputLabel>
          <Select value={division} onChange={handleTypeChange} className={classes.selectBox}>
            {divisions.map(({ id, name }) => (
              <MenuItem key={id} value={id} >
                {name}
              </MenuItem>
            ))}
          </Select>
        </div><br/>
        <div>
          <InputLabel>택배사</InputLabel>
          <Select value={courierCode} onChange={handleCourierChange} className={classes.selectBox}>
            {deliveryCompanies.map(({ code, name, activated }) => (
              <MenuItem key={code} value={code} disabled={!activated}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <TextField
          className={classes.input}
          value={trackingNumber}
          onChange={handleTrackingNumChange}
          fullWidth
          label="송장번호"
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          취소
        </Button>
        <Button color="primary" onClick={submitDeliveryInfo}>
          등록
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EnrollDeliveryForm
