import {dataProvider} from '../../App'

const axios = require('axios')

const imageHandler = (file, subKey, type) =>
  new Promise((resolve, reject) => {
    const rawFile = subKey ? file[subKey].rawFile : file.rawFile

    getPreSignedUrl(type, rawFile.type)
      .then((result) =>putObject(result, rawFile))
      .then(path =>resolve({src: path}))
      .catch(e => reject(e))
  })


async function getPreSignedUrl(type, mimeType) {
  try {
    const {data: {url, path}} = await dataProvider('UPLOAD', 'files', {data: {type, mimeType}})
    return {url, path}
  } catch (e) {
    throw e
  }
}

async function putObject(info, file) {
  try {
    await axios.put(info.url, file, {headers: {'Content-Type': file.type}})
    return info.path
  } catch (e) {
    throw e
  }
}

export function convertImagePath(path, size) {
  if (size) {
    if (size === 'small') size = 150
    else if (size === 'medium') size = 300
    else if (size === 'large') size = 750

    size = `?w=${size}`
  }
  else size = ''

  return path ? `${process.env.REACT_APP_URL}/v2/images/${encodeURIComponent(path)}${size}` : undefined
}

export function convertSrc(path, size) {
  return {path, src: convertImagePath(path, size)}
}

export async function uploadToS3(data, key, type, subKey) {
  if (!data[key])
    return data
  let arr = data[key]
  if (!Array.isArray(arr)) {
    if (arr.id){
      return data
    }
    else if (!arr.rawFile) {
      delete data[key]
      return data
    }
    return imageHandler(arr, subKey, type)
      .then(ret => {
        data[key] = ret
        return data
      })
  } else if (arr && arr.length) {
    const formerFiles = arr.filter(
      f => {
        const rawFile = subKey ? f[subKey].rawFile : f.rawFile
        return !(rawFile instanceof File)
      }
    ).map(file => {
      const data = {...file}
      delete data[subKey]
      return data
    })
    const newFiles = arr.filter(
      f => {
        const rawFile = subKey ? f[subKey].rawFile : f.rawFile
        return rawFile instanceof File
      }
    )

    const transformedFiles = []
    for (let i = 0; i < newFiles.length; i++) {
      const f = newFiles[i]
      const uploaded = await imageHandler(f, subKey, type)
      transformedFiles.push(uploaded)
    }
    data[key] = [...transformedFiles, ...formerFiles]
  }
  return data
}

export async function uploadImage(data, key, type, subKey) {
  if (data[key]?.rawFile) {
    await uploadToS3(data, key, type, subKey)
    data[key] = data[key].src
  } else if (data[key]?.src) {
    data[key] = data[key].path
  } else {
    data[key] = null
  }
}

export async function uploadImageSrc(data, key, type, subKey) {
  if (data[key]?.rawFile) {
    await uploadToS3(data, key, type, subKey)
  } else if (data[key]?.src) {
    data[key].src = data[key].path
  } else {
    data[key] = {src: null}
  }
}

