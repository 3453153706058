import React from 'react'
import {DateField, Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, NumberInput} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'
import {required} from '../../validators'
import EditActions from '../../custom/common/EditActions'
import EditToolbar from '../../custom/common/EditToolbar'

export default (props) => (
  <Edit {...props} actions={<EditActions/>}>
    <SimpleForm toolbar={<EditToolbar/>}>
      <TextInput disabled source='id'/>
      <ReferenceInput source="siteId" reference="sites" validate={required}>
        <SelectInput optionText="name"/>
      </ReferenceInput>
      <TextInput source="title" validate={required}/>
      <RichTextInput multiline source="content" validate={required}/>
      <NumberInput source="order" defaultValue={1} validate={required}/>
      <DateField source="createdAt" locales='ko' showTime/>
    </SimpleForm>
  </Edit>
)
