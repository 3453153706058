import React from 'react'
import {
  GET_LIST,
} from 'react-admin'
import {dataProvider, history} from '../App'

export default (WrappedComponent) => {
  return class extends React.Component {
    constructor(props) {
      super(props)
      this.state = {brands: [], categories: [], extras: [], inspections: []}
    }

    async componentDidMount() {
      try {
        await Promise.all([
          dataProvider(GET_LIST, 'brands', {filter: {}})
            .then(({data}) => this.setState({brands: data})),
          dataProvider(GET_LIST, 'categories', {filter: {}})
            .then(({data}) => this.setState({categories: data})),
          dataProvider(GET_LIST, 'inspections', {
            filter: {},
            sort: {field: 'name', order: 'ASC'},
            pagination: {
              page: 1,
              perPage: 1000
            }
          }).then(({data}) => this.setState({inspections: data}))
        ])
      } catch (e) {
        if (e && e.status === 401) {
          history.push('/login')
        }
      }
    }

    render() {
      const {categories, extras, inspections, brands} = this.state
      return <WrappedComponent categories={categories} brands={brands}
                               extras={extras} inspections={inspections} {...this.props} />
    }
  }
}
