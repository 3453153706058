import React, {useState} from 'react'
import {Button as AdminButton, crudGetOne} from 'react-admin'
import Button from '@material-ui/core/Button'
import ContentCreate from '@material-ui/icons/Create'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {dataProvider} from '../../App'
import {useDispatch} from 'react-redux'
import {status} from './choices'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles({
  dialog: {
    width: '20%'
  },
  button: {}
})

export default (props) => {
  const [isOpen, setOpen] = useState(false)
  const [value, setValue] = useState(null)
  const dispatch = useDispatch()
  const classes = useStyles()

  async function updateBusiness() {
    const {basePath, record, resource} = props
    const {data} = await dataProvider('UPDATE_STATUS', resource, {id: record.id, data: {status: value}})
    if (data) {
      dispatch(crudGetOne(resource, record.id, basePath, true))
    } else {
      alert('상태 변경을 실패 하였습니다.')
    }
  }

  function handleClick() {
    setOpen(true)
    setValue(props.record.status)
  }

  function handleCancel() {
    setOpen(false)
  }

  async function handleDone(e) {
    e.stopPropagation()
    await updateBusiness()
    handleCancel()
  }

  function handleChange(e, v) {
    setValue(v)
  }

  return (<div>
    <AdminButton label="상태" onClick={handleClick}>
      <ContentCreate/>
    </AdminButton>
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={isOpen}
      classes={{paper: classes.dialog}}
    >
      <DialogTitle id="confirmation-dialog-title">상태 변경</DialogTitle>
      <DialogContent>
        <RadioGroup
          aria-label="Status"
          name="status"
          value={value}
          onChange={handleChange}
        >
          {status.map(({id, name}) => (
            <FormControlLabel value={id} key={id} control={<Radio/>} label={name}/>
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          취소
        </Button>
        <Button onClick={handleDone} color="primary">
          확인
        </Button>
      </DialogActions>
    </Dialog>
  </div>)
}
