const types = [
  {id: "none", name: "없음"},
  // {id: "join", name: "회원 가입"},
  // {id: "delivered", name: "배송 완료"},
  // {id: "link", name: "링크"},
]

const benefitTypes = [
  {id: "rate", name: "할인율"},
  {id: "price", name: "할인 가격"}
]

const expireTypes = [
  {id: "term", name: "기간"},
  {id: "date", name: "날짜"}
]

export {types, benefitTypes, expireTypes}
