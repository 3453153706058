import React from 'react'
import {
  Create,
  FormDataConsumer,
  GET_LIST,
  GET_ONE,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  translate
} from 'react-admin'
import {required} from '../../validators'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'
import SingeDatePicker from '../../custom/common/SingeDatePicker'
import withProductRelations from '../../hocs/withProductRelations'
import SelectCategories from '../categories/SelectCategories'
import {dataProvider} from '../../App'
import {change} from 'redux-form'

class ResearchCreate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {inspections: [], product: null}
    this.handleProductChange = this.handleProductChange.bind(this)
  }

  handleProductChange(formData, dispatch, value) {
    if (formData.productId !== value) {
      dataProvider(GET_ONE, 'products', {id: value})
        .then(({data}) => {
          this.setState({product: data})
        })
      dataProvider(GET_LIST, 'inspections', {productId: value})
        .then((data) => {
          this.setState({inspections: data})
          const initInspections = data.map(({id}) => {
            return {id}
          })
          dispatch(change('record-form', 'inspections', initInspections))
        })
    }
  }

  render() {
    const {inspections} = this.state
    const {categories, translate, ...props} = this.props
    return (<Create {...props} actions={<CreateActions/>}>
      <SimpleForm toolbar={<CreateToolbar/>}
                  defaultValue={{parentIds: [], inspections: []}}>
        <FormDataConsumer>
          {props => <SelectCategories {...props} categories={categories}/>}
        </FormDataConsumer>

        <FormDataConsumer>
          {(props) => {
            if (!props.formData.parentIds) return ''
            const categoryId = props.formData.parentIds[props.formData.parentIds.length - 1]
            return <ReferenceInput
              source="productId"
              resource={props.resource}
              filter={{categoryId}}
              reference="products"
              onChange={(e, value) => this.handleProductChange(props.formData, props.dispatch, value)}
              validate={required}>
              <SelectInput optionText="name"/>
            </ReferenceInput>
          }
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {({formData}) => {
            if (!formData.inspections) return null
            return inspections.map((inspection, index) => {
                return (<SelectInput key={index}
                                     label={inspection.name}
                                     style={{marginRight: 20}}
                                     source={`inspections[${index}].optionId`}
                                     choices={inspection.options}
                                     optionValue={'id'}
                                     validate={required}/>)
              }
            )
          }}
        </FormDataConsumer>

        <RadioButtonGroupInput source="source" choices={[
          {id: 'it24', name: '아이티샵'},
          {id: 'joonggo', name: '중고나라'},
        ]} validate={required}/>
        <RadioButtonGroupInput source="type" choices={[
          {id: 'buy', name: '매입'},
          {id: 'sell', name: '판매'},
        ]} validate={required}/>
        <SingeDatePicker source="date" label={translate('resources.researches.fields.date')}
                         options={{format: 'YYYY-MM-DD'}}
                         numberOfMonths={1}
                         monthFormat="YYYY MMMM"
                         weekDayFormat="ddd"
                         renderMonthElement={(date) => date.month.locale('ko').format('YYYY MMMM')}
                         hideKeyboardShortcutsPanel={true}
                         validate={required}
        />
        <NumberInput source="price" validate={required}/>
        <TextInput source="memo"/>
      </SimpleForm>
    </Create>)
  }
}

export default translate(withProductRelations(ResearchCreate))
