import React, {useState, useEffect} from 'react';
import {Create, SimpleForm, TextInput, ImageInput, ImageField, SelectInput, DateField, GET_LIST} from 'react-admin'
import {required} from "../../validators";
import CreateActions from '../../custom/common/CreateActions';
import CreateToolbar from '../../custom/common/CreateToolbar';
import {dataProvider} from "../../App";

export default (props) => {
  const [siteChoices, setSiteChoices] = useState([]);

  useEffect(()=>{
    dataProvider(
      GET_LIST,
      'sites',
      {}
    ).then(result => setSiteChoices(result.data));
  }, [])

  return (
    <Create {...props} actions={<CreateActions/>}>
    <SimpleForm toolbar={<CreateToolbar/>}>
        <TextInput source='title' label="제목" validate={required}/>
        <TextInput source='subTitle' label="부제" validate={required}/>
        <ImageInput source="image" label="이미지">
            <ImageField source="src" title="title"/>
        </ImageInput>
        <TextInput source='link' label="링크" validate={required}/>
    </SimpleForm>
  </Create>
)}