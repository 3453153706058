import React from 'react'
import {DateField, Edit, SimpleForm, ImageInput, translate,
  ImageField, SelectInput, TextInput, GET_LIST} from 'react-admin'
import {required} from '../../validators'
import EditActions from '../../custom/common/EditActions'
import EditToolbar from '../../custom/common/EditToolbar'
import {dataProvider} from "../../App";
import * as choices from './choices'

class DefaultImageEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {division: []}
  }

  async componentDidMount() {
    const division = choices.division

    const result = await dataProvider(GET_LIST, 'defaultImages', {filter: {}})

    result.data.forEach(data => {
      division.forEach(item => {
        if (item.id === data.division) {
          item.disabled = true
        }
      })
    })

    this.setState({division})
  }

  render() {
    const {division} = this.state
    return <Edit {...this.props} actions={<EditActions/>}>
      <SimpleForm
        toolbar={<EditToolbar/>}>
        <TextInput disabled source='id'/>
        <SelectInput source="division" choices={division} validate={required}/>
        <ImageInput source="image" accept="image/*" validate={required}>
          <ImageField source="src" title="title"/>
        </ImageInput>
        <DateField source="createdAt" locales='ko' showTime/>
      </SimpleForm>
    </Edit>
  }
}

export default translate(DefaultImageEdit)
