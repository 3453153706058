import BookIcon from '@material-ui/icons/Book'
import ProductCreate from './ProductCreate'
import ProductEdit from './ProductEdit'
import ProductList from './ProductList'
import ProjectShow from './ProductShow'

export default {
  list: ProductList,
  create: ProductCreate,
  edit: ProductEdit,
  show: ProjectShow,
  icon: BookIcon,
}
