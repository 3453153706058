import React from 'react'
import {
  TopToolbar,
  DateField,
  ListButton,
  SelectField,
  Show,
  SimpleShowLayout,
  TextField,
  translate,
  TabbedShowLayout,
  Tab,
  ReferenceField,
  NumberField,
  ArrayField,
  Datagrid
} from 'react-admin'
import {status} from './choices'

const ShowActions = ({basePath}) => {
  return (
  <TopToolbar>
    <ListButton basePath={basePath}/>
  </TopToolbar>
)}

class BusinessReceiptShow extends React.Component {
  render() {
    const {translate, ...props} = this.props
    return <Show {...props} actions={<ShowActions/>}>
      <SimpleShowLayout>
        <TabbedShowLayout>
          <Tab label="기본 정보">
            <TextField source="id" />
            <SelectField source="status" choices={status} />
            <ReferenceField label="resources.businesses.fields.name" source="businessId" reference="businesses" allowEmpty link={'show'}>
              <TextField source="name"/>
            </ReferenceField>
            <NumberField source="price" />
          </Tab>
          <Tab label="현금 영수증 상품 목록">
            <ArrayField source="stocks" addLabel={false}>
              <Datagrid>
                <TextField source="id"/>
                <TextField source="product.name"/>
                <NumberField source="price"/>
                <DateField source="createdAt" locales='ko' showTime/>
              </Datagrid>
            </ArrayField>
          </Tab>
        </TabbedShowLayout>
      </SimpleShowLayout>
    </Show>
  }
}

export default translate(BusinessReceiptShow)
