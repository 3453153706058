import React from 'react'
import {
  ArrayInput,
  SimpleFormIterator,
  ImageInput,
  ImageField,
  SelectInput,
  TextInput,
  NumberInput
} from 'react-admin'
import {required} from '../../validators'
import {bannerTypes} from './choices'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles({
  inline: {
    display: 'inline-flex',
    width: '100%'
  },
  path: {
    width: '400px',
    marginLeft: 20
  },
  order: {
    width: '100px',
    marginLeft: 20
  }
})

export default (props) => {
  const {source} = props
  console.log(props)
  const classes = useStyles()
  return (
    <React.Fragment>
      <ImageInput label="resources.products.fields.image"
                  source={`${source}[image]`}
                  accept="image/*"
                  style={{display: 'block'}}
                  validate={required}>
        <ImageField source="src" title="title"/>
      </ImageInput>
      <div className={classes.inline}>
        <SelectInput label="resources.products.fields.type"
                     source={`${source}[type]`}
                     choices={bannerTypes}
                     validate={required}/>
        <TextInput label="resources.products.fields.path"
                   source={`${source}[path]`}
                   className={classes.path} />
        <NumberInput label="resources.products.fields.order"
                     source={`${source}[order]`}
                     className={classes.order}
                     validate={required}/>
      </div>
    </React.Fragment>
  )
}
