import React from 'react'
import {
  DateField,
  Show,
  TextField,
  SimpleShowLayout,
  SelectField,
  BooleanField,
  NumberField,
  useShowController
} from 'react-admin'
import * as choices from './couponChoices'
import ShowActions from '../../custom/common/ShowActions'

export default (props) => {
  const {record} = useShowController(props)
  return (
    <Show {...props} actions={<ShowActions/>}>
      <SimpleShowLayout>
        <TextField disabled source='id'/>
        <TextField source='code'/>
        <TextField source='name'/>
        {/*<ReferenceField source="categoryId" reference="categories" allowEmpty link="show">*/}
        {/*  <TextField source="id"/>*/}
        {/*</ReferenceField>*/}
        <SelectField source="type" choices={choices.types}/>
        {
          record && record.rate ?
            <NumberField source="rate"/> :
            <NumberField source="price"/>
        }
        {
          record && record.term &&
            <NumberField source="term"/>
        }
        {
          record && record.startDate &&
            <DateField source="startDate"/>
        }
        {
          record && record.endDate &&
          <DateField source="endDate"/>
        }
        <NumberField source="conditionPrice"/>
        <NumberField source="limit"/>
        <NumberField source="count"/>
        <TextField source="description"/>
        <DateField source="closeDate" locales='ko' showTime/>
        <DateField source="createdAt" locales='ko' showTime/>
      </SimpleShowLayout>
    </Show>
  )
}
