import React from 'react'
import {Create, NumberInput, SimpleForm, TextInput, SelectInput} from 'react-admin'
import {required} from '../../validators'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'

const siteChoices =[
  {id : 'mybaro', name : 'mybaro'},
  {id : 'sell.mybaro', name : 'sell.mybaro'}
]

export default (props) => (
  <Create {...props} actions={<CreateActions/>}>
    <SimpleForm
      toolbar={<CreateToolbar/>}>
      <TextInput source="keyword" validate={required}/>
      <NumberInput source="order" validate={required}/>
      <SelectInput source="site" choices={siteChoices} validate={required}/>
    </SimpleForm>
  </Create>
)
