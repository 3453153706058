const description = [
  {id: 'buy_goods', name: '상품 구매 적립'},
  {id: 'success_payment', name: '결제 시 차감'},
  {id: 'cancel_payment', name: '결제 취소 후 적립금 차감'},
  {id: 'cancel_goods', name: '결제 취소 후 적립금 반환'},
  {id: 'welcome', name: '신규 가입 포인트'},
  {id: 'write_review', name: '상품평 적립'},
  {id: 'recommend', name: '친구 추천 적립'},
  {id: 'recommended', name: '추천된 친구 적립'}
]

export {description}
