import React from 'react'
import {
  TopToolbar,
  DateField,
  ListButton,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  translate,
} from 'react-admin'

const ShowActions = ({basePath, resource, data = {}, ...props}) => {
  return (
    <TopToolbar>
      <ListButton basePath={basePath}/>
    </TopToolbar>
  )
}

class WishListShow extends React.Component {
  render() {
    const {translate, ...props} = this.props
    return <Show {...props} actions={<ShowActions/>}>
      <SimpleShowLayout>
        <ReferenceField source="userId" reference="users" link="show">
          <TextField source="name"/>
        </ReferenceField>
        <ReferenceField source="productId" reference="products" link="show">
          <TextField source="name"/>
        </ReferenceField>
        <ReferenceField source="goodsId" reference="goods" link="show" allowEmpty>
          <TextField source="name"/>
        </ReferenceField>
        <TextField source="memo"/>
        <DateField source="expireAt" locales="ko" showTime/>
        <DateField source="createdAt" locales="ko" showTime/>
      </SimpleShowLayout>
    </Show>
  }
}

export default translate(WishListShow)
