import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {BooleanField, TopToolbar, Datagrid, DateField, Filter, List, SelectInput, TextField,} from 'react-admin'
import StarRatingField from '../../custom/common/StarRatingField'

const styles = theme => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  select: {
    height:'3rem',
    padding: '0 0',
    margin: 0
  }
})

const Actions = ({basePath,}) => (
  <TopToolbar>
  </TopToolbar>
)

const ListFilter = props => (
  <Filter {...props}>
    <SelectInput source="replied" className={props.classes.select}choices={[
      {id: false, name: '답변안함'},
      {id: true, name: '답변함'}
    ]} alwaysOn/>
  </Filter>
)

export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props}
          filters={<ListFilter classes={classes}/>}
          perPage={25}
          actions={<Actions/>}
          bulkActionButtons={false}>
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false}/>
        <TextField source="name" sortable={false}/>
        <StarRatingField source="rating" sortable={false}/>
        <BooleanField source="replied" sortable={false}/>
        <DateField source="createdAt" locales='ko' showTime sortable={false}/>
      </Datagrid>
    </List>
  )
})

