import React from 'react'

import {
  BooleanInput,
  Create,
  FormDataConsumer,
  FormTab,
  ImageField,
  ImageInput,
  NumberInput,
  SelectInput,
  TabbedForm,
  TextInput,
  translate,
  GET_LIST,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  AutocompleteInput
} from 'react-admin'
import {required} from '../../validators'
import withProductRelations from '../../hocs/withProductRelations'
import RelateCategories from '../categories/RelatedCategories'
import RelatedExtras from './RelatedExtras'
import RelatedInspections from '../inspections/RelatedInspections'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'
import {dataProvider} from '../../App'
import withStyles from '@material-ui/core/styles/withStyles'
import ProductBanners from "./ProductBanners";

const validateCreation = (values) => {
  const errors = {}
  if (values.parentIds.length === 0) {
    errors.parentIds = ['카테고리는 필수입니다.']
  }
  return errors
}

const defaultExtras = [
  {name: 'CPU', order: 1},
  {name: 'RAM', order: 2},
  {name: 'SSD', order: 3},
  {name: 'HDD', order: 4},
  {name: 'Color', order: 5}
]

const inlineStyles = {
  root: {
    '& .ra-input': {
      display: 'inline-block',
    },
    '& section': {
      display: 'inline-flex',
    }
  }
}

const inlineNumberStyles = {
  root: {
    '& .ra-input': {
      display: 'inline-block'
    },
    display: 'inline-block',
    width: 100
  },
}

const InlineTextInput = withStyles(inlineNumberStyles)(TextInput)
const InlineNumberInput = withStyles(inlineNumberStyles)(NumberInput)
const InlineSelectInput = withStyles(inlineNumberStyles)(SelectInput)
const InlineSimpleShowLayout = withStyles(inlineStyles)(SimpleFormIterator)

class ProductCreate extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      brands : [],
    }
  }
  async componentDidMount(){
    const brandResult = await dataProvider(
      GET_LIST,
      "brands",
      {
        filter:{},
        pagination :{page :1, perPage: 1000},
        sort : {field : "id", order:"ASC"}
      })
    this.setState({ brands : brandResult.data, })
  }

  render() {
    const {categories, extras, inspections, translate, ...props} = this.props
    const {brands} = this.state;

    return (
      <Create {...props} actions={<CreateActions/>}>
        <TabbedForm
          validate={validateCreation}
          toolbar={<CreateToolbar/>}
          defaultValue={{parentIds: [], extras: defaultExtras, inspections: [], images: [], onDeal: false}}>
          <FormTab label={translate('resources.products.summary')}>

            <SelectInput source="brandId"
                         optionText="name"
                         choices={brands}
                         validate={required}
                         allowEmpty/>
            <FormDataConsumer>
              {props => <RelateCategories {...props} categories={categories} where={"product"}/>}
            </FormDataConsumer>
            <TextInput source="title" validate={required}/>
            <TextInput source="name" validate={required}/>
            <TextInput source="prefix" validate={required}/>
            <NumberInput source="price" validate={required}/>
            <TextInput source="code"/>
            <TextInput source="size"/>
            <TextInput source="year"/>
            <BooleanInput source="onDeal"/>

            <ImageInput source="titleImage" accept="image/*" validate={required}>
              <ImageField source="src" title="title"/>
            </ImageInput>

            <ImageInput multiple source="images" accept="image/*">
              <ImageField source="src" title="title"/>
            </ImageInput>
          </FormTab>
          <FormTab label={translate('resources.products.extras')}>
            <FormDataConsumer>
              {props => <RelatedExtras {...props}/>}
            </FormDataConsumer>
          </FormTab>
          <FormTab label={translate('resources.inspections.name')}>
            <FormDataConsumer>
              {props => <RelatedInspections {...props} inspections={inspections}/>}
            </FormDataConsumer>
          </FormTab>
          <FormTab label="resources.products.fields.banners">
            <ArrayInput source="banners">
              <SimpleFormIterator>
                <ProductBanners {...props} />
              </SimpleFormIterator>
            </ArrayInput>
          </FormTab>
          <FormTab label="resources.products.fields.blogs">
            <ArrayInput source="blogs" addLabel={false}>
              <SimpleFormIterator>
                <ReferenceInput
                  label={'resources.blogs.fields.id'}
                  source="id"
                  reference="blogs"
                  perPage={10}
                  alwaysOn
                  validate={required}
                >
                  <AutocompleteInput optionText="title" optionValue="id" />
                </ReferenceInput>
              </SimpleFormIterator>
            </ArrayInput>
          </FormTab>
        </TabbedForm>
      </Create>
    )
  }
}

export default translate(withProductRelations(ProductCreate))
