import React from 'react'
import {DateField, Edit, SimpleForm, TextInput, FormDataConsumer, ImageInput, ImageField} from 'react-admin'
import {required} from '../../validators'
import EditActions from '../../custom/common/EditActions'
import EditToolbar from '../../custom/common/EditToolbar'
import SelectCategories from './SelectCategories';

export default (props) => {
  return (
  <Edit {...props} actions={<EditActions/>}>
    <SimpleForm toolbar={<EditToolbar/>}>
      <TextInput disabled source='id'/>
      <TextInput source="name" validate={required}/>

      <FormDataConsumer>
        {props=><SelectCategories {...props}/>}
      </FormDataConsumer>
      
      <ImageInput source="image">
        <ImageField source="src" title="title"/>
      </ImageInput>
      
      <TextInput source="keywords"/>

      <DateField source="createdAt" locales='ko' showTime/>
    </SimpleForm>
  </Edit>
)}
