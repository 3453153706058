import React from 'react';
import compose from 'recompose/compose';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {withStyles} from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import {translate} from 'react-admin';

const styles={
    stock:{
        display:'flex',
        justifyContent:'space-between',
        paddingLeft : 8,
        paddingRight : 8,
        minWidth : 390,
    },
    stockInfo:{
        minWidth : 350,
    },
    stockGood:{
        fontSize: '0.8rem',
        fontWeight: 400,
        lineHeight: 1.43,
        letterSpacing: '0.01071em',
        color: 'rgba(0, 0, 0, 0.54)',
    },
    stockStatus:{
        display:'flex',
        justifyContent : 'center',
        minWidth : 90,
    },
}

function StockCardList(props){
    const {stocks}=props;

    return(
        <List>
            {stocks.map(stock =>{
                let status;
                if(stock.status === 'registered'){
                    status='접수'
                }else if(stock.status === 'waiting'){
                    status='입고대기'
                }else if(stock.status === 'inspected'){
                    status='검수완료'
                }
                    return(
                        <ListItem
                            key={stock.id}
                            button
                            component={Link}
                            to={`/stocks/${stock.id}/show`}
                            className={props.classes.stock}
                        >
                            <div>
                                <ListItemText 
                                    primary={`${new Date(stock.createdAt).toLocaleString()} `}
                                    secondary={`by ${stock.seller.name}`}
                                    className={props.classes.stockInfo}
                                />
                                <div className={props.classes.stockGood}>{stock.product.name}</div>
                            </div>
                            <ListItemText 
                                primary={ `${status}`}
                                className={props.classes.stockStatus}
                            />
                        </ListItem>
                    )
                })}
        </List>
    )
}


const enhance=compose(
    withStyles(styles),
    translate,
);

export default enhance(StockCardList);