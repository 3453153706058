import React, {Fragment, useEffect} from 'react'

import {
    TopToolbar,
    Tab,
    TabbedShowLayout,
    Show
} from 'react-admin'
import withStyles from '@material-ui/core/styles/withStyles'

import PurchaseList from './PurchaseList';
import SellList from './SellList';

const ShowActions = ({basePath, data = {}, redirectUrl}) => {
    return (
        <TopToolbar>
        </TopToolbar>
    )
}

const SalesShow = (props) => {

    return (
        <Show {...props} actions={<ShowActions />}>
            <TabbedShowLayout>
                <Tab label="매입 정보">
                    <PurchaseList props={props}/>
                </Tab>
                <Tab label="판매 정보">
                    <SellList props={props}/>
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
}

export default SalesShow;
