import React, {Fragment} from 'react'
import {Link} from 'react-router-dom';
import {
  ArrayField,
  Button,
  TopToolbar,
  CardContentInner,
  Datagrid,
  ListButton,
  NumberField,
  ReferenceField,
  SelectField,
  ShowController,
  Tab,
  TabbedShowLayout,
  TextField,
  TitleForRecord,
  translate,
} from 'react-admin'
import withStyles from '@material-ui/core/styles/withStyles'
import Reply from '@material-ui/icons/Reply'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import PhoneField from '../../custom/common/PhoneField'
import ImageClickField from '../../custom/common/ImageClickField'
import UserInspection from './UserInspection'
import StockEditButton from './StockEditButton'
import StockMemoButton from "./StockMemoButton";
// import SendSMSButton from './SendSMSButton'

const SubPriceField = ({record, locales}) => {
  if (record.inspections) {
    const price = record.inspections.reduce((prev, curr) => {
      return prev + curr.price
    }, 0)
    return <Typography>{price.toLocaleString(locales)}</Typography>
  }
  return null
}

const PhoneSMSField = (props) => {
  return (
    <Fragment>
      <PhoneField {...props} style={{display: 'inline'}}/>
      {/*<SendSMSButton id={props.record.productId}/>*/}
    </Fragment>
  )
}

const InspectionOptionField = (props) => (
  <SelectField {...props} choices={props.record.options}/>
)

const ShowActions = ({basePath, data = {}, ...rest}) => {
  return (
    <TopToolbar>
      {!data.goodsId && data.status === 'paid' && <Button
        component={Link}
        to={`/goods/create?stockId=${data.id}`}
        label="상품 생성"><Reply/>
      </Button>}
      <ListButton basePath={basePath}/>
      <StockMemoButton basePath={basePath} record={data}/>
      <StockEditButton basePath={basePath} record={data} value={data.status}/>
    </TopToolbar>
  )
}

const styles = {
  root: {display: 'flex', alignItems: 'flex-start'},
  form: {flexGrow: 3, marginRight: '2em'},
}


class StockShow extends React.Component {
  render() {
    const {classes, translate, ...props} = this.props
    return (
      <ShowController {...props}>
        {({isLoading, defaultTitle, ...controllerProps}) => {
          const {record} = controllerProps
          return record ? (
            <div className={classes.root}>
              <Card className={classes.form}>
                <TitleForRecord
                  defaultTitle={defaultTitle}
                  record={record}
                />
                <CardContentInner>
                  <ShowActions basePath={controllerProps.basePath} data={record}/>
                </CardContentInner>
                <TabbedShowLayout {...controllerProps}>
                  <Tab label={translate('resources.products.summary')}>
                    <TextField source="id"/>

                    <ReferenceField source="goodsId" reference="goods" link="show" allowEmpty>
                      <TextField source="id"/>
                    </ReferenceField>

                    <ReferenceField source="sellerId" reference="users" link="show" allowEmpty>
                      <TextField source="name"/>
                    </ReferenceField>

                    <ReferenceField source="productId" reference="products" link="show">
                      <TextField source="name"/>
                    </ReferenceField>
                    <ReferenceField label={translate('resources.stocks.fields.inspector.name')}
                                    source="inspectorId" reference="admins" link="show" allowEmpty>
                      <TextField source="name"/>
                    </ReferenceField>

                    <SelectField source="status" choices={[
                      {id: 'registered', name: '접수'},
                      {id: 'waiting', name: '입고대기'},
                      {id: 'warehousing', name: '입고'},
                      {id: 'inspected', name: '검수완료'},
                      {id: 'contactWaiting', name: '연락대기중'},
                      {id: 'boxSent', name: '박스발송완료'},
                      {id: 'adminCanceled', name: '거래취소'},
                      {id: 'agreed', name: '고객동의'},
                      {id: 'paid', name: '입금완료'},
                      {id: 'cancelled', name: '취소'},
                    ]}/>
                    <TextField source="serial"/>
                    <TextField source="memo"/>
                    <ImageClickField label={translate('resources.stocks.fields.systemImage')}
                                     source="systemImage.src" title="title" addLabel={true}/>
                    <ImageClickField source="images" src="src" addLabel={true}/>
                  </Tab>
                  <Tab label='판매자 정보'>
                    <TextField source="seller.name"/>
                    <PhoneSMSField source="seller.phone"/>
                    <TextField source="seller.bank"/>
                    <TextField source="seller.bankAccount"/>
                    <TextField source="seller.bankHolder"/>
                    <TextField source="seller.email"/>
                    <TextField source="seller.birth"/>
                    <TextField source="seller.address.zip"/>
                    <TextField source="seller.address.basic"/>
                    <TextField source="seller.address.detail"/>
                  </Tab>
                  {(record.status !== 'registered' && record.status !== 'waiting'
                  && record.status !== 'warehousing' ) && <Tab label={translate('resources.inspections.name')}>
                    <ArrayField source="inspections" addLabel={false}>
                      <Datagrid>
                        <TextField source="id"
                                   label={translate('resources.stocks.fields.inspections.id')}
                                   sortable={false}/>
                        <TextField source="name"
                                   label={translate('resources.stocks.fields.inspections.name')}
                                   sortable={false}/>
                        <InspectionOptionField
                          label={translate('resources.stocks.fields.inspections.value')}
                          source="optionId" sortable={false}/>
                        <NumberField source="price"
                                     label={translate('resources.stocks.fields.inspections.price')}
                                     sortable={false}/>
                        <TextField source='memo' sortable={false}/>
                      </Datagrid>
                    </ArrayField>
                    <NumberField source="basicPrice"/>
                    <SubPriceField source="subPrice" addLabel={true}/>
                    <NumberField source="price"/>
                    <TextField source="overall"/>
                  </Tab>}
                </TabbedShowLayout>
              </Card>
              <UserInspection record={record}/>
            </div>
          ) : ''
        }
        }
      </ShowController>
    )
  }
}

export default translate(withStyles(styles)(StockShow))
