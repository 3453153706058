import React from 'react'
import {Create, SimpleForm, TextInput, ReferenceInput, SelectInput, NumberInput} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'
import {required} from '../../validators'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'

export default (props) => (
  <Create {...props} actions={<CreateActions/>}>
    <SimpleForm
      toolbar={<CreateToolbar/>}>
      <ReferenceInput source="siteId" reference="sites">
        <SelectInput optionText="name" validate={required}/>
      </ReferenceInput>
      <TextInput source="title" validate={required}/>
      <RichTextInput multiline source="content" validate={required}/>
      <NumberInput source="order" defaultValue={1} validate={required}/>
    </SimpleForm>
  </Create>
)
