import React from 'react'
import {Edit, SimpleForm, SelectInput, BooleanInput, NumberInput} from 'react-admin'
import {required} from '../../validators'
import EditActions from '../../custom/common/EditActions'
import EditToolbar from '../../custom/common/EditToolbar'
import {description} from "../../custom/point/choices";

export default (props) => (
  <Edit {...props} actions={<EditActions/>}>
    <SimpleForm toolbar={<EditToolbar/>}>
      <SelectInput disabled source='name' choices={description}/>
      <NumberInput source="point" validate={required}/>
      <BooleanInput source="usable" validate={required}/>
    </SimpleForm>
  </Edit>
)
