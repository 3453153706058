import {
  CREATE,
  DELETE,
  GET_LIST,
  GET_ONE,
  UPDATE,
} from 'react-admin'

export function request(apiUrl, type, params) {
  let url = apiUrl + '/admin/categories'
  let options = {}


  switch (type) {
    case CREATE:
    case UPDATE:
      if (type === CREATE) {
        options.method = 'POST'
      }
      else {
        url += `/${params.id}`
        options.method = 'PUT'
      }
      const {data: {name, brandCategoryId, brandId, order, onDeal, parentIds, inspections}} = params
      let {data : {selectItems}} = params
      if(selectItems) selectItems = selectItems.map(object => object.selectItem)
      
      options.data = {
        name,
        brandId,
        brandCategoryId,
        selectItems,
        onDeal,
        order,
        inspections
      }
      
      if (parentIds.length > 0) options.data.parentId = parentIds[parentIds.length - 1]
      if (options.data.selectItems === null || options.data.parentId) delete options.data.selectItems
      break
    case GET_ONE:
      url += `/${params.id}`
      break
    case DELETE:
      options.method = 'DELETE'
      url += `/${params.id}`
      break
    default:
      break
  }
  return {url, ...options}
}

export async function response(res, type, params) {
  let ret = {}
  const {data} = res

  switch (type) {
    case UPDATE:
      ret = params
      break
    case GET_LIST:
      ret = {data: data, total: data.length}
      break
    case GET_ONE:
      const sortedData = sortCategoryOne(data, parseInt(params.id))
      sortedData.inspections = sortedData.inspections.sort((a, b) => a.order - b.order)
      if(sortedData.selectItems){
        sortedData.selectItems = sortedData.selectItems.map(item =>{ return {selectItem : item}})
      }
      ret = {data: sortedData}
      break
    default:
      if (data) ret = {data}
      break
  }
  return ret
}

function sortCategoryOne(arr, id) {
  const category = arr.find(cat => cat.id === id)
  const ret = {...category, parentIds: []}

  let parentId = category.parentId
  while (parentId) {
    const parent = arr.find(cat => cat.id === parentId) // eslint-disable-line no-loop-func
    ret.parentIds.unshift(parent.id)
    parentId = parent.parentId
  }
  return ret
}
