import React from 'react'

import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField, ImageField,
  NumberField,
  SelectField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  translate,
  TopToolbar,
  ListButton,
  EditButton,
  ReferenceManyField,
  Pagination,
  useTranslate,
  useShowController
} from 'react-admin'
import Typography from '@material-ui/core/Typography'
import AccountField from './AccountField'
import PhoneField from '../../custom/common/PhoneField'
import {history} from '../../App'
import get from 'lodash/get'
import {withStyles} from '@material-ui/core'
import UserDeleteButton from '../../custom/common/UserDeleteButton'
import {description} from '../../custom/point/choices'

const TitleField = (({record, style, ...rest}) => {
  const str = get(record, rest.source)
  let content = ''
  if (str && str.length > 30) {
    content = str.substring(0, 30) + '...'
  } else content = str
  return (
    <Typography style={style}>{content}</Typography>
  )
})
const ThumbnailImageField = withStyles({image: {height: 60}})(ImageField)

const OrderCountField = (({record}) => {
  if (!record.orders) return ''
  return <Typography>{record.orders.length.toLocaleString()}건</Typography>
})

const OrderTotalField = (({record}) => {
  if (!record.orders) return ''
  const total = record.orders.reduce((prev, curr) => {
    return prev + curr.amount
  }, 0)
  return <Typography>{total.toLocaleString()}원</Typography>
})

const StatusField = (({record, ...rest}) => {
  let str = ''
  switch (get(record, rest.source)) {
    case 'registered':
      str = '접수'
      break
    case 'waiting':
      str = '입고대기'
      break
    case 'warehousing':
      str = '입고'
      break
    case 'inspected':
      str = '검수완료'
      break
    case 'agreed':
      str = '고객동의'
      break
    case 'paid':
      str = '입금완료'
      break
    case 'cancelled':
      str = '취소'
      break
    default:
      break
  }
  return (
    <span>{str}</span>
  )
})

const ShowActions = (props) => {
  const {basePath} = props
  return props.data ? <TopToolbar>
    <ListButton basePath={basePath}/>
    <EditButton basePath={basePath} record={props.data}/>
    <UserDeleteButton {...props}/>
  </TopToolbar> : ''
}

const UserShow = (props) => {
  const {record} = useShowController(props)
  const translate = useTranslate()
  return (
    <Show {...props} actions={<ShowActions/>}>
      <TabbedShowLayout>
        <Tab label={translate('resources.users.summary')}>
          <TextField source="id"/>
          <TextField source="name"/>
          <AccountField/>
          <PhoneField source="phone"/>
          <PhoneField source="email"/>
          <OrderCountField source="orderCount" addLabel={true}/>
          <OrderTotalField source="orderPrice" addLabel={true}/>
          <NumberField source="point"/>
          <BooleanField source="isDel"/>
          <DateField source="createdAt" locales='ko' showTime/>
        </Tab>
        <Tab label={translate('resources.users.orderHistory')}>
          <ArrayField source="orders" addLabel={false}>
            <Datagrid rowClick={(record, resource, row) => history.push(`/orders/${row.id}/show`)}>
              <TextField label={'resources.orders.fields.id'} source="id" sortable={false}/>
              <SelectField label={'resources.orders.fields.method'} source="method" choices={[
                {id: 'card', name: '카드'},
                {id: 'vbank', name: '가상계좌'},
                {id: 'naverco', name: '네이버페이'},
              ]}/>
              <NumberField label={'resources.orders.fields.amount'} source="amount" sortable={false}/>
              <SelectField label={'resources.orders.fields.status'} source="status" choices={[
                {id: 'ready', name: '가상계좌 발급'},
                {id: 'paid', name: '결제완료'},
                {id: 'sent', name: '배송출발'},
                {id: 'delivered', name: '배송완료'},
                {id: 'cancelled', name: '취소'},
              ]}/>
              <DateField label={'resources.orders.fields.createdAt'} source="createdAt" locales='ko'
                         sortable={false}/>
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label={translate('resources.users.sellHistory')}>
          <ArrayField source="stocks" addLabel={false}>
            <Datagrid rowClick={(record, resource, row) => history.push(`/stocks/${row.id}/show`)}>
              <TextField label={'resources.stocks.fields.id'} source="id" sortable={false}/>
              <TextField label={'resources.stocks.fields.product.name'} source="product.name" sortable={false}/>
              <TextField label={'resources.stocks.fields.serial'} source="serial" sortable={false}/>
              <TextField label={'resources.stocks.fields.seller.name'} source="seller.name" sortable={false}/>
              <PhoneField label={'resources.stocks.fields.seller.phone'} source="seller.phone" sortable={false}/>
              <NumberField label={'resources.stocks.fields.price'} source="price" sortable={false}/>
              <StatusField label={'resources.stocks.fields.status'} source="status" sortable={false}/>
              <TextField label={'resources.stocks.fields.inspector.name'} source="inspector.name" sortable={false}/>
              <DateField label={'resources.stocks.fields.createdAt'} source="createdAt" locales='ko'/>
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label={translate('resources.users.questionHistory')}>
          <ArrayField source="questions" addLabel={false}>
            <Datagrid rowClick={(record, resource, row) => history.push(`/questions/${row.id}/show`)}>
              <TextField label="Id" source="id" sortable={false}/>
              <SelectField label={'resources.questions.fields.type'} source="type" choices={[
                {id: 'goods', name: '상품문의'},
                {id: 'payment', name: '구매/결제문의'},
                {id: 'exchange', name: '교환문의'},
                {id: 'delivery', name: '배송문의'},
                {id: 'refund', name: '취소/환불/반품문의'},
              ]}/>
              <TitleField label={'resources.questions.fields.question'} source="question" sortable={false}/>
              <BooleanField label={'resources.questions.fields.answered'} source="answered" sortable={false}/>
              <DateField label={'resources.questions.fields.createdAt'} source="createdAt" locales='ko' showTime
                         sortable={false}/>
              <DateField label={'resources.questions.fields.answeredAt'} source="answeredAt" locales="ko" showTime
                         sortable={false}/>
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label={translate('resources.users.devices')}>
          <ArrayField source="devices" addLabel={false}>
            <Datagrid rowClick={(record, resource, row) => history.push(`/products/${row.productId}/show`)}>
              <ThumbnailImageField label={'resources.products.fields.titleImage'}
                                   source="titleImage" sortable={false}/>
              <TextField label={'resources.products.fields.title'}
                         source="title" sortable={false}/>
              <TextField label={'resources.users.fields.devices.serial'}
                         source="serial" sortable={false}/>
              <DateField label={'resources.users.fields.devices.createdAt'}
                         source="createdAt" locales='ko' showTime
                         sortable={false}/>
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="포인트 내역">
          <ReferenceManyField label={'포인트 내역'}
                              reference="points"
                              link={''}
                              pagination={<Pagination/>}
                              sort={{field: 'createdAt', order: 'DESC'}}
                              filter={{userId: record ? record.id : null}}
                              target="id">
            <Datagrid>
              <TextField source="id" sortable={false}/>
              <NumberField source="point" sortable={false} />
              <SelectField source="description" choices={description} sortable={false}/>
              <DateField source="createdAt" locales={'ko'} showTime={true} />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default translate(UserShow)
