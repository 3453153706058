import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  TopToolbar,
  CreateButton,
  Datagrid,
  DateField,
  Filter,
  List,
  NumberField,
  SearchInput,
  SelectInput,
  TextField,
  translate,
  SelectField
} from 'react-admin'
import PhoneField from '../../custom/common/PhoneField'
import {status} from './choices'

const styles = theme => ({
  search: {
    height:'3rem',
    padding: '0 0',
    margin: 0
  },
  select: {
    height:'3rem',
    padding: '0 0',
    margin: 0
  }
})

const ListActions = ({basePath, resource, filters, ...rest}) => {
  return <TopToolbar>
    <CreateButton basePath={basePath}/>
  </TopToolbar>
}

const ListFilter = props => (
  <Filter {...props}>
    <SearchInput placeholder={'접수번호/전화번호/이름'} className={props.classes.search} source="search" alwaysOn/>
    <SelectInput source="status" className={props.classes.select} choices={status} alwaysOn/>
  </Filter>
)

export default withStyles(styles)(translate(({classes, translate, ...props}) => {
  return (
    <List {...props}
          actions={<ListActions/>}
          filters={<ListFilter classes={classes}/>}
          perPage={25}
          sort={{field: 'createdAt', order: 'DESC'}}
          bulkActionButtons={false}>
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false}/>
        <TextField source="product.name" sortable={false}/>
        <TextField source="serial" sortable={false}/>
        <TextField source="seller.name" sortable={false}/>
        <PhoneField source="seller.phone" sortable={false}/>
        <NumberField source="price" sortable={false}/>
        <SelectField source="status" choices={status} sortable={false}/>
        <TextField source="inspector.name" sortable={false}/>
        <DateField source="createdAt" locales='ko'/>
      </Datagrid>
    </List>
  )
}))

