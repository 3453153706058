import React from 'react'
import {
  BooleanInput,
  DateField,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin'
import {required} from '../../validators'
import EditActions from '../../custom/common/EditActions'
import EditToolbar from '../../custom/common/EditToolbar'

export default (props) => (
  <Edit {...props} actions={<EditActions/>}>
    <SimpleForm toolbar={<EditToolbar/>}>
      <TextInput disabled source='id'/>
      <TextField source="name"/>
      <TextInput source="adminName"/>
      <SelectInput source="authority" choices={[
        {id: 'admin', name: '관리자'},
        {id: 'manager', name: '매니저'},
      ]}/>
      <TextInput source="password" type='password'/>

      <ReferenceInput source="affiliationId" reference="affiliations"  validate={required}>
        <SelectInput optionText="affiliation"/>
      </ReferenceInput>

      <TextInput source="email" type='email' validate={required}/>
      <BooleanInput source="emailNoti" validate={required}/>
      <TextInput source="phone" type='phone' validate={required}/>
      <DateField source="createdAt" locales='ko' showTime/>
      <DateField source="updatedAt" locales='ko' showTime/>
    </SimpleForm>
  </Edit>
)
