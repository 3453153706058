import React from 'react'
import {DateField, TextInput, Edit, NumberInput, SimpleForm, TextField,} from 'react-admin'
import {required} from '../../validators'
import AccountField from './AccountField'
import EditActions from '../../custom/common/EditActions'
import EditToolbar from '../../custom/common/EditToolbar'


export default (props) => (
  <Edit {...props} actions={<EditActions/>}>
    <SimpleForm toolbar={<EditToolbar/>}>
      <TextInput disabled source='id'/>
      <TextField source="name"/>
      <TextField source="phone"/>
      <AccountField/>
      <NumberInput source="point" validate={required}/>
      <DateField source="createdAt" locales='ko' showTime/>
    </SimpleForm>
  </Edit>
)
