import {CREATE, DELETE, GET_LIST, GET_ONE, UPDATE} from 'react-admin'
import {convertImagePath, uploadToS3} from './ImageHandler'
import defaultImg from '../../defaultImg/thumbnail.png'

export async function request(apiUrl, type, params) {
  let url = apiUrl + '/admin/brands'
  let options = {}
  let retData = {}
  let idList =[]

  const data= {...params.data}

  switch (type) {
    case CREATE:
      options.method='POST'
      retData=await uploadToS3(data, 'image', 'image', )

      if(retData.brandCategories){
        idList = retData.brandCategories.filter(object => {
          return object && object.id;
        })
      }

      options.data = retData.image ? {
        name : retData.name,
        image : retData.image ? retData.image.src : null, 
        brandCategoryIds : idList.map(object => object.id),
        keywords : !data.keywords ? '중고' : retData.keywords.split(','),
      } : {
        name : retData.name,
        brandCategoryIds : idList.map(object => object.id),
        keywords : !data.keywords ? '중고' : retData.keywords.split(','),
      }
      break;
    case UPDATE:
      url+=`/${params.id}`;
      options.method = 'PUT'
      retData= await uploadToS3(data, 'image', 'image',);

      let image = null;
      if(retData && retData.image && retData.image.src)
        image=retData.image.src
      else if(params.data.image)
        image=params.data.image.path;
      
      idList = retData.brandCategories.filter(object => {return object && object.id;})
      
      options.data = image? {
        name : retData.name,
        image : image,
        brandCategoryIds : idList.map(object => object.id),
        keywords : data.keywords === null? '중고' :retData.keywords.split(','),
      } : {
        name : retData.name,
        brandCategoryIds : idList.map(object => object.id),
        keywords : data.keywords === null? '중고' :retData.keywords.split(','),
      }
      break;
    case GET_ONE:
      url += `/${params.id}`
      break
    case GET_LIST:
      options.method='GET'
      const {page, perPage}=params.pagination
      const {field, order} = params.sort
      options.params={
        sort : field,
        order : order,
        start : (page-1)*perPage ,
        perPage: perPage, 
      }
      break;
    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break
    default:
      break
  }
  return {url, ...options}
}

export async function response(res, type, params) {
  let ret = {}
  const {data} = res
  switch (type) {
    case GET_ONE:
      const path = data.image;
      data.image={path, src: convertImagePath(data.image, 'large')}
      if(!data.image.src){
        data.image={...data.image, src : defaultImg}
      }
      const keywords = data.keywords === null? '중고' : data.keywords.join(','); // 값이 없는 경우 '중고'라는 값을 정한다.
      data.keywords=keywords;
      ret={data};
      break;
    case GET_LIST:
      data.list.forEach(row => {
        row.image=convertImagePath(row.image, 'small');
        if(!row.image){ row.image=defaultImg }

        let brandCategoryList=[];
        row.brandCategories.forEach(brandCategory=>{
          brandCategoryList.push(brandCategory.name);
        })
        row.brandCategoryList=brandCategoryList.join(', ')
        if(row.keywords !== null){
          row.keywords=row.keywords.join(', ');
        }else{
          row.keywords='중고'
        }
      })
      ret = {data : data.list, total: data.total}
      break;
    default:
      if (data) ret = {data}
      break;
  }
  return ret
}
