import React from 'react'
import compose from 'recompose/compose'
import {withStyles} from '@material-ui/core/styles'
import {crudDelete, crudGetOne, translate} from 'ra-core'
import {Button as AdminButton, Confirm, linkToRecord} from 'react-admin'
import Button from '@material-ui/core/Button';
import ContentCreate from '@material-ui/icons/Create'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {dataProvider, history} from '../../App'
import {connect} from 'react-redux'

const styles = theme => ({
  dialog: {
    width: '80%',
  },
  button: {}
});

class StockEditButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {isOpen: false, isOpenCancel: false, isOpenDelete: false, value: props.value}
    this.handleClick = this.handleClick.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleDone = this.handleDone.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleCancelConfirm = this.handleCancelConfirm.bind(this)
    this.handleCancelCancel = this.handleCancelCancel.bind(this)
    this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this)
    this.handleDeleteCancel = this.handleDeleteCancel.bind(this)
    this.updateReview = this.updateStock.bind(this)
  }

  async updateStock() {
    const {dispatchCrudGetOne, basePath, record} = this.props
    const {value} = this.state
    await dataProvider('UPDATE_STATUS', 'stocks', {id: record.id, data: {status: value}})
    dispatchCrudGetOne('stocks', record.id, basePath)
  }

  handleClick() {
    this.setState({isOpen: true})
  }

  handleCancel() {
    this.setState({isOpen: false})
  }

  async handleDone(e) {
    e.stopPropagation()
    const {basePath, record} = this.props
    const {value} = this.state
    if (record.status === value) {
      return this.setState({isOpen: false})
    } else if (value === 'cancelled') {
      return this.setState({isOpenCancel: true})
    } else if (value === 'delete') {
      if (record.goodsId) alert('이미 생성한 상품이 있을 경우 삭제할 수 없습니다.')
      else this.setState({isOpenDelete: true})
      return
    } else if ((record.status === 'registered' || record.status === 'waiting' || record.status === 'warehousing')
      && value === 'inspected' && value !== record.status) {
      history.push(linkToRecord(basePath, record.id))
    } else if (value === 'edit') {
      history.push(linkToRecord(basePath, record.id))
    } else {
      await this.updateStock()
    }
    this.setState({isOpen: false})
  }

  async handleCancelConfirm() {
    await this.updateStock()
    this.setState({isOpen: false, isOpenCancel: false})
  }

  handleCancelCancel() {
    this.setState({isOpenCancel: false})
  }

  async handleDeleteConfirm() {
    const {basePath, record, dispatchCrudDelete} = this.props
    dispatchCrudDelete('stocks', record.id, record, basePath)
    this.setState({isOpen: false, isOpenDelete: false})
  }

  handleDeleteCancel() {
    this.setState({isOpenDelete: false})
  }

  handleChange(e, v) {
    this.setState({value: v})
  }

  render() {
    const {isOpen} = this.state
    const {classes, translate, record,} = this.props

    let choices = []
    if (record.status === 'registered' ||
        record.status === 'waiting' ||
        record.status === 'warehousing' ||
        record.status === 'contactWaiting' ||
        record.status === 'boxSent' ||
        record.status === 'adminCanceled'
    ){
      choices = [
        {id: 'registered', name: '접수'},
        {id: 'contactWaiting', name: '연락대기중'},
        {id: 'boxSent', name: '박스발송완료'},
        {id: 'adminCanceled', name: '거래취소'},
        {id: 'waiting', name: '입고대기'},
        {id: 'warehousing', name: '입고'},
        {id: 'inspected', name: '검수완료'},
        {id: 'delete', name: '삭제'},
      ]
    } else {
      choices = [
        {id: 'inspected', name: '검수완료'},
        {id: 'agreed', name: '고객동의'},
        {id: 'paid', name: '입금완료'},
        {id: 'edit', name: '매입수정'},
        {id: 'cancelled', name: '취소'},
        {id: 'delete', name: '삭제'},
      ]
    }
    return (
      <div>
        <AdminButton label="수정" onClick={this.handleClick}>
          <ContentCreate/>
        </AdminButton>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          onEntering={this.handleEntering}
          aria-labelledby="confirmation-dialog-title"
          open={isOpen}
          classes={{paper: classes.dialog}}
        >
          <DialogTitle id="confirmation-dialog-title">상태 수정</DialogTitle>
          <DialogContent>
            <RadioGroup
              ref={ref => {
                this.radioGroupRef = ref
              }}
              aria-label="Status"
              name="status"
              value={this.state.value}
              onChange={this.handleChange}
            >
              {choices.map(({id, name}) => (
                <FormControlLabel value={id} key={id} control={<Radio/>} label={name}/>
              ))}
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} color="primary">
              취소
            </Button>
            <Button onClick={this.handleDone} color="primary">
              확인
            </Button>
          </DialogActions>
        </Dialog>
        <Confirm
          isOpen={this.state.isOpenCancel}
          confirmColor="warning"
          title={'주문취소'}
          content={'정말로 주문을 취소하시겠습니까?'}
          cancel={translate('ra.action.cancel')}
          confirm={translate('ra.action.confirm')}
          onConfirm={this.handleCancelConfirm}
          onClose={this.handleCancelCancel}
        />
        <Confirm
          isOpen={this.state.isOpenDelete}
          confirmColor="warning"
          title={'주문취소'}
          content={'정말로 주문을 삭제하시겠습니까?'}
          cancel={translate('ra.action.cancel')}
          confirm={translate('ra.action.confirm')}
          onConfirm={this.handleDeleteConfirm}
          onClose={this.handleDeleteCancel}
        />
      </div>
    )
  }
}

export default compose(
  connect(
    null,
    {
      dispatchCrudGetOne: crudGetOne,
      dispatchCrudDelete: crudDelete
    }
  ),
  translate,
  withStyles(styles)
)(StockEditButton)
