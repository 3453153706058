const type = [
  {id: 'suggest', name: '기능 제안'},
  {id: 'log', name: '작업 기록'},
  {id: 'guide', name: '사용 가이드'},
  {id: 'etc', name: '기타'}
]

export default {
  type
}
