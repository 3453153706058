import React from 'react'
import {Edit, SelectInput, TextInput, SimpleForm} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'
import choices from './choices'
import {toolbar} from '../../utils/editorConfig'
import EditActions from '../../custom/common/EditActions'

export default (props) => (
  <Edit {...props} undoable={false} actions={<EditActions />}>
    <SimpleForm>
      <TextInput source="title" />
      <SelectInput source="type" choices={choices.type} />
      <RichTextInput source="content" toolbar={toolbar} />
    </SimpleForm>
  </Edit>
)
