import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {TopToolbar, CreateButton, Datagrid, EditButton, Filter, List, SearchInput, TextField,} from 'react-admin'
import OptionField from './OptionField'

const styles = theme => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }
})

const ListActions = ({basePath,}) => (
  <TopToolbar>
    <CreateButton basePath={basePath}/>
  </TopToolbar>
)

const ListFilter = props => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn/>
  </Filter>
)


export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props}
          actions={<ListActions/>}
          filters={<ListFilter/>}
          perPage={25}
          bulkActionButtons={false}>
      <Datagrid>
        <TextField source="id"/>
        <TextField source="name"/>
        <TextField source="key"/>
        <OptionField sortable={false}/>
        <EditButton/>
      </Datagrid>
    </List>
  )
})

