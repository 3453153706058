import React, {useState, useEffect} from 'react';
import {Create, SimpleForm, TextInput, ImageInput, ImageField, SelectInput, GET_LIST} from 'react-admin'
import {required} from "../../validators";
import CreateActions from '../../custom/common/CreateActions';
import CreateToolbar from '../../custom/common/CreateToolbar';
import {dataProvider} from "../../App";

const typeChoices = [
  {id : "notice", name : "notice"},
  {id : "event", name : "event"},
]

export default (props) => {
  const [siteChoices, setSiteChoices] = useState([]);
  
  useEffect(()=>{
    dataProvider(
      GET_LIST,
      'sites',
      {}
    ).then(result => setSiteChoices(result.data));
  }, [])
  
  return (
    <Create {...props} actions={<CreateActions/>}>
    <SimpleForm toolbar={<CreateToolbar/>}>
      <SelectInput source='siteId' choices={siteChoices} validate={required}/>
      <SelectInput source='type' choices={typeChoices} validate={required}/>
      <TextInput source='title' validate={required}/>
      <TextInput multiline source='content' style={{width : '100%'}}/>
      <ImageInput source="image">
        <ImageField source="src" title="title"/>
      </ImageInput>
    </SimpleForm>
  </Create>
)}