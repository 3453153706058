import React from  'react'
import {SelectInput, translate, GET_LIST} from 'react-admin'
import {dataProvider }from '../../App';

class SelectCategories extends React.Component{
    constructor(props){
        super(props)
        this.state={
            brandCategoryList : [],
            selectedCategoryId :[],
        }
    }

    componentDidMount(){
        dataProvider(
            GET_LIST,
            'brandCategories',
            {
              filter:{},
              pagination :{page:1, perPage: 1000},
              sort:{field : 'id', order:'ASC'},
            }
        ).then(result =>{
            this.setState({ brandCategoryList : result.data, })
            if(this.props.formData.brandCategories){
                const brandCategoriesId = this.props.formData.brandCategories.map(item => item.id);
                this.setState({ selectedCategoryId : brandCategoriesId, })
            }
        })   
    }

    render(){
        const {translate}=this.props
        const {brandCategoryList, selectedCategoryId}=this.state;
        let choiceList = brandCategoryList.filter((item, index) =>{            
            return !selectedCategoryId.includes(item.id)
        })

        let infoAboutSelected=[]
        for(let i=0; i<selectedCategoryId.length; i++){
            for(let j=0; j<brandCategoryList.length; j++){
                if(selectedCategoryId[i] === null){
                    infoAboutSelected.push({id : null})
                    break;
                }
                if(selectedCategoryId[i] === brandCategoryList[j].id){
                    infoAboutSelected.push({id : brandCategoryList[j].id, name : brandCategoryList[j].name})
                    break;
                }
            }
        }

        return(
            <React.Fragment>
                <SelectInput source={`brandCategories[${0}].id`}
                             label={translate('resources.brands.fields.brandCategoryIndex', {index: 1})}
                             style={{paddingRight: '1em', width : 200,}}
                             choices={infoAboutSelected[0] && infoAboutSelected[0].id !== null ? 
                                choiceList.concat(infoAboutSelected[0]).concat({id: null, name : "선택취소"}) : choiceList}
                             onChange={(e)=>{
                                 let category = selectedCategoryId.concat();
                                 category[0]=e.target.value;
                                 this.setState({selectedCategoryId : category})
                             }}
                            >
                    
                </SelectInput>
                {
                    selectedCategoryId.reduce((ret, value, i)=>{
                        if(selectedCategoryId.length > 0 && i+1 < brandCategoryList.length){
                            ret.push(<SelectInput key={i} source={`brandCategories[${i+1}].id`}
                            label={translate('resources.brands.fields.brandCategoryIndex', {index: i+2})}
                            style={{paddingRight: '1em', width : 200,}}
                            choices={infoAboutSelected[i+1] && infoAboutSelected[i+1].id !== null ? 
                                choiceList.concat(infoAboutSelected[i+1]).concat({id: null, name : "선택취소"}) : choiceList}
                            onChange={(e)=>{
                                let category = selectedCategoryId.concat();
                                category[i+1]=e.target.value;
                                this.setState({selectedCategoryId : category})
                            }}/>)
                        }
                        return ret;
                    }, [])
                }
            </React.Fragment>
        )
    }
}

export default translate(SelectCategories);