import BookIcon from '@material-ui/icons/Book'
import FaqCreate from './FaqCreate'
import FaqEdit from './FaqEdit'
import FaqList from './FaqList'

export default {
  list: FaqList,
  create: FaqCreate,
  edit: FaqEdit,
  icon: BookIcon,
}
