import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {TopToolbar, CreateButton, Datagrid, SelectField, List, TextField, BooleanField} from 'react-admin'
import {description} from "../../custom/point/choices";

const styles = theme => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }
})
const Actions = ({basePath,}) => (
  <TopToolbar>
    <CreateButton basePath={basePath}/>
  </TopToolbar>
)

export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props}
          actions={<Actions/>}
          bulkActionButtons={false}
          perPage={25}
          sort={{field: 'name', order: 'DESC'}}>
      <Datagrid rowClick={'edit'}>
        <SelectField source="name" choices={description} sortable={false}/>
        <TextField source="point" sortable={false}/>
        <BooleanField source="usable" sortable={false}/>
      </Datagrid>
    </List>
  )
})

