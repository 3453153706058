import React from 'react'

import {
  ArrayField,
  TopToolbar,
  CardContentInner,
  Datagrid, DateField,
  ImageField,
  ListButton,
  NumberField, ReferenceField,
  SelectField,
  ShowController,
  SimpleShowLayout,
  TextField,
  TitleForRecord,
  translate,
} from 'react-admin'
import {withStyles} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import OrderReceiver from './OrderReceiver'
import OrderStatus from './OrderStatus'
import OrderUser from './OrderUser'
import OrderInfoButton from './OrderInfoButton'
import OrderStatusButton from './OrderStatusButton'
import {history} from '../../App'
import LinkField from '../../custom/common/LinkField'

const ShowActions = ({basePath, ...props}) => (
  <TopToolbar>
    {props.data && props.data.statusLog && <OrderInfoButton basePath={basePath} record={props.data}/>}
    {props.data && props.data.statusLog && <OrderStatusButton basePath={basePath} record={props.data}/>}
    <ListButton basePath={basePath}/>
  </TopToolbar>
)

const ThumbnailImageField = withStyles({image: {height: 60}})(ImageField)

const styles = {
  root: {display: 'flex', alignItems: 'flex-start'},
  form: {flexGrow: 2, marginRight: '2em'},
  container: {width: '35em'},
}

class OrderShow extends React.Component {
  render() {
    const {classes, translate, ...props} = this.props

    const ShopGoodsLinkField = ((props) => {
      return <LinkField {...props} name={props.record.shopGoodsId} sortable={false}/>
    })

    return (
      <ShowController {...props}>
        {({isLoading, defaultTitle, ...controllerProps}) => {
          const record = controllerProps.record
          return controllerProps.record ? (
            <div className={classes.root}>
              <Card className={classes.form}>
                <TitleForRecord
                  defaultTitle={defaultTitle}
                  record={record}
                />
                <CardContentInner>
                  <ShowActions basePath={controllerProps.basePath} data={record}/>
                </CardContentInner>
                <SimpleShowLayout {...controllerProps}>
                  <TextField source="id"/>
                  <ReferenceField source="userId" reference="users" allowEmpty link={'show'}>
                    <TextField source="name"/>
                  </ReferenceField>
                  <ArrayField source="goods">
                    <Datagrid rowClick={(record, resource, row) => history.push(`/goods/${row.id}/show`)}>
                      <TextField label={translate('resources.goods.fields.id')} source="id" sortable={false}/>
                      <ShopGoodsLinkField {...props} label="resources.products.fields.shopGoodsId" sortable={false}/>
                      <ThumbnailImageField source="titleImage" sortable={false}/>
                      <TextField source="title" sortable={false}/>
                      <TextField source="stockNumber" sortable={false}/>
                      <NumberField source="price" sortable={false}/>
                      <NumberField source="discountPrice" sortable={false}/>
                      <TextField source="optionName" sortable={false}/>
                      <NumberField source="quantity" sortable={false}/>
                    </Datagrid>
                  </ArrayField>
                  <SelectField source="method" choices={[
                    {id: 'card', name: '체크/신용카드'},
                    {id: 'vbank', name: '가상계좌'},
                    {id: 'naverco', name: '네이버페이'},
                  ]}/>
                  <SelectField source="platform" choices={[
                    {id: 'web', name: '웹'},
                    {id: 'ios', name: 'IOS'},
                    {id: 'android', name: '안드로이드'},
                    {id: 'naver', name: '스마트스토어'},
                  ]}/>
                  <NumberField source="quantity"/>
                  <NumberField source="deliveryFee"/>
                  <NumberField source="amount"/>
                  <NumberField source="point"/>
                  {record.vbank && <TextField source="vbank.name"/>}
                  {record.vbank && <TextField source="vbank.holder"/>}
                  {record.vbank && <TextField source="vbank.number"/>}
                  {record.vbank && <DateField source="vbank.expire" locales="ko" showTime/>}
                  {record.vbankRefund && <TextField source="vbankRefund.bank"/>}
                  {record.vbankRefund && <TextField source="vbankRefund.holder"/>}
                  {record.vbankRefund && <TextField source="vbankRefund.account"/>}
                  <TextField source="memo"/>
                  <TextField source="pccc"/>
                </SimpleShowLayout>
              </Card>
              <div style={{display: 'block'}}>
                <OrderStatus record={record}/>
                <OrderUser record={record}/>
                <OrderReceiver record={record}/>
              </div>
            </div>
          ) : ''
        }
        }
      </ShowController>
    )
  }
}

export default withStyles(styles)(translate(OrderShow))
