import React, {useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Button as AdminButton, crudGetOne} from 'react-admin'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import {dataProvider} from '../../App'
import {useDispatch} from 'react-redux'
import AdminIcon from '@material-ui/icons/PanTool'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const useStyles = makeStyles({
  dialog: {
    width: '80%'
  },
  textField: {
    width: '50%'
  },
  spanMargin: {
    margin: '5px'
  },
  statusField: {
    width: '70px'
  },
  descriptionField: {
    width: '100%'
  }
})

export default (props) => {
  const [isOpen, setOpen] = useState(false)
  const [status, setStatus] = useState(null)
  const [choices, setChoices] = useState([])
  const dispatch = useDispatch()
  const classes = useStyles()

  function handleShowModal() {
    setStatus(props.status)
    setChoices(props.choices)
    setOpen(true)
  }

  function handleCancel() {
    setOpen(false)
  }

  function onChangeStatus(e) {
    const {value} = e.target
    setStatus(value)
  }

  function validate() {
    try {
      if (!status) throw new Error('상태 값을 선택 하세요.')
    } catch (e) {
      e.status = 100
      throw e
    }
  }

  async function handleDone(e) {
    e.stopPropagation()

    try {
      validate()
      const {id, basePath, resource} = props
      const params = {status}
      const {data} = await dataProvider('UPDATE_STATUS', resource, {id, data: params})

      if (data) {
        dispatch(crudGetOne(resource, id, basePath, true))
      } else {
        alert('변경을 실패 하였습니다.')
        return
      }

      setOpen(false)
    } catch (e) {
      if (e.status === 100) alert(e.message)
      else {
        alert('변경을 실패 하였습니다.')
      }
    }
  }

  const DialogButton = () => {
    return (
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          취소
        </Button>
        <Button onClick={handleDone} color="primary">
          확인
        </Button>
      </DialogActions>
    )
  }

  return (
    <section>
      <AdminButton label={'상태 변경'} onClick={handleShowModal} disabled={props.disabled}>
        <AdminIcon />
      </AdminButton>
      <div>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          aria-labelledby="confirmation-dialog-title"
          open={isOpen}
          classes={{paper: classes.dialog}}
        >
          <DialogContent>
            <RadioGroup name="status" value={status} onChange={onChangeStatus}>
              {choices.map((choice) => (
                <FormControlLabel value={choice.id} key={choice.id} control={<Radio />} label={choice.name} />
              ))}
            </RadioGroup>
          </DialogContent>
          <DialogButton />
        </Dialog>
      </div>
    </section>
  )
}
