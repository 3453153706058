import{
    CREATE,
    DELETE,
    GET_LIST,
    GET_ONE,
    UPDATE,
} from 'react-admin'
import {convertImagePath, uploadToS3} from './ImageHandler';
import {dataProvider} from '../../App';

async function getSiteId(selectedSite){
    const idList = await dataProvider(
        GET_LIST,
        'sites',
        {}
    );
    const selected = idList.data.filter(site => {
        return site.name === selectedSite;
    })
    return selected[0].id;
}

export async function request(apiUrl, type, params) {
    let url = apiUrl + '/admin/boards';
    let options={};
    let retData={};
    const data={...params.data};

    switch(type){
        case CREATE:
            options.method='POST';
            retData = await uploadToS3(data, "image", "image");
            options.data = retData;
            if(retData.image != null){
                options.data.image = retData.image.src;
            }
            break;
        case UPDATE:
            options.method='PUT';
            url+=`/${params.id}`;
            retData = await uploadToS3(data, "image", "image");
            let image=null;
            if(retData && retData.image && retData.image.src){
                image = retData.image.src;
            }else if(params.data.image){
                image = params.data.image.path;
            }

            options.data = retData;
            if(options.data.content == null){
                delete options.data.content;
            }
            if(options.data.contentHtml == null){
                delete options.data.contentHtml;
            }
            if(image != null){
                options.data.image=image;
            }else if(image === null){
                delete options.data.image;
            }
            break;
        case GET_LIST:
            options.method = 'GET';
            const {field, order}=params.sort;
            const {page, perPage}=params.pagination;
            params.filter.site = !params.filter.site ? 'mybaro' : params.filter.site;
            const siteId=await getSiteId(params.filter.site);
            options.params={
                siteId  : siteId,
                sort : field,
                order : order,
                start : (page-1) * perPage,
                perPage : perPage,
            }
            break;
        case GET_ONE:
            url += `/${params.id}`;
            break;
        case DELETE:
            options.method="DELETE";
            url+=`/${params.id}`;
            break;
        default:
            break;
    }
    return {url, ...options}
}

export async function response(res, type, params){
    let ret ={}
    const {data} = res;
    switch(type){
        case GET_LIST:
            ret  = {data : data.list, total: data.total};
            break;
        case GET_ONE:
            const path = data.image;
            data.image = {path, src: convertImagePath(data.image, 'large')}
            ret={data};
            break;
        default:
            if(data) ret = {data}
            break;
    }

    return ret;
}