import {
  CREATE,
  DELETE,
  GET_LIST,
  GET_ONE,
  UPDATE,
} from 'react-admin'
import {convertImagePath, uploadToS3} from "./ImageHandler";

export async function request(apiUrl, type, params) {
  let url = apiUrl + '/admin/defaultImages'
  let options = {}
  let data = {}

  switch (type) {
    case CREATE:
      options.method = 'POST'

      data = {...params.data}
      options.image = await uploadToS3(data, 'image', 'defaultDetail')
      options.data = data
      break
    case GET_ONE:
      url += `/${params.id}`
      break
    case UPDATE:
      url += `/${params.id}`
      options.method = 'PUT'

      options.data = params.data

      options.data.update = {}
      if (params.data.image.src !== params.previousData.image.src) {
        options.data.update.image = true
        await uploadToS3(options.data, 'image', 'image')
      }

      break
    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break
    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res
  switch (type) {
    case GET_LIST:
      data.map(item => item.image = convertImagePath(item.image, 'small'))
      ret = {data, total: data.length}
      break
    case GET_ONE:
      const image = {
        src: convertImagePath(data.image, 'medium')
      }
      data.image = image
      ret = {data}
      break
    default:
      if (data) ret = {data}
      break
  }
  return ret
}
