import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import Drawer from '@material-ui/core/Drawer'
import {withStyles} from '@material-ui/core/styles'
import IconImageEye from '@material-ui/icons/RemoveRedEye'
import IconKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import {ArrayField, Button, Datagrid, DateField, SimpleShowLayout, TextField, translate} from 'react-admin'

const styles = theme => ({
  field: {
    '& span': {
      display: 'inline-block',
      maxWidth: '20em'
    }
  }
})

const ProductPreviewView = translate(({classes, translate, ...props}) => (
  <SimpleShowLayout {...props}>
    <TextField source="id"/>
    <TextField source="title" className={classes.field}/>
    <TextField source="name" className={classes.field}/>
    <TextField source="code" className={classes.field}/>
    <TextField source="year" className={classes.field}/>
    <DateField source="createdAt" className={classes.field} locales="ko"/>
    <ArrayField source="extras">
      <Datagrid>
        <TextField source="name" label={translate('common.name')} sortable={false}/>
        <TextField source="value" label={translate('common.value')} sortable={false}/>
      </Datagrid>
    </ArrayField>
  </SimpleShowLayout>
))

const mapStateToProps = (state, props) => ({
  record: state.admin.resources[props.resource]
    ? state.admin.resources[props.resource].data[props.id]
    : null,
  version: state.admin.ui.viewVersion
})

const ProductPreview = connect(mapStateToProps, {})(
  withStyles(styles)(ProductPreviewView)
)

class ProductQuickPreviewButton extends Component {
  state = {showPanel: false}

  handleClick = () => {
    this.setState({showPanel: true})
  }

  handleCloseClick = () => {
    this.setState({showPanel: false})
  }

  render() {
    const {showPanel} = this.state
    const {id} = this.props
    return (
      <Fragment>
        <Button onClick={this.handleClick} label="ra.action.show">
          <IconImageEye/>
        </Button>
        <Drawer
          anchor="right"
          open={showPanel}
          onClose={this.handleCloseClick}
        >
          <div>
            <Button label="ra.action.close" onClick={this.handleCloseClick}>
              <IconKeyboardArrowRight/>
            </Button>
          </div>
          <ProductPreview id={id} basePath="/products" resource="products"/>
        </Drawer>
      </Fragment>
    )
  }
}

export default connect()(ProductQuickPreviewButton)
