import React from 'react'
import {
  DateField,
  Show,
  SimpleShowLayout,
  TextField,
  TabbedShowLayout,
  Tab,
  RichTextField,
  TopToolbar,
  ListButton,
  EditButton,
  SelectField
} from 'react-admin'
import choices from './choices'

const Actions = ({record, props, basePath}) => (
  <TopToolbar>
    <ListButton {...props} />
    <EditButton record={props} basePath={basePath} />
  </TopToolbar>
)

export default (props) => {
  return (
    <Show {...props} actions={<Actions props={props} />}>
      <SimpleShowLayout>
        <SelectField source="type" sortable={false} choices={choices.type} />
        <TextField source="title" />
        <TextField source="name" sortable={false} />
        <DateField source="createdAt" locales="ko" showTime sortable={false} />
        <TabbedShowLayout>
          <Tab label="내용" context="content">
            <RichTextField source="content" addLabel={false} />
          </Tab>
        </TabbedShowLayout>
      </SimpleShowLayout>
    </Show>
  )
}
