import React, {PureComponent} from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import {withStyles} from '@material-ui/core/styles';
import {CardHeader, Button} from '@material-ui/core';
import {translate} from 'react-admin';
import OrderList from './OrderList';

const styles ={
    card:{
        minWidth : 500,
    },
    cardHeader:{
        display:'flex',
        justifyContent: 'space-between',
        alignItems :'center'
    },
    durationSelect:{
        marginRight: 16,
        height: 30,
        fontSize : '1rem',
    },
    buttons : {
        marginBottom : 10,
        textAlign : 'center',
    },
    zeroBtn :{
        display : 'none',
    },
    moveBtn :{
        marginLeft:10,
        marginRight:10,
        border:0,
        outline: 0,
        backgroundColor:'white',
        color : '#3f51b5',
        fontSize : 15,
    },
    numBtn:{
        borderRadius :50,
    },
    curBtn:{
        border:0,
        borderRadius :50,
        backgroundColor: '#3f51b5',
        color : 'white',
        fontWeight : 'bold',
    }
}

class OrderCard extends PureComponent{
    constructor(props){
        super(props);
            this.state={
                perPage : 10,
                curPage : 0,
                pgBtnNum : 3,
                pgFirst : 0,
            }
            this.chngDuration=this.chngDuration;
            this.preBtn=this.preBtn;
            this.nextBtn=this.nextBtn;
            this.numBtn=this.numBtn;
    }

    chngDuration(e){ 
        if(e.target.value === 'entire'){
            this.setState({
                curPage : 0,
                pgFirst : 0,
            }, ()=>{
                this.props.fetchOrders();
            })
        }else if(e.target.value === 'd30'){
            const year=new Date().getFullYear();
            const month=new Date().getMonth();
            const date=new Date().getDate();
            const d30=new Date(year,month, date);
            d30.setDate(d30.getDate()-30);
            
            let d30Year=d30.getFullYear();
            let d30Month=d30.getMonth()+1 < 10 ? '0'+(d30.getMonth()+1) : d30.getMonth()+1;
            let d30Date=d30.getDate() < 10 ? '0'+d30.getDate() : d30.getDate();
            let todayYear=year;
            let todayMonth=month+1 < 10 ? '0'+(month+1) : month+1
            let todayDate=date < 10 ? '0'+date : date;

            this.setState({
                curPage : 0,
                pgFirst : 0,
            }, ()=>{
                this.props.fetchOrders(`${d30Year}-${d30Month}-${d30Date}`, `${todayYear}-${todayMonth}-${todayDate}`)
            })
        }
    }
    
    preBtn(){
        if(this.state.curPage === 0) return;
        this.setState({ curPage : this.state.curPage-1 }, ()=>{
            if(this.state.curPage < this.state.pgFirst){
                this.setState({pgFirst: this.state.pgFirst-this.state.pgBtnNum});
            }
        })    
    }

    nextBtn(numberOfPage){
        if(this.state.curPage === numberOfPage-1) return;
        this.setState({ curPage : this.state.curPage+1 },()=>{
            if(this.state.curPage === this.state.pgFirst+this.state.pgBtnNum){
                this.setState({pgFirst : this.state.pgFirst+this.state.pgBtnNum});
            }
        })
    }

    numBtn(index){
        if(index === this.state.curPage) return;
        this.setState({ curPage : index})
    }

    render(){
        const {title, list}=this.props;
        let {curPage, perPage, pgBtnNum, pgFirst}=this.state;

        let filteredList, filteredTotal;
        const year=new Date().getFullYear();
        const month=new Date().getMonth();
        const date=new Date().getDate();
        
        let d30=new Date(year,month, date);
        d30.setDate(d30.getDate()-30);

        filteredList=list.concat();
        filteredTotal=filteredList.length;
        
        let numberOfPage = (filteredTotal % perPage) === 0 ? (filteredTotal/perPage) : Math.floor((filteredTotal/perPage)+1); // 페이지 단위 0부터 시작
        let start=curPage*perPage;
        let end= start+perPage < filteredList.length ? start+perPage : filteredList.length;
        let curPageOrderList=filteredList.slice(start, end);
        let pgNumArr=[];
        for(let i=0; i<numberOfPage; i++){ pgNumArr.push(i); }
        let pgStart=pgFirst;
        let pgEnd=pgFirst+pgBtnNum;
        let pgBtnArr=pgNumArr.slice(pgStart, pgEnd);

        return(
            <Card className={this.props.classes.card}>
                <div className={this.props.classes.cardHeader}>
                    <CardHeader title={`${title} (${filteredTotal})`}></CardHeader>
                    <select 
                        className={this.props.classes.durationSelect}
                        onChange={e => this.chngDuration(e)}>
                        <option value='entire'> 전체기간 </option>
                        <option value='d30'> {`${d30.getFullYear()}.${d30.getMonth()+1}.${d30.getDate()} ~ ${year}.${month+1}.${date}`} </option>
                    </select>
                </div>
                <OrderList curPageOrderList={curPageOrderList}/> 
                <div className={ filteredTotal === 0 ? this.props.classes.zeroBtn :  this.props.classes.buttons}>
                    <Button type='button' className={this.props.classes.moveBtn}  onClick={ e => this.preBtn()}>이전</Button>
                    <span>
                        {pgBtnArr.map((number, index)=>{
                            return curPage === number ? <Button key={`btn${index}`} className={this.props.classes.curBtn} onClick={ e => this.numBtn(number)}>{number+1}</Button> 
                                : <Button key={`btnSelected${index}`} className={this.props.classes.numBtn} onClick={ e => this.numBtn(number)}>{number+1}</Button>;
                        })}
                    </span>
                    <Button className={this.props.classes.moveBtn} onClick={ e => this.nextBtn(numberOfPage) }>다음</Button>
                </div> 
            </Card>
        )
    }
}

const enhance=compose(
    withStyles(styles),
    translate,
);

export default enhance(OrderCard);