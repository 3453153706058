import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import '../../css/react_dates_overrides.css'
import moment from 'moment'
import React from 'react'
import TextField from '@material-ui/core/TextField'
import {addField, FieldTitle} from 'react-admin'
import {DayPickerSingleDateController, isInclusivelyAfterDay} from 'react-dates'
import {SingleDatePickerPhrases} from 'react-dates/lib/defaultPhrases'
import OutsideClickHandler from 'react-outside-click-handler'
import {css, withStyles} from 'react-with-styles'
import {
  ANCHOR_LEFT,
  DAY_SIZE,
  DEFAULT_VERTICAL_SPACING,
  FANG_HEIGHT_PX,
  FANG_WIDTH_PX,
  HORIZONTAL_ORIENTATION,
  ICON_BEFORE_POSITION,
  INFO_POSITION_BOTTOM,
  OPEN_DOWN,
  OPEN_UP
} from 'react-dates/lib/constants'
import {addEventListener} from 'consolidated-events'

const FANG_PATH_TOP = `M0,${FANG_HEIGHT_PX} ${FANG_WIDTH_PX},${FANG_HEIGHT_PX} ${FANG_WIDTH_PX / 2},0z`
const FANG_STROKE_TOP = `M0,${FANG_HEIGHT_PX} ${FANG_WIDTH_PX / 2},0 ${FANG_WIDTH_PX},${FANG_HEIGHT_PX}`
const FANG_PATH_BOTTOM = `M0,0 ${FANG_WIDTH_PX},0 ${FANG_WIDTH_PX / 2},${FANG_HEIGHT_PX}z`
const FANG_STROKE_BOTTOM = `M0,0 ${FANG_WIDTH_PX / 2},${FANG_HEIGHT_PX} ${FANG_WIDTH_PX},0`

class SingleDatePickerWrapper extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isInputFocused: false,
      isDayPickerFocused: false,
      showKeyboardShortcuts: false,
      date: props.initialDate,
    }

    this.onDateChange = this.onDateChange.bind(this)
    this.onFocusChange = this.onFocusChange.bind(this)
    this.onFocusOut = this.onFocusOut.bind(this)
    this.onOutsideClick = this.onOutsideClick.bind(this)
    this.onDayPickerBlur = this.onDayPickerBlur.bind(this)
    this.showKeyboardShortcutsPanel = this.showKeyboardShortcutsPanel.bind(this)

    this.setDayPickerContainerRef = this.setDayPickerContainerRef.bind(this)
    this.setContainerRef = this.setContainerRef.bind(this)
  }

  componentDidMount() {
    if (this.props.input.value) {
      const {date} = this.props.input.value
      this.setState({date: moment(date)})
    }
    const {focused} = this.props
    if (focused) {
      this.setState({
        isInputFocused: true,
      })
    }
  }

  componentWillUnmount() {
    if (this.removeFocusOutEventListener) this.removeFocusOutEventListener()
  }

  onDateChange(date) {
    const {keepOpenOnDateSelect} = this.props
    this.props.input.onChange(date.format('YYYY-MM-DD'))
    this.setState({date})
    if (!keepOpenOnDateSelect) {
      this.setState({
        isInputFocused: false,
        isDayPickerFocused: false,
        showKeyboardShortcuts: false,
      })
      this.props.input.onBlur()
    }
  }

  onFocusChange({focused}) {
    this.setState({
      isInputFocused: focused
    })
  }

  onOutsideClick(event) {
    const {
      onClose,
      date,
    } = this.props
    const {isInputFocused} = this.state
    if (!isInputFocused) return

    if (this.dayPickerContainer.contains(event.target)) return
    this.setState({
      isInputFocused: false,
      isDayPickerFocused: false,
      showKeyboardShortcuts: false,
    })
    onClose({date})
  }

  onDayPickerBlur() {
    this.setState({
      isInputFocused: true,
      isDayPickerFocused: false,
      showKeyboardShortcuts: false,
    })
  }

  onFocusOut(e) {
    const {onFocusChange} = this.props
    if (this.container.contains(e.relatedTarget || e.target)) return
    onFocusChange({focused: false})
  }

  setDayPickerContainerRef(ref) {
    this.dayPickerContainer = ref
  }

  setContainerRef(ref) {
    if (ref === this.container) return
    this.removeEventListeners()

    this.container = ref
    if (!ref) return

    this.addEventListeners()
  }

  showKeyboardShortcutsPanel() {
    this.setState({
      isInputFocused: false,
      isDayPickerFocused: true,
      showKeyboardShortcuts: true,
    })
  }

  addEventListeners() {
    this.removeFocusOutEventListener = addEventListener(
      this.container,
      'focusout',
      this.onFocusOut,
    )
  }

  removeEventListeners() {
    if (this.removeFocusOutEventListener) this.removeFocusOutEventListener();
  }

  render() {
    const {className, meta, label, source, input, resource, isRequired, options, addLabel, ...pickerOptions} = this.props
    const {
      openDirection,
      enableOutsideDays,
      numberOfMonths,
      orientation,
      monthFormat,
      navPrev,
      navNext,
      onPrevMonthClick,
      onNextMonthClick,
      onClose,
      withPortal,
      withFullScreenPortal,
      keepOpenOnDateSelect,
      initialVisibleMonth,
      renderMonthText,
      renderCalendarDay,
      renderDayContents,
      renderCalendarInfo,
      renderMonthElement,
      calendarInfoPosition,
      hideKeyboardShortcutsPanel,
      firstDayOfWeek,
      phrases,
      dayAriaLabelFormat,
      daySize,
      isRTL,
      isOutsideRange,
      isDayBlocked,
      isDayHighlighted,
      weekDayFormat,
      styles,
      verticalHeight,
      transitionDuration,
      horizontalMonthPadding,
    } = pickerOptions
    const {touched, error} = meta
    const {isInputFocused, date, isDayPickerFocused, showKeyboardShortcuts} = this.state

    const withAnyPortal = withPortal || withFullScreenPortal

    const dateString = input.value
    let inputHeight = 34
    if (addLabel) inputHeight += 16
    if (!!(touched && error)) inputHeight += 20

    return (
      <div ref={this.setDayPickerContainerRef} onClick={this.onOutsideClick}>
        <OutsideClickHandler onOutsideClick={this.onOutsideClick}>
          <div style={{position: 'relative'}}>
            <TextField
              {...input}
              className={className}
              type="text"
              margin="normal"
              autoComplete="off"
              error={!!(touched && error && !isInputFocused)}
              helperText={touched && error}
              onFocus={() => this.setState({isInputFocused: true})}
              label={
                <FieldTitle
                  label={label}
                  source={source}
                  resource={resource}
                  isRequired={isRequired}
                />
              }
              InputLabelProps={{
                shrink: true,
              }}
              value={dateString}
              {...options}
            />
            {isInputFocused && <svg
              role="presentation"
              focusable="false"
              {...css(
                styles.DateInput_fang,
                openDirection === OPEN_DOWN && {
                  top: inputHeight + FANG_HEIGHT_PX + 2,
                },
                openDirection === OPEN_UP && {
                  bottom: inputHeight + FANG_HEIGHT_PX + 2,
                },
              )}>
              <path
                {...css(styles.DateInput_fangShape)}
                d={openDirection === OPEN_DOWN ? FANG_PATH_TOP : FANG_PATH_BOTTOM}
              />
              <path
                {...css(styles.DateInput_fangStroke)}
                d={openDirection === OPEN_DOWN ? FANG_STROKE_TOP : FANG_STROKE_BOTTOM}
              />
            </svg>}
          </div>
          {isInputFocused && <DayPickerSingleDateController
            date={date}
            onDateChange={this.onDateChange}
            onFocusChange={this.onFocusChange}
            orientation={orientation}
            enableOutsideDays={enableOutsideDays}
            numberOfMonths={numberOfMonths}
            monthFormat={monthFormat}
            withPortal={withAnyPortal}
            focused={isInputFocused}
            keepOpenOnDateSelect={keepOpenOnDateSelect}
            hideKeyboardShortcutsPanel={hideKeyboardShortcutsPanel}
            initialVisibleMonth={initialVisibleMonth}
            navPrev={navPrev}
            navNext={navNext}
            onPrevMonthClick={onPrevMonthClick}
            onNextMonthClick={onNextMonthClick}
            onClose={onClose}
            renderMonthText={renderMonthText}
            renderCalendarDay={renderCalendarDay}
            renderDayContents={renderDayContents}
            renderCalendarInfo={renderCalendarInfo}
            renderMonthElement={renderMonthElement}
            calendarInfoPosition={calendarInfoPosition}
            isFocused={isDayPickerFocused}
            showKeyboardShortcuts={showKeyboardShortcuts}
            onBlur={this.onDayPickerBlur}
            phrases={phrases}
            dayAriaLabelFormat={dayAriaLabelFormat}
            daySize={daySize}
            isRTL={isRTL}
            isOutsideRange={isOutsideRange}
            isDayBlocked={isDayBlocked}
            isDayHighlighted={isDayHighlighted}
            firstDayOfWeek={firstDayOfWeek}
            weekDayFormat={weekDayFormat}
            verticalHeight={verticalHeight}
            transitionDuration={transitionDuration}
            horizontalMonthPadding={horizontalMonthPadding}
          />}
        </OutsideClickHandler>
      </div>
    )
  }
}


const defaultProps = {
  date: null,
  focused: false,
  disabled: false,
  required: false,
  readOnly: false,
  screenReaderInputMessage: '',
  showClearDate: false,
  showDefaultInputIcon: false,
  inputIconPosition: ICON_BEFORE_POSITION,
  customInputIcon: null,
  customCloseIcon: null,
  noBorder: false,
  block: false,
  small: false,
  regular: false,
  verticalSpacing: DEFAULT_VERTICAL_SPACING,
  keepFocusOnInput: false,

  // calendar presentation and interaction related props
  orientation: HORIZONTAL_ORIENTATION,
  anchorDirection: ANCHOR_LEFT,
  openDirection: OPEN_DOWN,
  horizontalMargin: 0,
  withPortal: false,
  withFullScreenPortal: false,
  appendToBody: false,
  disableScroll: false,
  initialVisibleMonth: null,
  firstDayOfWeek: null,
  numberOfMonths: 2,
  keepOpenOnDateSelect: false,
  reopenPickerOnClearDate: false,
  renderCalendarInfo: null,
  calendarInfoPosition: INFO_POSITION_BOTTOM,
  hideKeyboardShortcutsPanel: false,
  daySize: DAY_SIZE,
  isRTL: false,
  verticalHeight: null,
  transitionDuration: undefined,
  horizontalMonthPadding: 13,

  // navigation related props
  navPrev: null,
  navNext: null,

  onPrevMonthClick() {
  },
  onNextMonthClick() {
  },
  onClose() {
  },

  // month presentation and interaction related props
  renderMonthText: null,

  // day presentation and interaction related props
  renderCalendarDay: undefined,
  renderDayContents: null,
  renderMonthElement: null,
  enableOutsideDays: false,
  isDayBlocked: () => false,
  isOutsideRange: day => !isInclusivelyAfterDay(day, moment()),
  isDayHighlighted: () => {
  },

  // internationalization props
  displayFormat: () => moment.localeData().longDateFormat('L'),
  monthFormat: 'MMMM YYYY',
  weekDayFormat: 'dd',
  phrases: SingleDatePickerPhrases,
  dayAriaLabelFormat: undefined,
  addLabel: true,
}

SingleDatePickerWrapper.defaultProps = defaultProps

export default addField(withStyles(({reactDates: {color, zIndex}}) => ({
  DateInput_fang: {
    position: 'fixed',
    width: FANG_WIDTH_PX,
    height: FANG_HEIGHT_PX,
    left: 22,
    zIndex: zIndex + 2,
  },

  DateInput_fangShape: {
    fill: color.background,
  },

  DateInput_fangStroke: {
    stroke: color.core.border,
    fill: 'transparent',
  }
}), {pureComponent: typeof React.PureComponent !== 'undefined'})(SingleDatePickerWrapper))
