import React from 'react'
import {
    TopToolbar,
    DateField,
    ListButton,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField,
    translate,
} from 'react-admin'
import StockReviewReplyButton from './StockReviewReplyButton'
import CustomDeleteButton from '../../custom/common/CustomDeleteButton'
import StarRatingField from '../../custom/common/StarRatingField'

const ShowActions = ({basePath, resource, data = {}, ...props}) => {
    return (
        <TopToolbar>
            <ListButton basePath={basePath}/>
            {data.id && <StockReviewReplyButton basePath={basePath} record={data}/>}
            {data.id && <CustomDeleteButton basePath={basePath} resource={resource} record={data}
                                            submitOnEnter={false}
                                            variant="dense"/>}
        </TopToolbar>
    )
}

class StockReviewShow extends React.Component {
    render() {
        const {translate, ...props} = this.props
        return <Show {...props} actions={<ShowActions/>}>
            <SimpleShowLayout>
                <ReferenceField source="stockId" reference="stocks" link="show">
                    <TextField source="id"/>
                </ReferenceField>
                <ReferenceField source="productId" reference="products" link="show">
                    <TextField source="name" label="상품"/>
                </ReferenceField>
                <StarRatingField source="rating"/>
                <TextField style={{whiteSpace: 'pre'}} source="content"/>
                <TextField style={{whiteSpace: 'pre'}} source="reply"/>
                <TextField source="name" label="판매자"/>
                <DateField source="createdAt" locales="ko" showTime/>
            </SimpleShowLayout>
        </Show>
    }
}

export default translate(StockReviewShow)
