import BookIcon from '@material-ui/icons/Book'
import KeywordCreate from './KeywordCreate'
import KeywordEdit from './KeywordEdit'
import KeywordList from './KeywordList'

export default {
  list: KeywordList,
  create: KeywordCreate,
  edit: KeywordEdit,
  icon: BookIcon,
}
