import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {getResources, MenuItemLink, usePermissions, DashboardMenuItem} from 'react-admin'
import {useHistory} from 'react-router-dom'
import {useSelector} from 'react-redux'
import ViewModuleIcon from '@material-ui/icons/ViewModule'
import TocIcon from '@material-ui/icons/Toc'
import SubMenu from './SubMenu'

const menus = [
  {
    title: 'Dashboard',
    isDashboard: true,
    items: ['dashboard'],
    names: ['dashboard'],
    roles: [
      ['master', 'admin', 'manager'],
    ]
  },
  {
    title: '매입 및 판매 관리',
    items: ['orders', 'userSells', 'userSellOrders', 'stocks', 'goods', 'wishList', 'reviews', 'stockReviews'],
    names: ['주문 관리', '직거래 상품 관리', '직거래 주문 관리', '매입 관리', '상품 관리', '요청 관리', '구매평 관리','매각 후기 관리'],
    roles: [
      ['master', 'admin', 'manager'],
      ['master', 'admin', 'manager'],
      ['master', 'admin', 'manager'],
      ['master', 'admin', 'manager'],
      ['master', 'admin', 'manager'],
      ['master', 'admin', 'manager'],
      ['master', 'admin', 'manager'],
      ['master', 'admin', 'manager']
    ]
  },
  {
    title: '재고 관리',
    items: ['stocksByGoods', 'stocksByProducts'],
    names: ['상품별 재고','품목별 재고'],
    roles: [
      ['master', 'admin', 'manager'],
      ['master', 'admin', 'manager'],
    ]
  },
  {
    title: '매출 관리',
    items: ['sales'],
    names: ['매출 및 매입 관리'],
    roles: [
      ['master', 'admin', 'manager']
    ]
  },
  {
    title: '사용자 관리',
    items: ['users', 'questions', 'devices'],
    names: ['사용자 관리', '문의 관리', '디바이스 관리', 'CS 관리', '메세지 관리'],
    roles: [
      ['master', 'admin', 'manager'],
      ['master', 'admin', 'manager'],
      ['master', 'admin', 'manager']
    ]
  },
  {
    title: '상품 정보 관리',
    items: ['products', 'prices', 'categories', 'inspections', 'events', 'brands', 'brandCategories', 'banners',
      'researches'],
    names: ['품목', '가격 관리', '카테고리', '검수 항목', '이벤트', '브랜드', '브랜드 카테고리', '배너 관리',
      '가격 조사'],
    roles: [
      ['master', 'admin'],
      ['master', 'admin'],
      ['master', 'admin'],
      ['master', 'admin'],
      ['master', 'admin'],
      ['master', 'admin'],
      ['master', 'admin'],
      ['master', 'admin'],
      ['master', 'admin'],
      ['master', 'admin'],
    ]
  },
  {
    title: '서비스 관리',
    items: ['couponsBases', 'coupons', 'keywords', 'popups', 'defaultImages', 'faqs', 'pointsEvents', 'notices', 'boards', 'blogs'],
    names: ['쿠폰 등록', '쿠폰 내역', '키워드 관리', '팝업 관리', '기본 이미지 관리', 'FAQ 관리', '포인트 이벤트 관리', '공지사항 관리', '게시판 관리', '블로그 관리'],
    roles: [
      ['master', 'admin'],
      ['master', 'admin'],
      ['master', 'admin'],
      ['master', 'admin'],
      ['master', 'admin'],
      ['master', 'admin'],
      ['master', 'admin'],
      ['master', 'admin'],
        ['master', 'admin']
    ]
  },
  {
    title: '시스템 관리',
    items: ['admins', 'affiliations', 'emailSettings'],
    names: ['관리자 설정', '소속 관리', '이메일 노티 설정', '정산 관리'],
    roles: [
      ['master', 'admin'],
      ['master', 'admin'],
      ['master', 'admin'],
      ['master', 'admin']
    ]
  },
  {
    title: '개발 및 운영 관리',
    items: ['devPosts', 'operatorPosts'],
    names: ['개발 업데이트 공지', '운영자 게시판'],
    roles: [
      ['master', 'admin', 'manager'],
      ['master', 'admin', 'manager']
    ]
  },
  {
    title: '기업 관리',
    items: ['businesses', 'businessReceipts'],
    names: ['기업 관리', '현금 영수증 발급 관리'],
    roles: [
      ['master', 'admin'],
      ['master', 'admin']
    ]
  }
]

const Menu = ({onMenuClick, dense}) => {
  const history = useHistory()
  const [state, setState] = useState({
    menus: menus.map(({items}) => ({selected: items && !!items.find((item) => history.location.pathname.indexOf(item) > 0)}))
  })
  const resources = useSelector(getResources)

  const handleToggle = (index) => {
    state.menus[index].selected = !state.menus[index].selected
    setState((state) => ({...state}))
  }
  const {permissions} = usePermissions()
  const open = useSelector((state) => state.admin.ui.sidebarOpen)
  return menus
    .filter((menu) => menu.roles.filter((item) => (item ? item.indexOf(permissions) > -1 : true)).length)
    .map((menu, index) => {
      let items = null

      if (menu.isDashboard) {
        return <DashboardMenuItem onClick={onMenuClick} />
      }
      else {
        items = menu.items.map((item, index) => {
          const resource = resources.find((resource) => resource.name === item)
          if (!resource) return null
          if (!menu.roles[index] || menu.roles[index].indexOf(permissions) > -1) {
            if (resource) {
              if (resource.name === 'configuration') {
                return (
                  <MenuItemLink
                    key={index}
                    dense={dense}
                    onClick={onMenuClick}
                    to="/configuration/edit"
                    primaryText={menu.names[index]}
                    leftIcon={resource.icon ? <resource.icon /> : <TocIcon />}
                  />
                )
              }
              return (
                <MenuItemLink
                  key={index}
                  to={`/${resource.name}`}
                  leftIcon={resource.icon ? <resource.icon /> : <TocIcon />}
                  primaryText={menu.names[index]}
                  dense={dense}
                  onClick={onMenuClick}
                />
              )
            }
            return (
              <MenuItemLink
                disabled
                key={index}
                to="/"
                leftIcon={resource.icon ? <resource.icon /> : <TocIcon />}
                primaryText={`${item} (개발)`}
                dense={dense}
                onClick={onMenuClick}
              />
            )
          }
          return null
        })
      }

      return (
        <SubMenu
          key={index}
          isOpen={state.menus[index].selected}
          handleToggle={() => handleToggle(index)}
          icon={<ViewModuleIcon />}
          sidebarIsOpen={open}
          name={menu.title}
          dense={dense}
        >
          {items}
        </SubMenu>
      )
    })
}

Menu.propTypes = {
  onMenuClick: PropTypes.func,
  logout: PropTypes.object
}

export default Menu
