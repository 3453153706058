import React, {PureComponent} from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import {withStyles} from '@material-ui/core/styles';
import {CardHeader, Button} from '@material-ui/core';
import {translate} from 'react-admin';
import QuestionList from "./QuestionList";

const styles ={
    card:{
        minWidth : 500,
    },
    cardHeader:{
        display:'flex',
        justifyContent: 'space-between',
        alignItems :'center'
    },
    durationSelect:{
        marginRight: 16,
        height: 30,
        fontSize : '1rem',
    },
    buttons : {
        marginBottom : 10,
        textAlign : 'center',
    },
    zeroBtn :{
        display : 'none',
    },
    moveBtn :{
        marginLeft:10,
        marginRight:10,
        border:0,
        outline: 0,
        backgroundColor:'white',
        color : '#3f51b5',
        fontSize : 15,
    },
    numBtn:{
        borderRadius :50,
    },
    curBtn:{
        border:0,
        borderRadius :50,
        backgroundColor: '#3f51b5',
        color : 'white',
        fontWeight : 'bold',
    }
}

class QuestionCard extends PureComponent{
    constructor(props){
        super(props);
            this.state={
                curPage : 1,
                pgBtnNum : 3,
                pgFirst : 0,
                selectedDuration : 'entire'
            }
            this.chngDuration=this.chngDuration;
            this.pageBtn=this.pageBtn;
    }

    // getToday(){
    //     const year=new Date().getFullYear();
    //     const month=new Date().getMonth();
    //     const date=new Date().getDate();

    //     return `${year}-${month+1 < 10 ? '0'+(month+1) : month+1}-${date < 10 ? '0'+date : date}`
    // }
    
    // getD30(){
    //     const year=new Date().getFullYear();
    //     const month=new Date().getMonth();
    //     const date=new Date().getDate();
    //     const d30=new Date(year,month, date);
    //     d30.setDate(d30.getDate()-30);
        
    //     return `${d30.getFullYear()}-${d30.getMonth()+1 < 10 ? '0'+(d30.getMonth()+1) : d30.getMonth()+1}-${d30.getDate() < 10 ? '0'+d30.getDate() : d30.getDate()}`;
    // }

    // chngDuration(e){ 
    //     const {type}=this.props;

    //     if(e.target.value === 'entire'){
    //         this.setState({ curPage : 1, pgFirst : 0, selectedDuration : 'entire'}, ()=>{
    //             this.props.fetchStocks(type, this.state.curPage);
    //         })
    //     }else if(e.target.value === 'd30'){
    //         const d30 = this.getD30();
    //         const today = this.getToday();

    //         this.setState({curPage : 1, pgFirst:0, selectedDuration : 'd30'}, ()=>{
    //             this.props.fetchStocks(type, this.state.curPage, d30, today);
    //         })
    //     }
    // }

    pageBtn(btnType, index, numberOfPage){
        let {type, fetchQuestions}=this.props;
        let nextPage;

        if(btnType === 'pre'){
            if(this.state.curPage === 1) return;
            nextPage=this.state.curPage-1;
        }else if(btnType === 'next'){
            if(this.state.curPage === numberOfPage) return;
            nextPage=this.state.curPage+1;
        }else if(btnType === 'num'){
            if(this.state.curPage === index) return;
            nextPage = index;
        }

        switch(this.state.selectedDuration){
            case 'entire':
                fetchQuestions(type, nextPage);
                break;
            case 'd30':
                const today=this.getToday();
                const d30=this.getD30();
                fetchQuestions(type, nextPage, d30, today);
                break;
            default:
                break;
        }

        switch(btnType){
            case 'pre':
                this.setState({ curPage : this.state.curPage-1 }, ()=>{
                    if(this.state.pgFirst > this.state.curPage-1){
                        this.setState({pgFirst : this.state.pgFirst - this.state.pgBtnNum})
                    }
                })
                break;
            case 'next':
                this.setState({ curPage : this.state.curPage+1 }, ()=>{
                    if(this.state.pgFirst+this.state.pgBtnNum === this.state.curPage-1){
                        this.setState({pgFirst : this.state.pgFirst + this.state.pgBtnNum})
                    }
                })
                break;
            case 'num':
                this.setState({ curPage : nextPage})
                break;
            default:
                break;
        }
    }

    render(){
        const {title, questions, total, perPage}=this.props;
        const {curPage, pgFirst, pgBtnNum} = this.state;
        const today = new Date()
        const d30=new Date(today.getFullYear(),today.getMonth(), today.getDate());
        d30.setDate(d30.getDate()-30);

        let numberOfPage = (total % perPage) === 0 ? (total/perPage) : Math.floor((total/perPage)+1); // 페이지 단위 0부터 시작
        let pgNumArr=[];
        for(let i=0; i<numberOfPage; i++){ pgNumArr.push(i); }
        let pgStart=pgFirst;
        let pgEnd=pgFirst+pgBtnNum;
        let pgBtnArr=pgNumArr.slice(pgStart, pgEnd);




        return(
            <Card className={this.props.classes.card}>
                <div className={this.props.classes.cardHeader}>
                    <CardHeader title={`${title} (${total})`}></CardHeader>
                    {/* <select 
                        className={this.props.classes.durationSelect}
                        onChange={e => this.chngDuration(e)}>
                        <option value='entire'> 전체기간 </option>
                        <option value='d30'> {`${d30.getFullYear()}.${d30.getMonth()+1}.${d30.getDate()} ~ ${today.getFullYear()}.${today.getMonth()+1}.${today.getDate()}`} </option>
                    </select> */}
                </div>
                
                <QuestionList questions={questions}/>

                <div className={ total === 0 ? this.props.classes.zeroBtn :  this.props.classes.buttons}>
                    <Button type='button' className={this.props.classes.moveBtn}  onClick={ e => this.pageBtn('pre')}>이전</Button>
                    <span>
                        {pgBtnArr.map((number, index)=>{
                            if(curPage === number+1){
                                return <Button key={index} className={this.props.classes.curBtn} onClick={ e => this.pageBtn('num', number+1)}>{number+1}</Button>
                            }
                            return <Button key={index} className={this.props.classes.numBtn} onClick={ e => this.pageBtn('num', number+1)}>{number+1}</Button>;
                        })}
                    </span>
                    <Button className={this.props.classes.moveBtn} onClick={ e => this.pageBtn('next', null, numberOfPage) }>다음</Button>
                </div> 
            </Card>
        )
    }
}

const enhance=compose(
    withStyles(styles),
    translate,
);

export default enhance(QuestionCard);