import React, {useState} from 'react'
import {Button as AdminButton, crudGetOne} from 'react-admin'
import Button from '@material-ui/core/Button'
import ReceiptIcon from '@material-ui/icons/Receipt'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import {dataProvider} from '../../App'
import {useDispatch} from 'react-redux'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles({
  dialog: {
    width: '20%'
  },
  button: {}
})

export default (props) => {
  const [isOpen, setOpen] = useState(false)
  const dispatch = useDispatch()
  const classes = useStyles()

  function handleClick() {
    setOpen(true)
  }

  function handleCancel() {
    setOpen(false)
  }

  async function handleDone(e) {
    e.stopPropagation()
    const {basePath, record, resource} = props
    const {data} = await dataProvider('REQUEST_RECEIPT', 'businessReceipts', {data: {businessId: record.id}})
    if (data) {
      dispatch(crudGetOne('businesses', record.id, basePath, true))
    } else {
      alert('요청을 실패 하였습니다.')
    }
    handleCancel()
  }

  return (<div>
    <AdminButton label="현금 영수증" onClick={handleClick}>
      <ReceiptIcon/>
    </AdminButton>
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={isOpen}
      classes={{paper: classes.dialog}}
    >
      <DialogTitle id="confirmation-dialog-title">현금 영수증 요청</DialogTitle>
      <DialogContent>
        {props.record && props.record.receipt && (props.record.receipt.price !== 0) ?
          `${props.record.receipt.price.toLocaleString()}원에 대하여 현금 영수증을 요청 하시겠습니까?`
          : '요청할 금액이 없습니다.'}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          취소
        </Button>
        <Button onClick={handleDone} color="primary">
          확인
        </Button>
      </DialogActions>
    </Dialog>
  </div>)
}
