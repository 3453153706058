import BookIcon from '@material-ui/icons/Book'

import SalesList from './SalesList'
import SalesShow from './SalesShow'

export default {
    list: SalesList,
    show: SalesShow,
    icon: BookIcon,
}
