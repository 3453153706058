import BookIcon from '@material-ui/icons/Book'
import BrandCategoryList from './brandCategoryList'
import BrandCategoryCreate from './brandCategoryCreate'
import BrandCategoryEdit from './brandCategoryEdit'


export default {
    list : BrandCategoryList,
    create : BrandCategoryCreate,
    edit : BrandCategoryEdit,
    icon : BookIcon,
}