import {
  CREATE,
  DELETE,
  GET_LIST,
  GET_MANY,
  GET_ONE,
  UPDATE,
} from 'react-admin'

export function request(apiUrl, type, params) {
  let url = apiUrl + '/admin/shops'
  let options = {}

  switch (type) {
    case CREATE:
      options.method = 'POST'
      options.data = {
        ...params.data,
        categoryId: params.data.parentIds[params.data.parentIds.length - 1]
      }
      delete options.data.parentIds
      break
    case GET_LIST:
    case GET_MANY:
      if (!params.pagination) {
        params.pagination = {page: 1, perPage: 10000}
        params.sort = {field: null, order: null}
      }
      const {page, perPage} = params.pagination
      const {field, order} = params.sort
      options.params = {
        ...params.filter,
        sort: field,
        order: order,
        start: (page - 1) * perPage,
        perPage: perPage,
      }
      break
    case GET_ONE:
      url += `/${params.id}`
      break
    case UPDATE:
      url += `/${params.id}`
      options.method = 'PUT'
      delete params.data.shopCategories
      delete params.data.createdAt
      options.data = params.data
      break
    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break
    case 'GET_SHOP_CODE':
      url += `/code`
      options.method = 'GET'
      break
    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res
  switch (type) {
    case GET_LIST:
    case GET_MANY:
      ret = {data: data.list, total: data.total}
      break
    case GET_ONE:
      if (data) ret = {data}
      break
    default:
      if (data) ret = {data}
      break
  }
  return ret
}
