import BookIcon from '@material-ui/icons/Book'
import PriceCreate from './PriceCreate'
import PriceEdit from './PriceEdit'
import PriceList from './PriceList'
import PriceShow from './PriceShow'

export default {
  list: PriceList,
  create: PriceCreate,
  edit: PriceEdit,
  show: PriceShow,
  icon: BookIcon,
}
