import {CREATE, DELETE, GET_LIST, GET_ONE, UPDATE,} from 'react-admin'
import _dataprovider from './index.js'

async function getSiteId(selectedSite){
  const dataProvider=_dataprovider(process.env.REACT_APP_URL);
  const idList = await dataProvider(
    GET_LIST,
    'sites',
    {}
  );
  const selected = idList.data.filter(site => {
    return site.name === selectedSite;
  })
  return selected[0].id;
}

export async function request(apiUrl, type, params) {
  let url = apiUrl + '/admin/keywords'
  let options = {}
  let selectedSite='mybaro';
  let siteId, keyword, order;

  switch (type) {
    case CREATE:
      selectedSite= params.data.site;
      siteId=await getSiteId(selectedSite);
      keyword=params.data.keyword;
      order=params.data.order;
      options.method = 'POST'
      options.data = {siteId, keyword, order};
      break
    case GET_ONE:
      url += `/${params.id}`
      break
    case GET_LIST:
      options.method = 'GET'  
      selectedSite = params.filter.site ? params.filter.site : selectedSite;
      siteId = await getSiteId(selectedSite);
      options.params ={
        siteId : siteId,
      }

      break
    case UPDATE:
      url += `/${params.id}`
      siteId=params.data.siteId;
      keyword=params.data.keyword;
      order=params.data.order;
      options.method = 'PUT'
      options.data = {siteId, keyword, order}
      break
    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break
    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res
  switch (type) {
    case GET_LIST:
      ret = {data, total: data.length}
      break
    case GET_ONE:
      ret = {data}
      break
    case UPDATE:
      ret = params
      break
    default:
      if (data) ret = {data}
      break
  }
  return ret
}
