import {withStyles} from '@material-ui/core/styles'
import React, {useState, useEffect} from 'react'
import {dataProvider, history} from "../../App";
import numeral from 'numeral'

import {
    Datagrid,
    Filter,
    ImageField,
    List,
    NumberField,
    TextField,
    translate,
    SelectInput, GET_LIST, DateField
} from 'react-admin'

const styles = theme => ({
    title: {
        overflow: 'hidden'
    },
    select: {
        height:'3rem',
        padding: '0 0',
        margin: 0,
        overflow: 'hidden',
        width: '6rem',
    },
    text: {
        maxWidth: '200px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow:'ellipsis'
    },
})

const ThumbnailImageField = withStyles({image: {height: 60}})(ImageField)

const loadRelatedCategories = (props, change) => {
    let temp = {title:[], size: [], year: [], category: []}
    const {categories, filterValues } = props

    if(change.name === 'brandId') {
        categories.forEach(row => {
            if (row.brandId === change.value) {
                let t_row = {id: (row.id + ','+ row.name), name: row.name}
                temp.title.push(t_row)
            }
        })
        props.setFilters({brandId: change.value, status: filterValues.status})
    } else if(filterValues.brandId) {
        categories.forEach(row => {
            if (row.brandId === filterValues.brandId) {
                let t_row = {id: (row.id + ','+ row.name), name: row.name}
                temp.title.push(t_row)
            }
        })
    }

    if(change.name === 'title') {
        categories.forEach(row => {
            if(row.parentId === Number((change.value).split(',')[0])) {
                let t_row = {id: (row.id + ','+ row.name), name: row.name}
                temp.size.push(t_row)
            }
        })
        props.setFilters({brandId: filterValues.brandId, title: change.value, status: filterValues.status})
    } else if(filterValues.title && change.name !== 'brandId') {
        categories.forEach(row => {
            if(row.parentId === Number((filterValues.title).split(',')[0])) {
                let t_row = {id: (row.id + ','+ row.name), name: row.name}
                temp.size.push(t_row)
            }
        })
    }

    if(change.name === 'size') {
        categories.forEach(row => {
            if(row.parentId === Number((change.value).split(',')[0])) {
                let t_row = {id: (row.id + ','+ row.name), name: row.name}
                temp.year.push(t_row)
            }
        })
        props.setFilters({brandId: filterValues.brandId, title: filterValues.title, size: change.value, status: filterValues.status})
    } else if(filterValues.size && change.name !== 'brandId' && change.name !== 'title') {
        categories.forEach(row => {
            if(row.parentId === Number((filterValues.size).split(',')[0])) {
                let t_row = {id: (row.id + ','+ row.name), name: row.name}
                temp.year.push(t_row)
            }
        })
    }

    if(change.name === 'year') {
        categories.forEach(row => {
            if(row.parentId === Number((change.value).split(',')[0])) {
                let t_row = {id: (row.id + ','+ row.name), name: row.name}
                temp.category.push(t_row)
            }
        })
        props.setFilters({brandId: filterValues.brandId, title: filterValues.title, size: filterValues.size, year: change.value, status: filterValues.status})
    } else if(filterValues.year && change.name !== 'brandId' && change.name !== 'title' && change.name !== 'size') {
        categories.forEach(row => {
            if(row.parentId === Number((filterValues.year).split(',')[0])) {
                let t_row = {id: (row.id + ','+ row.name), name: row.name}
                temp.category.push(t_row)
            }
        })
    }

    const result = {title: temp.title, size: temp.size, year: temp.year, category: temp.category}

    return result
}

const ListActions = (props) => {

    let sumSellCount = 0, sumBuyPrice = 0, sumPrice = 0
    props.ids.map(row => {
        sumSellCount += props.data[row].quantity
        sumBuyPrice += props.data[row].buyPrice
        if(props.data[row].status === '판매 중')
            sumPrice += props.data[row].price * props.data[row].quantity
    })

    sumSellCount = numeral(sumSellCount).format('0,0')
    sumBuyPrice = numeral(sumBuyPrice).format('0,0')
    sumPrice = numeral(sumPrice).format('0,0')

    return (
        <div>
            <div style={{textAlign: 'right'}}>총 {sumSellCount}대</div>
            <div style={{textAlign: 'right'}}>매입 {sumBuyPrice}원</div>
            <div style={{textAlign: 'right'}}>판매 {sumPrice}원</div>
        </div>
    )
}

const ListFilter = (props) => {
    const [relatedCategories, setRelatedCategories] = useState({title: [], size:[], year:[], category:[]})

    useEffect(() => {
        setRelatedCategories(loadRelatedCategories(props, {name: null, value: null}))
    }, [props.categories])

    const selectInput = (category, index) => {
        return (
            relatedCategories[category].length ?
                <SelectInput source={category}
                             choices={relatedCategories[category]}
                             className={props.classes.select}
                             label={index + "차 카테고리"}
                             onChange={(e) => setRelatedCategories(loadRelatedCategories(props, e.target))}
                             alwaysOn /> : <></>
        )
    }

    return (
            props.brands ?  <Filter {...props}>
            <SelectInput source="status" className={props.classes.select} choices={[
                {id: 'all', name: '전체'},
                {id: 'onSale', name: '판매 중'},
                {id: 'soldOut', name: '판매 완료'},
                {id: 'onRepair', name: '수리 중'},
                {id: 'onUseInside', name: '내부 사용 중'}
            ]} label="상태" alwaysOn/>
            <SelectInput source="brandId"
                         choices={props.brands.data}
                         className={props.classes.select}
                         onChange={(e) => setRelatedCategories(loadRelatedCategories(props, e.target))}
                         alwaysOn/>
            {selectInput('title', 1)}
            {selectInput('size', 2)}
            {selectInput('year', 3)}
            {selectInput('category', 4)}</Filter>: <></>
    )
}

const handleRowClick = (id) => {
    history.push(`/goods/${id}/show`)
}

export default withStyles(styles)(translate(({classes, translate, ...props}) => {
    const [brands, setBrands] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const loadBrands = async () => {
            let options = {
                pagination: {
                    page: 1,
                    perPage: 100
                },
                sort: {
                    field: 'id',
                    order: 'ASC',
                }
            }
            const result = await dataProvider(GET_LIST, 'brands', options)
            setBrands(result);
        }
        const loadCategories = async () => {
            const result = await dataProvider(GET_LIST, 'categories')

            setCategories(result.data)
        }
        loadBrands()
        loadCategories()
    }, [])

    return (
        <List {...props}
              classes={classes}
              actions={<ListActions props={props} />}
              filters={<ListFilter props={props} brands={brands} categories={categories} classes={classes}/>}
              sort={{field: 'buyDate', order: 'DESC'}}
              perPage={500}
              title='상품별 재고'
              bulkActionButtons={false}>
            <Datagrid rowClick={handleRowClick} className={classes.datagrid}>
                <TextField source="status" label="상태" sortable={false} />
                <ThumbnailImageField source="titleImage" label="대표 이미지" sortable={false}/>
                <TextField source="id" label="상품 번호" sortable={false} />
                <TextField source="naverCode" label="네이버 코드" sortable={false}/>
                <TextField source="title" label="카" />
                <TextField source="size" label="테" />
                <TextField source="year" label="고" />
                <TextField source="category" label="리" />
                <NumberField source="buyPrice" label="매입가" />
                <NumberField source="price" label="판매가" />
                <NumberField source="margin" label="예상 마진" sortable={false} />
                <NumberField source="quantity" label="재고수량" sortable={false}/>
                <TextField source="memo" label="메모" cellClassName={classes.text} sortable={false}/>
                <DateField source="buyDate" label="매입일" locales='ko'/>
            </Datagrid>
        </List>
    )
}))

