import BookIcon from '@material-ui/icons/Book'
import ResearchCreate from './ResearchCreate'
import ResearchList from './ResearchList'
import ResearchShow from './ResearchShow'

export default {
  list: ResearchList,
  create: ResearchCreate,
  show: ResearchShow,
  icon: BookIcon,
}
