import {
    CREATE, DELETE,
    GET_LIST, GET_MANY,
    GET_ONE,
    UPDATE,
} from 'react-admin'
import {convertImagePath, uploadToS3} from './ImageHandler'
import _ from 'lodash'
import {dataProvider} from "../../App";

export async function request(apiUrl, type, params) {
    let url = apiUrl + '/admin/stocks'
    let options = {}

    switch (type) {
        case GET_LIST:
            const {page, perPage} = params.pagination
            const {field, order} = params.sort

            if(params.filter.title) {
                params.filter.title = params.filter.title.split(',')[1]
            }
            if(params.filter.size) {
                params.filter.size = params.filter.size.split(',')[1]
            }
            if(params.filter.year) {
                params.filter.year = Number(params.filter.year.split(',')[1])
            }
            if(params.filter.category) {
                params.filter.category = params.filter.category.split(',')[1]
            }

            options.params = {
                ...params.filter,
                order: order,
                start: (page - 1) * perPage,
                sort: field,
                perPage: perPage,
            }

            url += `/products/stocks`
            break
        case GET_MANY:
            if (params.ids.length === 1) url += `/${params.ids[0]}`
            break
        default:
            break
    }
    return {url, ...options}
}

export async function response(res, type, params) {
    let ret = {}
    const {data} = res
    switch (type) {
        case GET_LIST:
            data.data.forEach(row => {
                row.titleImage = convertImagePath(row.titleImage, 'small')
            })

            ret = data
            break
        case GET_MANY:
            if (params.ids.length === 1) ret = {data: [data]}
            else ret = {data}
            break
        default:
            if (data) ret = {data}
            break
    }
    return ret
}
