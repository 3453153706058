import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import GetOutSideGoods from '../../custom/goods/getOutSideGoods'
import {
  BooleanField,
  TopToolbar,
  CreateButton,
  Datagrid,
  DateField,
  Filter,
  ImageField,
  List,
  NumberField,
  SearchInput,
  TextField,
  translate,
  SelectInput
} from 'react-admin'

const styles = {
  root: {
    '& > div': {
      overflow: 'visible'
    },
  },
  search: {
    width: '18rem',
    height:'3rem',
    padding: '0 0',
    margin: 0
  },
  select: {
    height:'3rem',
    padding: '0 0',
    margin: 0
  }
}

const ThumbnailImageField = withStyles({image: {height: 60}})(ImageField)

const ListActions = ({basePath,}) => (
  <TopToolbar>
    <GetOutSideGoods basePath={basePath}/>
    <CreateButton basePath={basePath}/>
  </TopToolbar>
)

const ListFilter = props => (
  <Filter {...props}>
    <SearchInput placeholder={'상품번호/재고번호/상품명/네이버코드'} className={props.classes.search} source="search" alwaysOn/>
    <SelectInput source="division" className={props.classes.select} choices={[
      {id: 'all', name: '전체'},
      {id: 'outside', name: '외부 등록 상품'},
      {id: 'inside', name: '내부 등록 상품'}
    ]} alwaysOn/>
    <SelectInput source="status" className={props.classes.select} choices={[
      {id: 'all', name: '전체'},
      {id: 'onSale', name: '판매 중'},
      {id: 'soldOut', name: '판매 완료'},
      {id: 'onRepair', name: '수리 중'},
      {id: 'onUseInside', name: '내부 사용 중'},
    ]} label="상태" alwaysOn/>

  </Filter>
)

export default withStyles(styles)(translate(({classes, translate, ...props}) => {
  return (
    <List {...props}
          classes={classes}
          actions={<ListActions/>}
          filters={<ListFilter classes={classes}/>}
          perPage={25}
          sort={{field: 'createdAt', order: 'DESC'}}
          bulkActionButtons={false}>
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false}/>
        <ThumbnailImageField source="titleImage" sortable={false}/>
        <TextField source="stockNumber" sortable={false}/>
        <NumberField source="serial" sortable={false}/>
        <TextField source="naverCode" sortable={false}/>
        <TextField source="name"/>
        <NumberField source="originalPrice" sortable={false}/>
        <NumberField source="discount" sortable={false}/>
        <NumberField source="buyPrice" sortable={false}/>
        <DateField source="buyDate" label="매입 날짜"locales='ko' sortable={true}/>
        <TextField source="seller" label="판매자" sortable={false}/>
        <NumberField source="price"/>
        <BooleanField source="onSale" sortable={false}/>
        <BooleanField source="reserved" sortable={false}/>
        <NumberField source="quantity" sortable={false}/>
        <TextField source="status" label="상태" />
        <DateField source="createdAt" locales='ko'/>
      </Datagrid>
    </List>
  )
}))

