import React from 'react'
import {
  Create,
  FormDataConsumer,
  FormTab,
  GET_LIST,
  GET_ONE,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TabbedForm,
  TextInput,
  translate,
} from 'react-admin'
import {required} from '../../validators'
import SelectCategories from '../categories/SelectCategories'
import withProductRelations from '../../hocs/withProductRelations'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'
import {dataProvider} from '../../App'
import TextField from '@material-ui/core/TextField'

const BasicPriceField = ({classes, label, value}) => {
  return <TextField label={label} margin="normal"
                    disabled value={value}/>
}

class StockCreate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {inspections: [], product: null}
    this.handleProductChange = this.handleProductChange.bind(this)
    this.handleOptionChange = this.handleOptionChange.bind(this)
  }

  handleProductChange(formData, dispatch, value) {
    if (formData.productId !== value) {
      dataProvider(GET_ONE, 'products', {id: value})
        .then((data) => {
          this.setState({product: data.data})
        })
      dataProvider(GET_LIST, 'inspections', {productId: value})
        .then((data) => {
          this.setState({inspections: data})
        })
    }
  }

  handleOptionChange(formData, index, inspection, value) {
    const option = inspection.options.find(i => i.id === value)
    if (option) {
      return {
        ...inspection,
        price: option.price,
        optionId: option.id
      }
    }
  }

  handleMemoChange(inspection, memo) {
    return {
      ...inspection,
      memo
    }
  }

  render() {
    const {inspections} = this.state
    const {categories, translate, ...props} = this.props
    return (
      <Create {...props} actions={<CreateActions/>}>
        <TabbedForm
          toolbar={<CreateToolbar/>}
          initialValues={{parentIds: [], inspections: [], images: [], overall: ''}}>
          <FormTab label={translate('resources.products.summary')}>
            <FormDataConsumer>
              {props => <SelectCategories {...props} categories={categories}/>}
            </FormDataConsumer>

            <FormDataConsumer>
              {(props) => {
                if (!props.formData.parentIds) return ''
                const categoryId = props.formData.parentIds[props.formData.parentIds.length - 1]
                return <ReferenceInput
                  source="productId"
                  resource={props.resource}
                  filter={{categoryId}}
                  reference="products"
                  onChange={(e) => this.handleProductChange(props.formData, props.dispatch, e.target.value)}
                  validate={required}>
                  <SelectInput optionText="name"/>
                </ReferenceInput>
              }
              }
            </FormDataConsumer>

            <TextInput source="serial" validate={required}/>
            <TextInput multiline source="memo"/>
            <ImageInput source="systemImage" accept="image/*">
              <ImageField source="src" title="title"/>
            </ImageInput>

            <ImageInput multiple source="images" accept="image/*">
              <ImageField source="src" title="title"/>
            </ImageInput>
          </FormTab>
          <FormTab label='판매자 정보'>
            <TextInput source="seller.name"/>
            <TextInput source="seller.phone"/>
            <TextInput source="seller.bank"/>
            <TextInput source="seller.bankAccount"/>
            <TextInput source="seller.bankHolder"/>
          </FormTab>
          <FormTab label={translate('resources.inspections.name')}>
            <FormDataConsumer>
              {({formData}) => {
                if (!formData.inspections) return null
                formData.inspections = inspections
                return inspections.map((inspection, index) => {
                    return <div key={index}>
                      <TextInput disabled label={translate('resources.stocks.fields.inspections.id')}
                                 source={`inspections[${index}].id`}/>
                      <SelectInput label={inspection.name}
                                   source={`inspections[${index}].optionId`}
                                   onChange={(e) => {
                                     inspections[index] = this.handleOptionChange(formData, index, inspection, e.target.value)
                                     this.setState({inspections})
                                   }}
                                   choices={inspection.options}
                                   optionValue={'id'}
                                   style={{marginLeft: 20}}
                                   validate={required}/>
                      <NumberInput label={translate('resources.stocks.fields.inspections.price')}
                                   disabled={!formData.inspections[index] || !formData.inspections[index].optionId}
                                   source={`inspections[${index}].price`}
                                   style={{marginLeft: 20}}
                                   validate={required}/>
                      <TextInput label={translate('resources.stocks.fields.memo')}
                                 source={`inspections[${index}].memo`}
                                 onChange={(e) => {
                                   inspections[index] = this.handleMemoChange(inspection, e.target.value)
                                   this.setState({inspections})
                                 }}
                                 style={{marginLeft: 20}}/>
                    </div>
                  }
                )
              }}
            </FormDataConsumer>
            {this.state.product && <BasicPriceField label={translate('resources.stocks.fields.basicPrice')}
                                                    value={this.state.product.buyPrice}/>}
            <FormDataConsumer>
              {({formData}) => {
                if (!this.state.product || !formData.inspections) return null
                let subPrice = 0
                formData.inspections.filter(i => i.optionId)
                  .forEach(i => subPrice += i.price)
                return <BasicPriceField label={translate('resources.stocks.fields.subPrice')} value={subPrice}/>
              }}
            </FormDataConsumer>
            <FormDataConsumer>
              {({formData}) => {
                if (!this.state.product || !formData.inspections) return null
                return <NumberInput label={translate('resources.stocks.fields.price')}
                                    source="price" validate={required}/>
              }}
            </FormDataConsumer>
            <TextInput multiline source="overall"/>
          </FormTab>
        </TabbedForm>
      </Create>
    )
  }
}

export default translate(withProductRelations(StockCreate))
