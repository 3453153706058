import BookIcon from '@material-ui/icons/Book';
import BoardList from  './BoardList';
import BoardCreate from './BoardCreate';
import BoardEdit from './BoardEdit';

export default {
    list : BoardList,
    create : BoardCreate,
    edit : BoardEdit,
    icon : BookIcon,
}
