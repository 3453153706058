import {withStyles} from '@material-ui/core/styles'
import React, {useEffect} from 'react'

import {
    TopToolbar,
    Datagrid,
    DateField,
    Filter,
    List,
    NumberField,
    TextField,
    translate,
    SelectInput,
    DateInput,
} from 'react-admin'

const styles = {
    root: {
        '& > div': {
            overflow: 'visible'
        },
    },
    select: {
        height: '3rem',
        padding: '0 0',
        margin: 0
    }
}

const ListActions = ({basePath,}) => {
    return (
        <TopToolbar>
        </TopToolbar>
    )
}

const ListFilter = props => (
    <Filter {...props}>
        <SelectInput source="term" label="기간 단위" className={props.classes.select} choices={[
            {id: 'year', name: '년'},
            {id: 'month', name: '월'},
            {id: 'week', name: '주'}
        ]} initialvalue='week' alwaysOn/>
    </Filter>
)

export default withStyles(styles)(translate(({classes, translate, ...props}) => {

    return (
        <List {...props}
              classes={classes}
              actions={<ListActions/>}
              filters={<ListFilter classes={classes}/>}
              sort={{field:'createdAt', order: 'DESC'}}
              perPage={25}
              bulkActionButtons={false}>
            <Datagrid rowClick="show">
                <TextField source="week" label="날짜 기간" locales='ko' sortable={false}/>
                <NumberField source="매입 건수" label="매입 건수" sortable={false}/>
                <NumberField source="매입 금액" label="매입 금액" sortable={false}/>
                <NumberField source="판매 건수" label="판매 건수" sortable={false}/>
                <NumberField source="판매 금액" label="판매 금액" sortable={false}/>
                <NumberField source="total" label="합계" sortable={false}/>
                <NumberField source="diffFromLastTerm" label="이전 대비" sortable={false}/>
            </Datagrid>
        </List>
    )
}))