import React from 'react'
import PropTypes from 'prop-types'
import {BooleanInput, SelectInput, TextInput, translate,} from 'react-admin'
import get from 'lodash/get'
import {required} from '../../validators'

class OrderedSelectInput extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e, val) {
    const {formData, nameSource, orderSource, id} = this.props
    const items = formData[nameSource]

    const selectedItem = get(formData, id)

    const beforeSelected = items.find(item => item[orderSource] === val)
    if (beforeSelected) {
      beforeSelected[orderSource] = selectedItem[orderSource]
    }
  }

  render() {
    const {
      id,
      translateChoice = false,
      optionText = 'id',
      onChange = this.handleChange,
      names,
      nameSource,
      orderSource,
      formData,
      translate,
      isValueChoice
    } = this.props
    const currentItem = {id: -1}

    const nameChoices = names.reduce((pre, cur) => {
      pre.push(cur)
      return pre
    }, [])
    const currentName = names.find(name => name.id === currentItem.id)
    const orderChoices = Array.from({length: formData[nameSource].length})
      .map((v, i) => ({id: i + 1}))

    return (<React.Fragment>
      <SelectInput label={translate(`resources.${nameSource}.fields.name`)}
                   validate={required}
                   source={id + '.id'} optionText="name" choices={nameChoices}/>
      {isValueChoice && currentName ?
        currentName.type === 'string'
          ? <TextInput source={id + '.value'}
                       label={translate(`resources.${nameSource}.fields.value`)}
                       style={{marginLeft: 20}} validate={required}/>
          : <SelectInput source={id + '.optionId'} style={{marginLeft: 20}}
                         label={translate(`resources.${nameSource}.fields.optionId`)}
                         validate={required} choices={currentName.options}/>
        : ''}
      <SelectInput label={translate(`resources.${nameSource}.fields.${orderSource}`)}
                   validate={required}
                   translateChoice={translateChoice}
                   source={id + '.' + orderSource}
                   optionText={optionText}
                   onChange={onChange}
                   style={{marginLeft: 20}}
                   choices={orderChoices}/>
      {nameSource === 'inspections' &&
      <BooleanInput label={translate(`resources.${nameSource}.fields.onMain`)}
        source={id + '.onMain'} style={{marginLeft: 20, display: 'inline-flex'}}/>
      }
    </React.Fragment>)
  }
}

OrderedSelectInput.propTypes = {
  names: PropTypes.arrayOf(PropTypes.object).isRequired,
  nameSource: PropTypes.string.isRequired,
  orderSource: PropTypes.string.isRequired,
}

export default translate(OrderedSelectInput)
