import {CREATE, DELETE, GET_LIST, GET_ONE, UPDATE} from 'react-admin'

export function request(apiUrl, type, params) {
  let url = apiUrl + `/admin/posts`
  const options = {}
  switch (type) {
    case CREATE:
      options.method = 'POST'
      options.data = params.data
      break
    case GET_ONE:
      url += `/${params.id}`
      break
    case GET_LIST:
      const {page, perPage} = params.pagination
      options.params = {
        ...params.filter,
        type: params.filter.type ? params.filter.type : 'dev',
        start: (page - 1) * perPage,
        perPage,
      }
      break
    case UPDATE:
      url += `/${params.id}`
      options.method = 'PUT'
      options.data = params.data
      break
    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break
    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res
  switch (type) {
    case GET_LIST:
      if (data) ret = data
      break
    case GET_ONE:
      if (params) data.id = params.id
      ret = {data}
      break
    default:
      ret = {data}
      break
  }
  return ret
}
