import React from 'react'

import {
  ArrayField,
  Datagrid,
  DateField,
  NumberField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  translate,
} from 'react-admin'
import ShowActions from '../../custom/common/ShowActions'
import ImageClickField from '../../custom/common/ImageClickField'

class PriceShow extends React.Component {
  render() {
    const {translate, ...props} = this.props
    return (
      <Show {...props} actions={<ShowActions/>}>
        <SimpleShowLayout>
          <ImageClickField label={translate('resources.products.fields.titleImage')} addLabel={true}
                           source="titleImage"/>
          <ReferenceField label={translate('resources.prices.fields.productId')}
                          source="id" reference="products" link="show">
            <TextField source="name"/>
          </ReferenceField>

          <ArrayField source="history">
            <Datagrid>
              <TextField source="id" sortable={false}/>
              <NumberField source="price" sortable={false}/>
              <DateField source="createdAt" locales='ko' showTime sortable={false}/>
              <TextField source="memo" sortable={false}/>
            </Datagrid>
          </ArrayField>
        </SimpleShowLayout>
      </Show>
    )
  }
}

export default translate(PriceShow)
