import React from 'react'
import compose from 'recompose/compose'
import {withStyles} from '@material-ui/core/styles'
import {crudDelete, crudGetOne, translate} from 'ra-core'
import {Button as AdminButton, UPDATE} from 'react-admin'
import Button from '@material-ui/core/Button';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import {dataProvider} from '../../App'
import {connect} from 'react-redux'

const styles = theme => ({
  dialog: {
    width: '90%',
  },
  button: {}
});

class StockEditButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {isOpen: false, reply: ''}
    this.handleClick = this.handleClick.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleDone = this.handleDone.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.updateReview = this.updateReview.bind(this)
  }

  async updateReview() {
    const {dispatchCrudGetOne, basePath, record} = this.props
    const {reply} = this.state
    if (reply && reply.length) {
      await dataProvider(UPDATE, 'reviews', {
        id: record.id,
        data: {reply}
      })
      dispatchCrudGetOne('reviews', record.id, basePath)
    }
  }

  handleClick() {
    const {reply} = this.props.record
    this.setState( {isOpen: true, reply: reply || ''})
  }

  handleCancel() {
    this.setState({isOpen: false})
  }

  async handleDone(e) {
    e.stopPropagation()
    await this.updateReview()
    this.setState({isOpen: false})
  }

  handleChange(e) {
    const {name, value} = e.target
    this.setState({[name]: value})
  }

  render() {
    const {isOpen, reply} = this.state
    const {classes, translate,} = this.props

    return (
      <div>
        <AdminButton label="답변" onClick={this.handleClick}>
          <QuestionAnswerIcon/>
        </AdminButton>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="md"
          onEntering={this.handleEntering}
          aria-labelledby="confirmation-dialog-title"
          open={isOpen}
          classes={{paper: classes.dialog}}
        >
          <DialogTitle id="confirmation-dialog-title">답변 입력</DialogTitle>
          <DialogContent>
            <TextField label={translate('resources.reviews.fields.reply')}
                       style={{width: '100%'}}
                       margin="normal"
                       multiline
                       name='reply'
                       onChange={this.handleChange}
                       value={reply}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} color="primary">
              취소
            </Button>
            <Button onClick={this.handleDone} color="primary">
              확인
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default compose(
  connect(
    null,
    {
      dispatchCrudGetOne: crudGetOne,
      dispatchCrudDelete: crudDelete
    }
  ),
  translate,
  withStyles(styles)
)(StockEditButton)
