import React from 'react'
import {Create, ImageField, ImageInput, SelectInput,
  SimpleForm, NumberInput, DateInput, BooleanInput} from 'react-admin'
import {required} from '../../validators'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'

export default (props) => (
  <Create {...props} actions={<CreateActions/>}>
    <SimpleForm
      toolbar={<CreateToolbar/>}>
      <SelectInput source="site"
                   choices={[
                     {id: 'mybaro', name: 'mybaro'},
                     {id: 'sell.mybaro', name: 'sell.mybaro'}
                   ]}
                   validate={required}/>
      <SelectInput source="type"
                   choices={[
                     {id: 'main', name: '메인'}
                   ]}
                   validate={required}/>
      <ImageInput source="web" accept="image/*" validate={required}>
        <ImageField source="src" title="title"/>
      </ImageInput>
      <ImageInput source="mobile" accept="image/*">
        <ImageField source="src" title="title"/>
      </ImageInput>

      <DateInput source="startDate" defaultValue={null} />
      <DateInput source="endDate" defaultValue={null} />

      <BooleanInput source="alwaysOn" defaultValue={false} validate={required} />
      <NumberInput source="order" defaultValue={1} validate={required}/>
    </SimpleForm>
  </Create>
)
