import React, {Fragment} from 'react'

import {
  BooleanField,
  ChipField,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  SelectField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  translate, ArrayField, Datagrid, DateField, Tab
} from 'react-admin'
import ShowActions from '../../custom/common/ShowActions'
import ImageClickField from '../../custom/common/ImageClickField'
import PropertyField from './PropertyField'
import ProductPreviewButton from '../products/ProductPreviewButton'
import withStyles from '@material-ui/core/styles/withStyles'

const inlineDateFieldStyles = {
}

const InlineDateField = withStyles(inlineDateFieldStyles)(DateField)
const InlineNumberField = withStyles(inlineDateFieldStyles)(NumberField)

const ProductField = (props) => {
  return (
    <Fragment>
      <ReferenceField {...props} style={{display: 'inline'}}>
        <TextField source="name"/>
      </ReferenceField>
      <ProductPreviewButton id={props.record.productId}/>
    </Fragment>
  )
}

const OptionField = (props) => {
  const {record, translate} = props
  if (record.optionType === 'none') return ''
  return (
    <ArrayField {...props} source="options">
      <Datagrid>
        <NumberField source="id" sortable={false}/>
        <TextField label={translate('resources.goods.fields.optionName')} source="name" sortable={false}/>
        <NumberField label="resources.goods.fields.optionPrice" source="price" sortable={false}/>
        <NumberField source="quantity" sortable={false}/>
      </Datagrid>
    </ArrayField>
  )
}

class GoodsShow extends React.Component {
  render() {
    const {translate, ...props} = this.props
    return (

      <Show {...props} actions={<ShowActions/>}>
        <SimpleShowLayout>

          <TextField source="id"/>

          <ProductField source="productId" reference="products" linkType={'show'}/>

          <ReferenceField source="stockId" reference="stocks" allowEmpty linkType={'show'}>
            <TextField source="id"/>
          </ReferenceField>

          <ReferenceField source="wishListId" reference="wishList" allowEmpty linkType={'show'}>
            <TextField source="userName"/>
          </ReferenceField>

          <TextField source='stockNumber'/>
          <TextField source='name'/>
          <TextField source='serial'/>
          <TextField source="naverCode"/>
          <NumberField source="originalPrice"/>
          <NumberField source="discount"/>
          <TextField source="seller" label="판매자" />
          <DateField source="buyDate" label="매입 날짜" locales='ko'/>
          <NumberField source="buyPrice"/>
          <NumberField source="price"/>
          <BooleanField source="onSale"/>
          <NumberField source="quantity"/>

          <SelectField source="optionType" choices={[
            {id: 'none', name: '설정안함'},
            {id: 'single', name: '설정함'}
          ]}/>
          <OptionField addLabel={true} source="options" translate={translate}/>

          <PropertyField/>

          <BooleanField source="isTimeSale"/>

          <InlineDateField className={'date_input'} label="from" source="startDate"/>
          <InlineDateField className={'date_input'}  label="to" source="endDate"/>
          <InlineNumberField className={'date_input'}  source="timeSalePrice"/>

          <ReferenceArrayField reference="events" source="eventIds">
            <SingleFieldList>
              <ChipField source="name"/>
            </SingleFieldList>
          </ReferenceArrayField>

          <ImageClickField label={translate('resources.goods.fields.titleImage')} addLabel={true}
                           source="titleImage.src" src="src"/>

          <ImageClickField label={translate('resources.goods.fields.mainImages')} addLabel={true}
                           source="mainImages" src="src"/>

          <ImageClickField label={translate('resources.goods.fields.detailImages')} addLabel={true}
                           source="detailImages" src="src"/>

          <BooleanField source='isHtmlOverall'/>
          <TextField style={{}} source='overall'/>
          <TextField source='memo' />
        </SimpleShowLayout>
      </Show>
    )
  }
}

export default translate(GoodsShow)
