import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  TopToolbar,
  CreateButton,
  Datagrid,
  DateField,
  NumberField,
  List,
  TextField,
  SelectField,
  ImageField,
  Filter,
  SearchInput,
  SelectInput
} from 'react-admin'
import * as choices from './choices'

const styles = theme => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
})
const Actions = ({basePath}) => (
  <TopToolbar>
  </TopToolbar>
)

const ListFilter = props => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn/>
    <SelectInput source="status" choices={choices.status} label="상태" alwaysOn/>
  </Filter>
)


export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props}
          actions={<Actions/>}
          filters={<ListFilter />}
          perPage={25}
          sort={{field: 'createdAt', order: 'DESC'}}
          bulkActionButtons={false}>
      <Datagrid rowClick={'show'}>
        <TextField source="id" sortable={false}/>
        <SelectField source="status" choices={choices.status} sortable={false}/>
        <ImageField source="userSell.mainImage" sortable={false}/>
        <TextField source="userSell.title" sortable={false}/>
        <NumberField source="amount" sortable={false}/>
        <DateField source="createdAt" locales="ko" showTime/>
      </Datagrid>
    </List>
  )
})

