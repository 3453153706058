import React, {useState, useEffect} from "react";
import {dataProvider, history} from "../../App";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";

import moment from 'moment'
import numeral from 'numeral'

const PurchaseList = (props) => {

    const columns = [
        { id: 'stockId', label: '매입 번호', minWidth: 170 },
        { id: 'productName', label: '상품 이름', minWidth: 200 },
        { id: 'seller', label: '판매자', minWidth: 100},
        { id: 'price', label: '매입 가격', minWidth: 100},
        { id: 'createdAt', label: '매입 날짜', minWidth: 100, format: (value) => value.date()},
    ];

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [purchaseList, setPurchaseList] = useState([]);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        async function fetchData() {
            const id = decodeURI((window.location.pathname).split('/')[2]);

            let options = {
                sort: 'purchase',
                id: id,
                page: page,
                perPage: rowsPerPage
            }

            const {data, total} = await dataProvider('GET_DEALS_PURCHASE', 'sales', options)
            setTotal(total);
            setPurchaseList(data)
        }
        fetchData();

    }, [page, rowsPerPage]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleCellClick = (row, e) => {
        e.preventDefault();

        history.push(`/stocks/${row.stockId}/show`)
    }

    return (
        <Paper>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {purchaseList.slice(0, rowsPerPage).map((row) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code} onClick={(e) => handleCellClick(row, e)}>
                                    {columns.map((column) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell key={column.id}>
                                                {column.id === 'createdAt' ? moment(value).format('YYYY-MM-DD') :
                                                    column.id === 'price' ? numeral(value).format('0,0') :
                                                    value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    );
}

export default PurchaseList;