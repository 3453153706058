import React from 'react'
import {Create, SelectInput, SimpleForm, TextInput} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'
import {toolbar} from '../../utils/editorConfig'
import choices from './choices'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from "../../custom/common/CreateToolbar";

const CreatePosts = (props) => {
  return (
    <Create {...props} actions={<CreateActions />}>
      <SimpleForm toolbar={<CreateToolbar/>}>
        <TextInput source="title" />
        <SelectInput source="type" choices={choices.type} />
        <RichTextInput source="content" toolbar={toolbar} />
      </SimpleForm>
    </Create>
  )
}

export default CreatePosts
