import BookIcon from '@material-ui/icons/Book'
import AffiliationCreate from './AffiliationCreate'
import AffiliationEdit from './AffiliationEdit'
import AffiliationList from './AffiliationList'

export default {
  list: AffiliationList,
  create: AffiliationCreate,
  edit: AffiliationEdit,
  icon: BookIcon,
}
