import React from 'react'
import {Edit, SimpleForm, TextInput} from 'react-admin'
import EditActions from '../../custom/common/EditActions'
import EditToolbar from '../../custom/common/EditToolbar'
import {required} from '../../validators'

export default (props) => {
  return (
    <Edit {...props} actions={<EditActions/>}>
      <SimpleForm toolbar={<EditToolbar/>}>
        <TextInput disabled source='id'/>
        <TextInput source='name' validate={required}/>
      </SimpleForm>
    </Edit>
  )
}
