import React from 'react'
import {
  TopToolbar,
  Datagrid,
  DateField,
  Filter,
  List,
  SearchInput,
  TextField,
  SelectField,
  NumberField
} from 'react-admin'
import {status} from './choices'

const Actions = ({basePath,}) => (
  <TopToolbar>
  </TopToolbar>
)

const ListFilter = props => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn/>
  </Filter>
)

export default ({classes, ...props}) => {
  return (
    <List {...props}
          filters={<ListFilter classes={classes}/>}
          perPage={25}
          sort={{field: 'id', order: 'DESC'}}
          actions={<Actions/>}
          bulkActionButtons={false}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <SelectField source="status" choices={status} sortable={false} />
        <TextField source="business.name" sortable={false} />
        <TextField source="business.phone" sortable={false} />
        <TextField source="business.email" sortable={false} />
        <NumberField source="price" sortable={false} />
        <DateField source="createdAt" locales='ko' showTime sortable={false} />
      </Datagrid>
    </List>
  )
}

