import React, {useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Button as AdminButton, crudGetOne} from 'react-admin'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import {dataProvider} from '../../App'
import {useDispatch} from 'react-redux'
import TextField from '@material-ui/core/TextField'
import AdminIcon from '@material-ui/icons/Contacts'

const useStyles = makeStyles({
  dialog: {
    width: '80%'
  },
  textField: {
    width: '50%'
  },
  spanMargin: {
    margin: '5px'
  },
  statusField: {
    width: '70px'
  },
  descriptionField: {
    width: '100%'
  }
})

export default (props) => {
  const [isOpen, setOpen] = useState(false)
  const [address, setAddress] = useState({name: '', phone: '', address: '', zipCode: '', memo: ''})
  const dispatch = useDispatch()
  const classes = useStyles()

  function handleShowModal() {
    const {receiverName, receiverPhone, address, zipCode, memo} = props
    setAddress({
      name: receiverName,
      phone: receiverPhone,
      address,
      zipCode,
      memo
    })
    setOpen(true)
  }

  function handleCancel() {
    setOpen(false)
  }

  function handleChange(e) {
    const {value, name} = e.target
    const temp = {...address}
    temp[name] = value
    setAddress(temp)
  }

  function validate() {
    try {
      // if (memo && memo.length > 10000) throw new Error(`최대 입력 가능 글자 수는 10000자 입니다. 현재 ${memo.length}자`)
    } catch (e) {
      e.status = 100
      throw e
    }
  }

  async function handleDone(e) {
    e.stopPropagation()

    try {
      validate()
      const {id, basePath, resource} = props
      const {data} = await dataProvider('UPDATE_ADDRESS', resource, {id, data: address})

      if (data) {
        dispatch(crudGetOne(resource, id, basePath, true))
      } else {
        alert('저장을 실패 하였습니다.')
        return
      }

      setOpen(false)
    } catch (e) {
      if (e.status === 100) alert(e.message)
      else {
        alert('저장을 실패 하였습니다.')
      }
    }
  }

  const DialogButton = () => {
    return (
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          취소
        </Button>
        <Button onClick={handleDone} color="primary">
          확인
        </Button>
      </DialogActions>
    )
  }

  return (
    <section>
      <AdminButton label={'구매자 배송지 변경'} onClick={handleShowModal} disabled={props.disabled}>
        <AdminIcon />
      </AdminButton>
      <div>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          aria-labelledby="confirmation-dialog-title"
          open={isOpen}
          classes={{paper: classes.dialog}}
        >
          <DialogContent>
            <TextField
              className={classes.input}
              value={address.name}
              name="name"
              onChange={handleChange}
              fullWidth
              label="배송지명 또는 배송자명"
            />
            <TextField
              className={classes.input}
              value={address.phone}
              name="phone"
              onChange={handleChange}
              fullWidth
              label="배송자 연락처"
            />
            <TextField
              className={classes.input}
              value={address.address}
              name="address"
              onChange={handleChange}
              fullWidth
              label="배송지 주소"
            />
            <TextField
              className={classes.input}
              value={address.zipCode}
              name="zipCode"
              onChange={handleChange}
              fullWidth
              label="배송지 우편번호"
            />
            <TextField
              className={classes.input}
              value={address.memo}
              name="memo"
              onChange={handleChange}
              fullWidth
              label="메모"
            />
          </DialogContent>
          <DialogButton />
        </Dialog>
      </div>
    </section>
  )
}
