import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  BooleanField,
  TopToolbar,
  CreateButton,
  Datagrid,
  DateField,
  EditButton,
  ImageField,
  List,
  TextField,
  translate,
} from 'react-admin'

const styles = theme => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }
})
const Actions = ({basePath,}) => (
  <TopToolbar>
    <CreateButton basePath={basePath}/>
  </TopToolbar>
)

const ThumbnailImageField = withStyles({image: {height: 60}})(ImageField)

export default withStyles(styles)(translate(({classes, translate, ...props}) => {
  return (
    <List {...props}
          pagination={null}
          actions={<Actions/>}
          bulkActionButtons={false}>
      <Datagrid>
        <TextField source="id" sortable={false}/>
        <ThumbnailImageField source="image" sortable={false}/>
        <TextField source="name" sortable={false}/>
        <BooleanField source="onSale"/>
        <DateField source="createdAt" locales='ko' showTime sortable={false}/>
        <EditButton label={translate('ra.action.edit')}/>
      </Datagrid>
    </List>
  )
}))

