import React from 'react'
import {Link} from 'react-router-dom'
import {
  ArrayField,
  BooleanField,
  Button,
  TopToolbar,
  ChipField,
  Datagrid,
  EditButton,
  ListButton,
  NumberField,
  Show,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  translate, DateField,
  SelectField
} from 'react-admin'
import ImageClickField from '../../custom/common/ImageClickField'
import History from '@material-ui/icons/History'
import LinkField from '../../custom/common/LinkField'
import {withStyles} from "@material-ui/core/styles";
import {ImageField} from "react-admin";
import {history} from "../../App";
import {bannerTypes} from "./choices";

const ThumbnailImageField = withStyles({image: {height: 60}})(ImageField)

const ShowActions = ({basePath, data = {}, redirectUrl}) => {

  return (
    <TopToolbar>
      {data.id && <Button
        component={Link}
        to={`/prices/${data.id}/show`}
        label="가격 관리"><History/>
      </Button>}
      <ListButton basePath={redirectUrl ? redirectUrl : basePath}/>
      <EditButton basePath={basePath} record={data}/>
    </TopToolbar>
  )
}

const handleRowClick = (props, basePath, record) => {
  history.push(`/goods/${record.id}/show`)
}

class ProductShow extends React.Component {
  constructor(props) {
    super(props)
    const query = new URLSearchParams(props.location.search)
    const redirectUrl = query.get('redirectUrl')

    this.state = {
      redirectUrl: redirectUrl ? redirectUrl : null
    }
  }

  render() {
    const {translate, ...props} = this.props
    const {redirectUrl} = this.state
    return (
      <Show {...props} actions={<ShowActions redirectUrl={redirectUrl}/>}>
        <TabbedShowLayout>
          <Tab label={translate('resources.products.summary')}>
            <TextField source="id"/>
            <TextField source="shopGoodsId"/>
            <LinkField {...props} />
            <ArrayField source="categories">
              <SingleFieldList linkType={false}>
                <ChipField source="name"/>
              </SingleFieldList>
            </ArrayField>
            <TextField source="brandName"/>
            <TextField source='title'/>
            <TextField source="name"/>
            <TextField source="prefix"/>
            <NumberField source="price"/>
            <NumberField source="buyPrice"/>
            <TextField source="code"/>
            <TextField source='size'/>
            <TextField source="year"/>
            <BooleanField source="onDeal"/>

            <ImageClickField label={translate('resources.products.fields.titleImage')} addLabel={true}
                             source="titleImage.src"/>

            <ImageClickField label={translate('resources.products.fields.images')} addLabel={true}
                             source="images" src="src"/>
          </Tab>
          <Tab label={translate('resources.products.extras')}>
            <ArrayField source="extras" addLabel={false}>
              <Datagrid>
                <TextField source="name" label={translate('common.name')} sortable={false}/>
                <TextField source="value" label={translate('common.value')} sortable={false}/>
              </Datagrid>
            </ArrayField>
          </Tab>
          <Tab label={translate('resources.inspections.name')}>
            <ArrayField source="inspections" addLabel={false}>
              <Datagrid>
                <TextField source="id" sortable={false}/>
                <TextField source="name" label={translate('common.name')} sortable={false}/>
                <BooleanField source="onMain" label={translate('resources.inspections.fields.onMain')}
                              sortable={false}/>
              </Datagrid>
            </ArrayField>
          </Tab>
          <Tab label="상품 내역">
            <ArrayField source="goods" addLabel={false}>
              <Datagrid rowClick={handleRowClick}>
                <TextField source="id" label="상품 번호" sortable={false}/>
                <ThumbnailImageField source="titleImage" sortable={false}/>
                <TextField source="name"/>
                <NumberField source="price"/>
                <BooleanField source="onSale" label="노출여부" sortable={false}/>
                <BooleanField source="reserved" label="예약" sortable={false}/>
                <NumberField source="quantity" label="재고수량" sortable={false}/>
                <TextField source="status" label="상태"/>
                <DateField source="createdAt" locales='ko'/>
              </Datagrid>
            </ArrayField>
          </Tab>
          <Tab label="resources.products.fields.banners">
            <ArrayField source="banners" addLabel={false}>
              <Datagrid>
                <ImageField label="resources.products.fields.image" source="image.src" />
                <SelectField source="type" choices={bannerTypes}/>
                <TextField label="resources.products.fields.path" source="pathName" />
                <NumberField order="order" />
              </Datagrid>
            </ArrayField>
          </Tab>
          <Tab label="resources.products.fields.blogs">
            <ArrayField source="blogs" addLabel={false}>
              <Datagrid>
                <NumberField label="resources.blogs.fields.id" source="id" sortable={false} />
                <TextField label="resources.blogs.fields.title" source="title" sortable={false} />
              </Datagrid>
            </ArrayField>
          </Tab>
        </TabbedShowLayout>
      </Show>
    )
  }
}

export default translate(ProductShow)
