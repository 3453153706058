import BookIcon from '@material-ui/icons/Book'
import ExtraCreate from './InspectionCreate'
import InspectionEdit from './InspectionEdit'
import ExtraList from './InspectionList'

export default {
  list: ExtraList,
  create: ExtraCreate,
  edit: InspectionEdit,
  icon: BookIcon,
}
