import React, {useMemo} from 'react';
import compose from 'recompose/compose';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {withStyles} from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import {translate} from 'react-admin';

const styles={
    order:{
        display:'flex',
        justifyContent:'space-between',
        paddingLeft : 8,
        paddingRight : 8,
        minWidth : 390,
    },
    orderInfo:{
        minWidth : 350,
    },
    orderGood:{
        fontSize: '0.8rem',
        fontWeight: 400,
        lineHeight: 1.43,
        letterSpacing: '0.01071em',
        color: 'rgba(0, 0, 0, 0.54)',
        
    },
    orderPrice:{
        display:'flex',
        justifyContent : 'end',
        minWidth : 90,
    },
}

function OrderList(props){
    return(
        <List>
            {props.curPageOrderList.map(order =>{
                let goodsName =[];
                goodsName = order.goods.split(',');
                return(
                    <ListItem
                        key = {order.id} 
                        button
                        component={Link}
                        to={`/orders/${order.id}/show`}
                        className={props.classes.order}>
                        <div>
                            <ListItemText 
                                primary={`${(order.createdAt).toLocaleString()} `}
                                secondary={`by ${order.buyerName}, ${order.platform === 'naver' ? 'NAVER' : 'BARO'} `}
                                className={props.classes.orderInfo}
                            />
                            { goodsName.map((name, index) => <div key={index} className={props.classes.orderGood}>{`${ index === 0 ? '': index+1+'.' } ${name}`}</div> ) }
                        </div>
                        <ListItemText 
                            primary={ `${order.amount}원`}
                            className={props.classes.orderPrice}
                        />
                    </ListItem>
                )
            })}
        </List>
    )
}

const enhance=compose(
    withStyles(styles),
    translate,
);

export default enhance(OrderList);