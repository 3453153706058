import React, { Fragment, useState, useEffect } from 'react'
import { TopToolbar, CreateButton, List, EditButton, GET_LIST} from 'react-admin'
import {
  List as MuiList,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Collapse,
  Card,
  makeStyles,
} from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import {dataProvider} from '../../App';




const useStyles = makeStyles({
  card: {
    maxWidth: '100%',
    marginTop: '1em',
  },
  title :{
    marginTop: '10px',
    marginLeft: '10px',
    fontWeight : 'bold',
    fontSize : '30px'
  },
  categories : {
    width : '100%',
    display :'flex',
  },
  category : {
    flex : '1',
    margin :'15px'
  }
})
const SmallCard = ({ className, ...props }) => {
  const classes = useStyles()
  return <Card {...props} className={`${className} ${classes.card}`} />
}


const NotUpdatedSubTree = ({ root, basePath}) => {

  return (
    <Fragment>
      <ListItem>
        <ListItemText primary={root.name} />
        <ListItemSecondaryAction>
          <EditButton record={root} basePath={basePath} />
        </ListItemSecondaryAction>
      </ListItem>
    </Fragment>
  )
}

const NotUpdatedTree = ({ ids, data, basePath }) => {
  const classes = useStyles()
  const nodes = ids.map(id => data[id])
  const roots = nodes.filter(node => !node.parentId && (!node.brandCategoryId || !node.brandId))
  return (
    <MuiList>
      <div className={`${classes.title}`}>브랜드 또는 브랜드 카테고리가 지정되지 않은 카태고리</div>
      {roots.map(root => (
        <NotUpdatedSubTree
          basePath={basePath}
          key={root.id}
          root={root}
        />
      ))}
    </MuiList>
  )
}


const SubTree = (props) =>{
  const [childOpen, setChildOpen] = useState(false);
  const {level, root, basePath, nodes} = props;
  const childNodes = nodes.filter(node => node.parentId === root.id)
  const hasChildren = childNodes.length > 0

  return(
    <Fragment>
      <ListItem
        button={hasChildren}
        onClick={()=> setChildOpen(!childOpen)}
        style={{ paddingLeft: level * 16 }}>

        {hasChildren && childOpen && <ExpandLess />}
        {hasChildren && !childOpen && <ExpandMore />}
        {!hasChildren && <div style={{ width: 24 }}>&nbsp;</div>}
        <ListItemText primary={root.name}/>
        
        <ListItemSecondaryAction>
          <EditButton record={root} basePath={basePath} />
        </ListItemSecondaryAction>
      
      </ListItem>
      <Collapse in={childOpen} timeout="auto" unmountOnExit>
        <MuiList component="div" disablePadding>
          {childNodes.map(node => (
            <SubTree
              basePath={basePath}
              key={node.id}
              nodes={nodes}
              root={node}
              level={level + 1}
            />
          ))}
        </MuiList>
      </Collapse>
    </Fragment>
  )
}

const BrandSubTree = (props) => {
  const {brand, roots, ids, nodes, basePath, level}=props;
  const [brandOpen, setBrandOpen] = useState(false)
  const hasRoots = roots.length > 0

  return(
    <Fragment>
      <ListItem
        button={hasRoots}
        onClick={()=>setBrandOpen(!brandOpen)}
        style={{paddingLeft: (level+1) * 16}}>
        <ListItemText primary={brand.name}/>
        {hasRoots && brandOpen && <ExpandLess />}
        {hasRoots && !brandOpen && <ExpandMore />}
        {!hasRoots && <div style={{ width: 24 }}>&nbsp;</div>}
      </ListItem>
      <Collapse in={brandOpen} timeout="auto" unmountOnExit>
        {roots.map((root, index) =>{
            return <SubTree
              key={index}
              ids={ids}
              nodes={nodes}
              basePath={basePath}
              level={level+2}
              root={root}/>
          })}
      </Collapse>
    </Fragment>
  )
}


const BrandCatSubTree = (props)=>{
  const [brandCatOpen, setBrandCatOpen]= useState(false);
  const {brandCategory, brands, level, ids, data, basePath} =props;
  const hasBrands = brands.length > 0;
  const nodes = ids.map(id => data[id])

  return(
    <Fragment>
      <ListItem 
        button={hasBrands}
        onClick={() => setBrandCatOpen(!brandCatOpen)}
        style={{ paddingLeft: level * 16 }}
      >
        <ListItemText primary={brandCategory.name} />
        {hasBrands && brandCatOpen && <ExpandLess/>}
        {hasBrands && !brandCatOpen && <ExpandMore/>}
        {!hasBrands && <div style={{ width: 24 }}>&nbsp;</div>}
      </ListItem>
      
      <Collapse in={brandCatOpen} timeout="auto" unmountOnExit>
        <MuiList component="div" disablePadding>
          {brands.map((brand, index) => {
              const roots = nodes.filter(node =>{
                return node.brandCategoryId === brandCategory.id && node.brandId === brand.id &&!node.parentId;
              })
              return <BrandSubTree key={index} brand={brand} roots={roots} 
                                   ids={ids} nodes={nodes} basePath={basePath} level={level+1}/>
          })}
        </MuiList>
      </Collapse>
    </Fragment>
  )
}

const Tree = ({ids, data, basePath}) =>{
  const [brandCategories, setbrandCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const classes = useStyles()

  useEffect(()=>{
    let isMounted = true;
    dataProvider(GET_LIST, 
      'brandCategories', 
      {
        filter :{}, 
        pagination:{page:1, perPage:1000}, 
        sort:{field:'id', order : 'ASC'}
      })
    .then(result => {
      if(isMounted) setbrandCategories(result.data);
    })
    dataProvider(GET_LIST, 
      'brands',
      {
        filter :{},
        pagination : {page : 1, perPage: 1000},
        sort:{field : "id", order : "ASC"},
      })
    .then(result =>{
       if(isMounted) setBrands(result.data);
    })

    return () =>{isMounted=false;}
  }, [])

  return(
    <MuiList>
      <div className={`${classes.title}`}>카테고리</div>
      {brandCategories.map((brandCategory, index) => {
          const filteredBrands = brands.filter(brand => {
            let hasCategory = false;
            for(let i=0; i<brand.brandCategories.length; i++){
              if(brand.brandCategories[i].id === brandCategory.id){hasCategory = true;}
            }
            return hasCategory
          })
          return(<BrandCatSubTree
            key={index}
            ids={ids}
            data={data}
            basePath={basePath}
            brandCategory={brandCategory}
            brands={filteredBrands}
            level={1}/>)
        })
      }
    </MuiList>
  )
}

const Actions = ({basePath,}) => (
  <TopToolbar>
    <CreateButton basePath={basePath}/>
  </TopToolbar>
)

const CategoryList = props => {
  const classes = useStyles();

  return (
    <div className={`${classes.categories}`}>
      <div className={`${classes.category}`}>
        <List
          {...props}
          perPage={1000}
          pagination={null}
          component={SmallCard}
          actions={<Actions/>}
        >
          <Tree {...props} />
        </List>
      </div>
      <div className={`${classes.category}`}>
        <List
          {...props}
          title="카테고리"
          perPage={1000}
          pagination={null}
          component={SmallCard}
          actions={null}
        >
          <NotUpdatedTree {...props} />
        </List>
      </div>
    </div>
)}

export default CategoryList

