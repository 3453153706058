const types = [
  {id: 'goods', name: '상품문의'},
  {id: 'product', name: '상품문의'},
  {id: 'payment', name: '구매/결제문의'},
  {id: 'exchange', name: '교환문의'},
  {id: 'delivery', name: '배송문의'},
  {id: 'refund', name: '취소/환불/반품문의'},
]

export {types}
