import React from 'react'
import {Create, ImageField, ImageInput, SelectInput, SimpleForm} from 'react-admin'
import {required} from '../../validators'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'

export default (props) => (
  <Create {...props} actions={<CreateActions/>}>
    <SimpleForm
      toolbar={<CreateToolbar/>}>
      <SelectInput source="type"
                   choices={[
                     {id: 'main', name: '메인'},
                     {id: 'goodsList', name: '상품리스트'},
                     {id: 'mainCenter', name: '메인 중앙'}
                   ]}
                   validate={required}/>
      <ImageInput source="imagePC" accept="image/*" validate={required}>
        <ImageField source="src" title="title"/>
      </ImageInput>
      <ImageInput source="imageMO" accept="image/*" validate={required}>
        <ImageField source="src" title="title"/>
      </ImageInput>
      <ImageInput source="imageAPP" accept="image/*">
        <ImageField source="src" title="title"/>
      </ImageInput>
    </SimpleForm>
  </Create>
)
