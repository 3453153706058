import React from 'react'
import {
  BooleanInput,
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin'
import {required} from '../../validators'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'

export default (props) => (
  <Create {...props} actions={<CreateActions/>}>
    <SimpleForm
      toolbar={<CreateToolbar/>}
      defaultValue={{emailNoti: false}}>
      <TextInput source="name" validate={required}/>
      <TextInput source="password" type='password' validate={required} />
      <TextInput source="adminName" validate={required}/>
      <SelectInput source="authority" choices={[
        {id: 'admin', name: '관리자'},
        {id: 'manager', name: '매니저'},
      ]}/>

      <ReferenceInput source="affiliationId" reference="affiliations" validate={required}>
        <SelectInput optionText="affiliation"/>
      </ReferenceInput>

      <TextInput source="email" type='email' validate={required}/>
      <BooleanInput source="emailNoti" validate={required}/>
      <TextInput source="phone" type='phone' validate={required}/>
    </SimpleForm>
  </Create>
)
