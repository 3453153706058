import {
  CREATE,
  DELETE,
  GET_LIST,
  GET_ONE,
  UPDATE
} from 'react-admin'
import {convertImagePath, convertSrc, uploadImage, uploadToS3} from './ImageHandler'

export async function request(apiUrl, type, params) {
  let url = apiUrl + '/admin/banners'
  let options = {}

  switch (type) {
    case CREATE:
    case UPDATE:
      if (type === UPDATE) {
        url += `/${params.id}`
        options.method = 'PUT'
      } else options.method = 'POST'

      const data = {...params.data}
      await uploadImage(data, 'imagePC', 'file')
      await uploadImage(data, 'imageMO', 'file')
      await uploadImage(data, 'imageAPP', 'file')

      options.data = data
      break
    case GET_ONE:
      url += `/${params.id}`
      break
    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break
    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res
  switch (type) {
    case GET_LIST:
      data.forEach(row => {
        row.imagePC = convertImagePath(row.imagePC, 'small')
        row.imageMO = convertImagePath(row.imageMO, 'small')
        row.imageAPP = convertImagePath(row.imageAPP, 'small')
      })
      ret = {data, total: data.length}
      break
    case GET_ONE:
      data.imagePC = convertSrc(data.imagePC, 'large')
      data.imageMO = convertSrc(data.imageMO, 'large')
      data.imageAPP = convertSrc(data.imageAPP, 'large')
      console.log(data)
      ret = {data}
      break
    default:
      if (data) ret = {data}
      break
  }
  return ret
}
