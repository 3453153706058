import React from 'react'
import {
  DateField, Edit, SimpleForm,
  TextInput, translate
} from 'react-admin'
import {required} from '../../validators'
import EditActions from '../../custom/common/EditActions'
import EditToolbar from '../../custom/common/EditToolbar'
import withProductRelations from '../../hocs/withProductRelations'

class ShopEdit extends React.Component {
  render() {
    const {shopCategories, translate, ...props} = this.props
    return (
      <Edit {...props} actions={<EditActions/>}>
        <SimpleForm toolbar={<EditToolbar/>}>
          <TextInput disabled source='code'/>
          <TextInput source="name" validate={required}/>
          <TextInput source="accountId"/>
          <TextInput source="password"/>
          <TextInput source="url" />
          <TextInput source="memo"/>
          <DateField source="createdAt" locales='ko' showTime/>
        </SimpleForm>
      </Edit>
    )
  }
}

export default translate(withProductRelations(ShopEdit))
