import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  TopToolbar, CreateButton, Datagrid, NumberField,
  List, TextField, SelectField, ImageField, DateField, BooleanField
} from 'react-admin'

const ThumbnailImageField = withStyles({image: {height: 60}})(ImageField)

const styles = theme => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }
})

const Actions = ({basePath,}) => (
  <TopToolbar>
    <CreateButton basePath={basePath}/>
  </TopToolbar>
)

export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props}
          actions={<Actions/>}
          bulkActionButtons={false}>
      <Datagrid rowClick={'edit'}>
        <TextField source="id" sortable={false}/>
        <TextField source="site" sortable={false}/>
        <ThumbnailImageField source="web" sortable={false}/>
        <ThumbnailImageField source="mobile" sortable={false}/>
        <SelectField source="type"
                     choices={[
                     {id: 'main', name: '메인'}
                     ]}
                     sortable={false}/>
        <NumberField source="order" sortable={false}/>
        <DateField source="startDate" sortable={false}/>
        <DateField source="endDate" sortable={false}/>
        <BooleanField source="alwaysOn" sortable={false}/>
        <DateField source="createdAt" sortable={false}/>
      </Datagrid>
    </List>
  )
})

