import axios from 'axios'
import {omitBy,  isNull} from 'lodash'

const models = {
  admins: require('./admins'),
  auth: require('./auth'),
  blogs: require('./blogs'),
  brands: require('./brands'),
  boards: require('./boards'),
  brandCategories : require('./brandCategories'),
  categories: require('./categories'),
  files: require('./files'),
  events: require('./events'),
  goods: require('./goods'),
  inspections: require('./inspections'),
  keywords: require('./keywords'),
  orders: require('./orders'),
  prices: require('./prices'),
  products: require('./products'),
  questions: require('./questions'),
  researches: require('./researches'),
  reviews: require('./reviews'),
  stocks: require('./stocks'),
  users: require('./users'),
  wishList: require('./wishList'),
  affiliations: require('./affiliations'),
  emailSettings: require('./emailSettings'),
  dashboard: require('./dashboard'),
  banners: require('./banners'),
  devices: require('./devices'),
  shops: require('./shops'),
  popups: require('./popups'),
  faqs: require('./faqs'),
  sites: require('./sites'),
  defaultImages: require('./defaultImages'),
  pointsEvents: require('./pointsEvents'),
  points: require('./points'),
  coupons: require('./coupons'),
  notices : require('./notices'),
  couponsBases: require('./couponsBases'),
  sales: require('./sales'),
  stockReviews: require('./stockReviews'),
  stocksByProducts: require('./stocksByProducts'),
  stocksByGoods: require('./stocksByGoods'),
  devPosts: require('./devPosts'),
  operatorPosts: require('./operatorPosts'),
  businesses: require('./businesses'),
  businessReceipts: require('./businessReceipts'),
  userSells: require('./userSells'),
  userSellOrders: require('./userSellOrders')
}

async function refreshToken(apiUrl) {
  try {
    const accessToken = localStorage.getItem('accessToken')
    const refreshToken = localStorage.getItem('refreshToken')
    const options = models.auth.request(apiUrl, 'REFRESH', {data: {accessToken, refreshToken}})
    const {data} = await axios(options)
    return data.accessToken
  } catch (e) {
    if (e.response.status === 401) {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('role')
      localStorage.removeItem('username')
      window.open('/login', '_self')
    }
    throw e
  }
}

export default function (apiUrl) {
  const httpClient = (options = {}) => {
    if (!options.headers) options.headers = {}
    const token = localStorage.getItem('accessToken')
    if (token) options.headers.authorization = `Bearer ${token}`
    return axios(options)
  }

  return async function (type, resource, params) {
    const resourceModel = models[resource]
    const options = await resourceModel.request(apiUrl, type, omitBy(params, isNull))
    try {
      const response = await httpClient(options)
      return resourceModel.response(response, type, params)
    } catch (e) {
      if (e && e.response && e.response.status === 401) {
        try {
          const accessToken = await refreshToken(apiUrl, e)
          if (accessToken) {
            localStorage.setItem('accessToken', accessToken)
            const response = await httpClient(options)
            return resourceModel.response(response, type, params)
          } else {
            throw new Error('RefreshTokenFailed')
          }
        } catch (e) {
          e.status = 401
          throw e
        }
      } else throw e.response ? e.response.data : e
    }
  }
}
