import React from 'react'
import {SelectInput, translate,} from 'react-admin'

class SelectCategories extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  async handleChange(index, value, e) {
    const {formData} = this.props
    if (index < formData.parentIds.length - 1 && formData.parentIds[index] !== value) {
      formData.parentIds.splice(index,)
    }
  }

  render() {
    const {translate, categories, formData,} = this.props
    if (!formData || !formData.parentIds || !categories) return ''
    return (
      <React.Fragment>
        <SelectInput source="parentIds[0]" optionText="name"
                     label={translate('resources.categories.fields.parentIds', {index: 1})}
                     style={{paddingRight: '1em'}}
                     onChange={async (e, value) => {
                       await this.handleChange(0, value, e)
                     }}
                     choices={categories.filter(item => item.parentId === null && item.id !== formData.id)}/>
        {formData.parentIds.reduce((ret, id, i) => {
          const children = categories.filter(item => item.parentId === id && item.id !== formData.id)
          if (children.length > 0) {
            ret.push(<SelectInput source={`parentIds[${i + 1}]`} optionText="name" key={id}
                                  label={translate('resources.categories.fields.parentIds', {index: i + 2})}
                                  style={{paddingRight: '1em'}}
                                  onChange={async (e, value) => {
                                    await this.handleChange(i + 1, value, e)
                                  }}
                                  choices={categories.filter(item => item.parentId === id)}/>)
          }
          return ret
        }, [])
        }
      </React.Fragment>
    )
  }

}

export default translate(SelectCategories)
