import React from 'react'

import {
  ArrayField,
  Button,
  TopToolbar, Datagrid,
  DateField,
  ListButton,
  NumberField,
  ReferenceField,
  SelectField,
  Show,
  SimpleShowLayout,
  TextField,
  translate,
} from 'react-admin'
import {Link} from 'react-router-dom'
import History from '@material-ui/icons/History'
import CustomDeleteButton from '../../custom/common/CustomDeleteButton'

const ShowActions = (props) => {
  const {basePath, data = {}} = props
  return (
    <TopToolbar>
      {data.productCode && <Button
        component={Link}
        to={`/researches?filter=${encodeURIComponent(JSON.stringify({search: data.productCode}))}`}
        label="제품코드 검색"><History/>
      </Button>}
      <ListButton basePath={basePath}/>
      {data.id && <CustomDeleteButton {...props}
                                      record={data}
                                      submitOnEnter={false}
                                      variant="dense"
      />}
    </TopToolbar>
  )
}

class PriceShow extends React.Component {
  render() {
    const {translate, ...props} = this.props
    return (
      <Show {...props} actions={<ShowActions/>}>
        <SimpleShowLayout>
          <ReferenceField label={translate('resources.prices.fields.productId')}
                          source="productId" reference="products" linkType="show">
            <TextField source="name"/>
          </ReferenceField>

          <TextField source="productCode"/>

          <SelectField source="source" choices={[
            {id: 'it24', name: '아이티샵'},
            {id: 'joonggo', name: '중고나라'},
          ]}/>
          <SelectField source="type" choices={[
            {id: 'buy', name: '매입'},
            {id: 'sell', name: '판매'},
          ]}/>
          <NumberField source="price" sortable={false}/>
          <DateField source="date" locales='ko'/>
          <TextField source="memo"/>

          <ArrayField source="inspections" addLabel={true}>
            <Datagrid>
              <TextField source="name" label={translate('common.name')} sortable={false}/>
              <TextField source="value" label={translate('common.value')} sortable={false}/>
            </Datagrid>
          </ArrayField>

          <TextField source="researchName"/>
          <DateField source="createdAt" locales='ko' showTime sortable={false}/>
        </SimpleShowLayout>
      </Show>
    )
  }
}

export default translate(PriceShow)
