import React, {forwardRef} from 'react'
import {AppBar, MenuItemLink, UserMenu} from 'react-admin'
import Typography from '@material-ui/core/Typography'
import {makeStyles} from '@material-ui/core/styles'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  spacer: {
    flex: 1
  }
})

const ConfigurationMenu = forwardRef((props, ref) => {
  return (
    <>
      <MenuItemLink
        ref={ref}
        to="/users"
        primaryText={localStorage.getItem('username')}
        leftIcon={<AccountCircleIcon />}
        onClick={props.onClick}
      />
    </>
  )
})

const CustomUserMenu = (props) => (
  <UserMenu {...props}>
    <ConfigurationMenu />
  </UserMenu>
)

const CustomAppBar = (props) => {
  const classes = useStyles()
  return (
    <AppBar {...props} userMenu={<CustomUserMenu />}>
      <Typography variant="h6" color="inherit" className={classes.title} id="react-admin-title" />
      <span className={classes.spacer} />
    </AppBar>
  )
}

export default CustomAppBar
