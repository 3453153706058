import {
  CREATE,
  DELETE,
  GET_LIST,
  GET_ONE,
  UPDATE,
} from 'react-admin'
import {convertImagePath, uploadToS3} from './ImageHandler'
import momentTimezone from 'moment-timezone'

export async function request(apiUrl, type, params) {
  let url = apiUrl + '/admin/popups'
  let options = {}

  switch (type) {
    case CREATE:
    case UPDATE:
      if (type === UPDATE) {
        url += `/${params.id}`
        options.method = 'PUT'
      } else options.method = 'POST'

      const data = {...params.data}

      if (data.startDate && data.endDate)  {
        data.startDate = momentTimezone.tz(data.startDate, 'Asia/Seoul').format('YYYY-MM-DD')
        data.endDate = momentTimezone.tz(data.endDate, 'Asia/Seoul').format('YYYY-MM-DD')
      }
      else {
        delete data.startDate
        delete data.endDate
      }

      options.data = await uploadToS3(data, 'web', 'file',)
      options.data = await uploadToS3(data, 'mobile', 'file',)

      break
    case GET_ONE:
      url += `/${params.id}`
      break
    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break
    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res
  switch (type) {
    case GET_LIST:
      data.forEach(row => {
        row.web = convertImagePath(row.web, 'small')
        row.mobile = convertImagePath(row.mobile, 'small')
      })
      ret = {data, total: data.length}
      break
    case GET_ONE:
      data.web = {src: convertImagePath(data.web, 'large')}
      data.mobile = {src: convertImagePath(data.mobile, 'large')}
      ret = {data}
      break
    default:
      if (data) ret = {data}
      break
  }
  return ret
}
