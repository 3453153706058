import {
  AUTH_GET_PERMISSIONS,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK,
} from 'react-admin'
import {dataProvider} from '../App'

export default async (type, params) => {
  if (type === AUTH_LOGIN) {
    let type
    if (params.isAdmin) {
      type = 'ADMIN_LOGIN'
    } else {
      type = 'LOGIN'
    }
    const {data: {accessToken, refreshToken, role, username}} = await dataProvider(type, 'auth', {data: params})
    localStorage.setItem('accessToken', accessToken)
    localStorage.setItem('refreshToken', refreshToken)
    localStorage.setItem('role', role)
    localStorage.setItem('username', username)
    return Promise.resolve()
  }
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('role')
    localStorage.removeItem('username')
    return Promise.resolve()
  }
  if (type === AUTH_ERROR) {
    const {status} = params
    if (status === 401 || status === 403) {
      return Promise.reject()
    } else {
      return Promise.resolve()
    }
  }
  if (type === AUTH_CHECK) {
    if (params && params.role === 'guest') {
      return Promise.resolve()
    } else {
      if (localStorage.getItem('accessToken')) {
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    }
  }
  if (type === AUTH_GET_PERMISSIONS) {
    const role = localStorage.getItem('role')
    if (role) {
      return Promise.resolve(role)
    } else {
      return Promise.resolve('guest')
    }
  }
  return Promise.reject('Unknown method')
}
