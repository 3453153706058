import React from 'react'

import {
  BooleanInput,
  Edit,
  FormDataConsumer,
  FormTab,
  ImageField,
  ImageInput,
  SelectInput,
  TabbedForm,
  TextInput,
  translate,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  AutocompleteInput
} from 'react-admin'
import {required} from '../../validators'
import RelateCategories from '../categories/RelatedCategories'
import withProductRelations from '../../hocs/withProductRelations'
import RelatedInspections from '../inspections/RelatedInspections'
import EditActions from '../../custom/common/EditActions'
import EditToolbar from '../../custom/common/EditToolbar'
import RelatedExtras from './RelatedExtras'
import ProductBanners from "./ProductBanners";

const validateCreation = (values) => {
  const errors = {}
  if (values.parentIds.length === 0) {
    errors.parentIds = ['카테고리는 필수입니다.']
  }
  return errors
}

const defaultExtras = [
  {name: 'CPU'},
  {name: 'RAM'},
  {name: 'SSD'},
  {name: 'HDD'},
  {name: 'Color'}
]

class ProductCreate extends React.Component {
  render() {

    const {categories, inspections, translate, ...props} = this.props
    return (
      <Edit {...props} actions={<EditActions/>}>
        <TabbedForm
          validate={validateCreation}
          toolbar={<EditToolbar/>}
          redirect={'show'}
          defaultValue={{parentIds: [], extras: defaultExtras}}>
          <FormTab label={translate('resources.products.summary')}>
            <TextInput disabled source="id"/>

            <FormDataConsumer>
              {props => <SelectInput source="brandId"
                                     label={translate('resources.products.fields.brandId')}
                                     choices={props.formData.brandsInfo}
                                     validate={required}/>}
            </FormDataConsumer>

            <FormDataConsumer>
              {props => <RelateCategories {...props} categories={categories} where={"product"}/>}
            </FormDataConsumer>

            <TextInput source="title" validate={required}/>
            <TextInput source="name" validate={required}/>
            <TextInput source="prefix" />
            <TextInput disabled source="price" validate={required}/>
            <TextInput source="code"/>
            <TextInput source="size"/>
            <TextInput source="year"/>
            <BooleanInput source="onDeal"/>

            <ImageInput source="titleImage" accept="image/*">
              <ImageField source="src" title="title"/>
            </ImageInput>

            <ImageInput multiple source="images" accept="image/*">
              <ImageField source="src" title="title"/>
            </ImageInput>
          </FormTab>
          <FormTab label={translate('resources.products.extras')}>
            <FormDataConsumer>
              {props => <RelatedExtras {...props}/>}
            </FormDataConsumer>
          </FormTab>
          <FormTab label={translate('resources.inspections.name')}>
            <FormDataConsumer>
              {props => <RelatedInspections {...props} inspections={inspections}/>}
            </FormDataConsumer>
          </FormTab>
          <FormTab label="resources.products.fields.banners">
            <ArrayInput source="banners">
              <SimpleFormIterator>
                <ProductBanners {...props} />
              </SimpleFormIterator>
            </ArrayInput>
          </FormTab>
          <FormTab label="resources.products.fields.blogs">
            <ArrayInput source="blogs" addLabel={false}>
              <SimpleFormIterator>
                <ReferenceInput
                  label={'resources.blogs.fields.id'}
                  source="id"
                  reference="blogs"
                  perPage={10}
                  alwaysOn
                  validate={required}
                >
                  <AutocompleteInput optionText="title" optionValue="id" />
                </ReferenceInput>
              </SimpleFormIterator>
            </ArrayInput>
          </FormTab>
        </TabbedForm>
      </Edit>
    )
  }
}

export default translate(withProductRelations(ProductCreate))
