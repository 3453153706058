import React from 'react';
import compose from 'recompose/compose';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {withStyles} from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import {translate} from 'react-admin';

const styles={
    question:{
        display:'flex',
        justifyContent:'space-between',
        paddingLeft : 8,
        paddingRight : 8,
        minWidth : 390,
    },
    questionInfo:{
        minWidth : 350,
    },
    questionMain:{
        fontWeight: 400,
        lineHeight: 1.43,
        letterSpacing: '0.01071em',
    },
    questionStatus:{
        display:'flex',
        justifyContent : 'center',
        minWidth : 90,
    },
}

function QuestionCardList(props){
    const {questions}=props;
    const questionType = (type) => {
        switch(type){
            case 'goods':
                return '상품문의';
            case 'payment':
                return '구매/결제문의';
            case 'exchange':
                return '교환문의';
            case 'delivery':
                return '베송문의';
            case 'refund':
                return '취소/환불/반품문의';
            default:
                return;
        }
    }
    
    return(
        <List>
            {questions.map(question =>{  
                    return(
                        <ListItem
                            key={question.id}
                            button
                            component={Link}
                            to={`/questions/${question.id}/show`}
                            className={props.classes.question}
                        >
                            <div>
                                <ListItemText 
                                    primary={`${new Date(question.createdAt).toLocaleString()} `}
                                    secondary={`by ${question.name}`}
                                    className={props.classes.questionInfo}
                                />
                                <div className={props.classes.questionMain}>{question.question}</div>
                            </div>
                            <ListItemText 
                                primary={questionType(question.type)}
                                className={props.classes.questionStatus}
                            />
                        </ListItem>
                    )
                })}
        </List>
    )
}

const enhance=compose(
    withStyles(styles),
    translate,
);

export default enhance(QuestionCardList);