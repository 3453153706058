import React from 'react'
import {ArrayInput, SimpleFormIterator, TextInput, translate,} from 'react-admin'
import {required} from '../../validators'
import get from 'lodash/get'

const ArrayItem = translate(({id, orderChoices, onChange, translate}) => (
  <React.Fragment>
    <TextInput source={id + '.name'}
               label={translate('common.name')}
               validate={required}/>
    <TextInput source={id + '.value'}
               label={translate('common.value')}
               style={{marginLeft: 20}}
               validate={required}/>
  </React.Fragment>)
)

class RelatedExtras extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(id, val) {
    const {formData} = this.props
    const selectedItem = get(formData, id)

    const beforeSelected = formData.extras.find(item => item.order === val)
    if (beforeSelected) {
      beforeSelected.order = selectedItem.order
    }
  }

  render() {
    const {formData, translate} = this.props
    if (!formData || !formData.extras) return ''
    const orderChoices = Array.from({length: formData.extras.length})
      .map((v, i) => ({id: i + 1}))
    return (
      <ArrayInput source="extras"
                  label={translate('resources.products.fields.extras')}>
        <SimpleFormIterator>
          <ArrayItem orderChoices={orderChoices} onChange={this.handleChange}/>
        </SimpleFormIterator>
      </ArrayInput>
    )
  }

}

export default translate(RelatedExtras)
