import {
  CREATE,
  DELETE,
  GET_LIST,
  GET_ONE,
  UPDATE,
} from 'react-admin'

export function request(apiUrl, type, params) {
  let url = apiUrl + '/admin/sites'
  let options = {}
  
  switch (type) {
    case CREATE:
      options.method = 'POST'
      options.data = params.data
      break
    case GET_ONE:
      url += `/${params.id}`
      break
    case UPDATE:
      url += `/${params.id}`
      options.method = 'PUT'
      options.data = params.data
      break
    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break
    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res
  switch (type) {
    case GET_LIST:
      ret = {data, total: data.length}
      break
    default:
      if (data) ret = {data}
      break
  }
  return ret
}
