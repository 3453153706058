import React from 'react'
import {
  TopToolbar,
  DateField,
  DeleteButton,
  ListButton,
  ReferenceField,
  SelectField,
  Show,
  SimpleShowLayout,
  TextField,
  translate,
} from 'react-admin'
import QuestionAnswerButton from './QuestionAnswerButton'
import * as choices from './choices'

const ShowActions = ({basePath, resource, data = {}, ...props}) => {
  return (
  <TopToolbar>
    <ListButton basePath={basePath}/>
    {data.id && <QuestionAnswerButton basePath={basePath} record={data}/>}
    {data.id && <DeleteButton basePath={basePath} resource={resource} record={data}/>}
  </TopToolbar>
)}

class QuestionShow extends React.Component {
  render() {
    const {translate, ...props} = this.props
    return <Show {...props} actions={<ShowActions/>}>
      <SimpleShowLayout>
        <ReferenceField source="userId" reference="users" link="show">
          <TextField source="name"/>
        </ReferenceField>
        <ReferenceField source="goodsId" reference="goods" link="show" allowEmpty>
          <TextField source="name"/>
        </ReferenceField>
        <ReferenceField source="productId" reference="products" link="show" allowEmpty>
          <TextField source="name"/>
        </ReferenceField>
        <SelectField source="type" choices={choices.types}/>
        <TextField style={{whiteSpace: 'pre'}} source="question"/>
        <TextField style={{whiteSpace: 'pre'}} source="answer"/>
        <DateField source="createdAt" locales="ko" showTime/>
        <DateField source="answeredAt" locales="ko" showTime/>
      </SimpleShowLayout>
    </Show>
  }
}

export default translate(QuestionShow)
