import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  TopToolbar,
  Datagrid,
  Filter,
  List,
  NumberField,
  SearchInput,
  TextField,
} from 'react-admin'

const styles = theme => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }
})
const Actions = ({basePath,}) => (
  <TopToolbar>
  </TopToolbar>
)

const ListFilter = props => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn/>
  </Filter>
)

export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props}
          actions={<Actions/>}
          filters={<ListFilter/>}
          perPage={25}
          bulkActionButtons={false}>
      <Datagrid rowClick="show">
        <TextField source="id"/>
        <TextField source="name" sortable={false}/>
        <NumberField source="code" sortable={false}/>
        <NumberField source="price" />
        <NumberField source="buyPrice" sortable={false}/>
      </Datagrid>
    </List>
  )
})

