import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  BooleanField,
  TopToolbar,
  Datagrid,
  DateField,
  Filter,
  List,
  SearchInput,
  SelectField,
  SelectInput,
  TextField,
} from 'react-admin'
import Typography from '@material-ui/core/Typography'
import get from 'lodash/get'
import * as choices from './choices'

const styles = theme => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  search: {
    height:'3rem',
    padding: '0 0',
    margin: 0
  },
  select: {
    height:'3rem',
    padding: '0 0',
    margin: 0
  }
})

const TitleField = (({record, style, ...rest}) => {
  const str = get(record, rest.source)
  let content = ''
  if (str && str.length > 30) {
    content = str.substring(0, 30) + '...'
  } else content = str
  return (
    <Typography style={style}>{content}</Typography>
  )
})

const Actions = ({basePath,}) => (
  <TopToolbar>
  </TopToolbar>
)

const ListFilter = props => (
  <Filter {...props}>
    <SearchInput source="search" className={props.classes.search} alwaysOn/>
    <SelectInput source="type" className={props.classes.select} choices={choices.types} alwaysOn/>
    <SelectInput source="answered" className={props.classes.select} choices={[
      {id: false, name: '답변안함'},
      {id: true, name: '답변함'}
    ]} alwaysOn/>
  </Filter>
)

export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props}
          filters={<ListFilter classes={classes}/>}
          perPage={25}
          sort={{field: 'createdAt', order: 'DESC'}}
          actions={<Actions/>}
          bulkActionButtons={false}>
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false}/>
        <SelectField source="type" choices={choices.types}/>
        <TitleField source="question" sortable={false}/>
        <BooleanField source="answered" sortable={false}/>
        <TextField source="name" sortable={false}/>
        <DateField source="createdAt" locales='ko' showTime/>
        <DateField source="answeredAt" locales="ko" showTime/>
      </Datagrid>
    </List>
  )
})

