import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import LocalShippingIconRounded from '@material-ui/icons/LocalShippingRounded'
import EnrollDeliveryForm from './EnrollDeliveryForm'

const EnrollDeliveryInfo = ({ basePath, data, resource}) => {
  const [open, setOpen] = useState(false)

  const handleFormOpen = () => {
    setOpen(true)
  }

  const handleFormClose = () => {
    setOpen(false)
  }

  return data.hasOwnProperty('delivery') ? (
    <>
      <Button
        size="small"
        color="primary"
        startIcon={<LocalShippingIconRounded />}
        onClick={handleFormOpen}
      >
        택배 등록
      </Button>
      <EnrollDeliveryForm
        open={open}
        onClose={handleFormClose}
        basePath={basePath}
        resource = {resource}
        data={data}
      />
    </>
  ) : null
}

export default EnrollDeliveryInfo
