import React from 'react'
import {
  TopToolbar,
  TextInput,
  Edit,
  ImageField,
  ImageInput,
  ListButton,
  SimpleForm,
  SelectInput,
  NumberInput,
  DateInput,
  BooleanInput
} from 'react-admin'
import {required} from '../../validators'
import EditToolbar from '../../custom/common/EditToolbar'

const EditActions = ({basePath, data}) => (
  <TopToolbar>
    <ListButton basePath={basePath}/>
  </TopToolbar>
)

export default (props) => (
  <Edit {...props} actions={<EditActions/>}>
    <SimpleForm toolbar={<EditToolbar/>}>
      <TextInput disabled source='id'/>
      <TextInput disabled source='adminId'/>
      <SelectInput source="site"
                   choices={[
                     {id: 'mybaro', name: 'mybaro'},
                     {id: 'sell.mybaro', name: 'sell.mybaro'}
                   ]}
                   validate={required}/>
      <SelectInput source="type"
                   choices={[
                     {id: 'main', name: '메인'}
                   ]}
                   validate={required}/>
      <ImageInput source="web" accept="image/*" validate={required}>
        <ImageField source="src" title="title"/>
      </ImageInput>
      <ImageInput source="mobile" accept="image/*">
        <ImageField source="src" title="title"/>
      </ImageInput>

      <DateInput source="startDate" />
      <DateInput source="endDate" />

      <BooleanInput source="alwaysOn" validate={required} />

      <NumberInput source="order" defaultValue={1} validate={required}/>
    </SimpleForm>
  </Edit>
)
