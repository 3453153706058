import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  TopToolbar, CreateButton, Datagrid, DateField, 
  EditButton, DeleteButton, List, TextField, translate,
  Filter, SelectInput
} from 'react-admin'

const styles = theme => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }
})
const Actions = ({basePath,}) => (
  <TopToolbar>
    <CreateButton basePath={basePath}/>
  </TopToolbar>
)

const siteChoices =[
  {id: 'mybaro', name: 'mybaro'},
  {id: 'sell.mybaro', name: 'sell.mybaro'},
]

const ListFilter=props =>(
  <Filter {...props}>
    <SelectInput source="site" choices={siteChoices} initialValue='mybaro' alwaysOn/>
  </Filter>
)

export default withStyles(styles)(translate(({classes, translate, ...props}) => {
  return (
    <List {...props}
        pagination={null}
        actions={<Actions/>}
        filters={<ListFilter/>}
        bulkActionButtons={false}>
      <Datagrid>
        <TextField source="id" sortable={false}/>
        <TextField source="keyword" sortable={false}/>
        <TextField source="order" sortable={false}/>
        <DateField source="createdAt" locales='ko' sortable={false}/>
        <EditButton label={translate('ra.action.edit')}/>
        <DeleteButton label={translate('ra.action.delete')}/>
      </Datagrid>
    </List>
  )
}))