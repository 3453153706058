import React from 'react'
import {Create, SimpleForm, TextInput, FormDataConsumer, ImageInput, ImageField} from 'react-admin'
import {required} from '../../validators'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'
import SelectCategories from './SelectCategories'

export default (props) => {

  return(
    <Create {...props} actions={<CreateActions/>}>
      <SimpleForm toolbar={<CreateToolbar/>}>
        <TextInput source="name" validate={required}/>
        <FormDataConsumer>
          {props=><SelectCategories {...props}/>}
        </FormDataConsumer>      
        
        <ImageInput source="image">
          <ImageField source="src" title="title"/>
        </ImageInput>
        
        <TextInput source="keywords"/>
      </SimpleForm>
    </Create>
)}