import {CREATE, DELETE, GET_LIST, GET_MANY, GET_ONE, UPDATE,} from 'react-admin'
import {convertImagePath, uploadToS3} from './ImageHandler'
import {dataProvider} from "../../App";

export async function request(apiUrl, type, params) {
  let url = apiUrl + '/admin/products'
  let options = {}

  switch (type) {
    case CREATE:
    case UPDATE:
      if (type === UPDATE) {
        url += `/${params.id}`
        options.method = 'PUT'
      } else options.method = 'POST'

      const data = {...params.data}
      data.extras = data.extras.sort((a ,b) => a.order - b.order)
      data.categoryId = data.parentIds[data.parentIds.length - 1]
      delete data.parentIds

      if (data.banners && data.banners.length) {
        for (const banner of data.banners) {
          await uploadToS3(banner, 'image', 'image')
        }
        data.banners.forEach(banner => {
          if (banner.image) banner.src = banner.image.src
          delete banner.pathName
          delete banner.image
        })
      }

      options.data = await uploadToS3(data, 'titleImage', 'image',)
      options.data = await uploadToS3(data, 'images', 'image',)
      break
    case GET_LIST:
      const {categoryId} = params
      if (categoryId) {
        options.params = params
      } else {
        const {page, perPage} = params.pagination
        const {field, order} = params.sort
        options.params = {
          ...params.filter,
          sort: field,
          order: order,
          start: (page - 1) * perPage,
          perPage: perPage,
        }
      }
      break
    case GET_ONE:
      url += `/${params.id}`
      break
    case GET_MANY:
      if (params.ids.length === 1) url += `/${params.ids[0]}`
      break
    case DELETE:
      options.method = 'DELETE'
      url += `/${params.id}`
      break
    default:
      break
  }
  return {url, ...options}
}

export async function response(res, type, params) {
  let ret = {}
  const {data} = res

  switch (type) {
    case UPDATE:
      ret = params
      break
    case GET_ONE:

      if (data.banners) {
        data.banners.forEach(banner => {
          if (!banner.path) banner.pathName = '없음'
          else banner.pathName = banner.path
          banner.image = {src: convertImagePath(banner.src, 'large')}
        })
      }
      data.titleImage.src = convertImagePath(data.titleImage.src, 'large')
      data.images = data.images.map(image => {
        image.file = {src: convertImagePath(image.src, 'large')}
        image.src = convertImagePath(image.src, 'large')
        return image
      })
      data.categories = sortCategories(data.categories, data.categoryId)
      data.parentIds = data.categories.map(cat => cat.id)
      data.inspections = data.inspections.sort((a, b) => a.order - b.order)
      let result = await dataProvider(
        GET_LIST,
        "brands",
        {
          filter:{},
          pagination :{page :1, perPage: 1000},
          sort : {field : "id", order:"ASC"}
        })
      let brandName = null;
      const brandsInfo = result.data.map((brandInfo)=>{
        if(brandInfo.id === data.brandId) brandName = brandInfo.name;
        return {
          id : brandInfo.id ,
          name : brandInfo.name
        }
      })

      let goods = await dataProvider(
          'GET_PRODUCT',
          "goods",
          {
            filter:{},
            id: data.id,
            pagination :{page :1, perPage: 1000},
            sort : {field : "id", order:"ASC"}
          })

      goods.data.forEach(row => row.titleImage = convertImagePath(row.titleImage, 'small'))

      data.goods = goods.data;

      data.brandsInfo = brandsInfo;
      data.brandName = brandName;
      ret = {data}
      break
    case GET_LIST:
      data.data = data.data.map(row => {
        row.titleImage = convertImagePath(row.titleImage, 'small')
        return row
      })
      ret = data
      break
    case GET_MANY:
      if (params.ids.length === 1) {
        ret = {data: [data]}
      }
      else ret = {data}
      break
    default:
      if (data) ret = {data}
      break
  }
  return ret
}

function sortCategories(arr, id) {
  const category = arr.find(cat => cat.id === id)
  const ret = []
  let parentId = category.id
  while (parentId) {
    const parent = arr.find(cat => cat.id === parentId) // eslint-disable-line no-loop-func
    ret.unshift(parent)
    parentId = parent.parentId
  }
  return ret
}
