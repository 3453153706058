import React from 'react'
import {
  BooleanInput,
  TopToolbar,
  Edit,
  ImageField,
  ImageInput,
  ListButton,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  AutocompleteInput
} from 'react-admin'
import {required} from '../../validators'
import EditToolbar from '../../custom/common/EditToolbar'

const EditActions = ({basePath, data}) => (
  <TopToolbar>
    <ListButton basePath={basePath}/>
  </TopToolbar>
)

export default (props) => (
  <Edit {...props} actions={<EditActions/>}>
    <SimpleForm toolbar={<EditToolbar/>}>
      <TextInput disabled source='id'/>
      <TextInput source="name" validate={required}/>
      <TextInput source="subname" label="이벤트 부제목"/>
      <BooleanInput source="onSale"/>
      <TextInput source="backgroundColor"/>
      <ImageInput source="desktopImage" accept="image/*" validate={required}>
        <ImageField source="src" title="title"/>
      </ImageInput>
      <ImageInput source="mobileImage" accept="image/*" validate={required}>
        <ImageField source="src" title="title"/>
      </ImageInput>
      <ImageInput source="appImage" accept="image/*">
        <ImageField source="src" title="title"/>
      </ImageInput>
      <ImageInput source="descriptionImage" accept="image/*">
        <ImageField source="src" title="title"/>
      </ImageInput>
      <ArrayInput source="blogs" addLabel={false}>
        <SimpleFormIterator>
          <ReferenceInput
            label={'resources.blogs.fields.id'}
            source="id"
            reference="blogs"
            perPage={10}
            alwaysOn
            validate={required}
          >
            <AutocompleteInput optionText="title" optionValue="id" />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
)
