import {
  CREATE, DELETE,
  GET_LIST, GET_MANY,
  GET_ONE,
  UPDATE,
} from 'react-admin'
import {uploadToS3, convertImagePath} from './ImageHandler'

export async function request(apiUrl, type, params) {
  let url = apiUrl + '/admin/goods'
  let options = {}
  switch (type) {
    case CREATE:
    case UPDATE:
      if (type === UPDATE) {
        url += `/${params.id}`
        options.method = 'PUT'
      } else options.method = 'POST'

      const data = {...params.data}
      if (data.optionType === 'single') {
        data.quantity = data.options ? data.options.reduce((prev, o) => prev + o.quantity, 0) : 0
      }
      if (data.isTimeSale) {
        data.startDate = new Date(data.startDate)
        data.endDate = new Date(data.endDate)
      }
      if (!data.stockId) delete data.stockId
      options.mainImages = await uploadToS3(data, 'mainImages', 'image')
      options.detailImages = await uploadToS3(data, 'detailImages', 'image')
      options.data = data
      if (options.data.status === 'onUseInside' || options.data.status === 'onRepair') {
        options.data.onSale = false
      }
      break
    case GET_LIST:
      const {categoryId} = params
      if (categoryId) {
        options.params = params
      }
      else {
        const {page, perPage} = params.pagination
        const {field, order} = params.sort
        if (params.filter && params.filter.q) {
          params.filter.search = params.filter.q
          delete params.filter.q
        }
        options.params = {
          ...params.filter,
          sort: field,
          order: order,
          start: (page - 1) * perPage,
          perPage: perPage,
        }
      }
      break
    case GET_ONE:
      url += `/${params.id}`
      break
    case GET_MANY:
      if (params.ids.length === 1) url += `/${params.ids[0]}`
      break
    case 'GET_STOCK':
      url += `/stocks/${params.id}`
      break
    case 'GET_PRODUCT':
      url += `/products/${params.id}`
      break
    case DELETE:
      options.method = 'DELETE'
      url += `/${params.id}`
      break
    case 'GET_OUTSIDE_GOODS':
      options.method = 'GET'
      options.config = {timeout: 1000 * 60 * 10}
      options.params = {...params}
      url += `/outside/goods`
      break;
    case 'CREATE_OUTSIDE_GOODS':
      options.method = 'POST'
      options.data = {id: params.id}
      options.config = {timeout: 1000 * 60 * 10}
      url += `/outside/goods`
      break;
    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res

  switch (type) {
    case UPDATE:
      ret = params
      break
    case GET_ONE:
      data.titleImage.src = convertImagePath(data.titleImage.src, 'large')
      data.mainImages.map(image => image.src = convertImagePath(image.src, 'large'))
      data.detailImages.map(image => image.src = convertImagePath(image.src, 'large'))
      console.log(data)
      ret = {data}
      break
    case GET_LIST:
      data.data.forEach(row => row.titleImage = convertImagePath(row.titleImage, 'small'))
      ret = data
      break
    case GET_MANY:
      if (params.ids.length === 1) {
        data.titleImage.src = convertImagePath(data.titleImage.src, 'large')
        data.mainImages.map(image => image.src = convertImagePath(image.src, 'large'))
        data.detailImages.map(image => image.src = convertImagePath(image.src, 'large'))
        ret = {data: [data]}
      }
      else ret = {data}
      break

    default:
      if (data) ret = {data}
      break
  }
  return ret
}
