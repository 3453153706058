import React from 'react'
import Chip from '@material-ui/core/Chip'

const styles = {
  main: {display: 'flex', flexWrap: 'wrap'},
  chip: {margin: 4},
}

const names = {
  new: 'NEW',
  sale: 'SALE',
  recommend: '추천',
  direct: '직구'
}

const PropertyField = ({record}) => {
  return (
    <span style={styles.main}>
        {record.properties &&
        record.properties.map((property, i) => (
          <Chip
            key={i}
            style={styles.chip}
            label={names[property]}
          />
        ))}
    </span>
  )
}

PropertyField.defaultProps = {
  addLabel: true,
  source: 'properties'
}
export default PropertyField
