import {
    CREATE, DELETE,
    GET_LIST, GET_MANY,
    GET_ONE,
    UPDATE,
} from 'react-admin'
import {uploadToS3, convertImagePath} from './ImageHandler'

export async function request(apiUrl, type, params) {
    let url = apiUrl + '/admin/goods'
    let options = {}
    switch (type) {
        case GET_LIST:
            if(params.filter.title) {
                params.filter.title = params.filter.title.split(',')[1]
            }
            if(params.filter.size) {
                params.filter.size = params.filter.size.split(',')[1]
            }
            if(params.filter.year) {
                params.filter.year = Number(params.filter.year.split(',')[1])
            }
            if(params.filter.category) {
                params.filter.category = params.filter.category.split(',')[1]
            }

            params.filter.status = 'onSale'

            const {categoryId} = params
            if (categoryId) {
                options.params = params
            }
            else {
                const {page, perPage} = params.pagination
                const {field, order} = params.sort
                if (params.filter && params.filter.q) {
                    params.filter.search = params.filter.q
                    delete params.filter.q
                }
                options.params = {
                    ...params.filter,
                    sort: field,
                    order: order,
                    start: (page - 1) * perPage,
                    perPage: perPage,
                }
            }
            break
        default:
            break
    }
    return {url, ...options}
}

export function response(res, type, params) {
    let ret = {}
    const {data} = res

    switch (type) {
        case GET_LIST:
            data.data.forEach(row => {
                row.titleImage = convertImagePath(row.titleImage, 'small')
                row.margin = Math.ceil((row.price - row.buyPrice) / row.price * 100) + '%'
            })

            ret = data
            break
        default:
            if (data) ret = {data}
            break
    }
    return ret
}
