import {
  GET_LIST,
  GET_MANY_REFERENCE
} from 'react-admin'

export function request(apiUrl, type, params) {
  let url = apiUrl + '/admin/users/points'
  let options = {}

  switch (type) {
    case GET_MANY_REFERENCE:
    case GET_LIST:
      url += '/list'
      const {page, perPage} = params.pagination
      const {field, order} = params.sort

      options.params = {
        ...params.filter,
        sort: field,
        order: order,
        start: (page - 1) * perPage,
        perPage: perPage,
      }
      break
    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res
  switch (type) {
    case GET_MANY_REFERENCE:
    case GET_LIST:
      ret = {data: data.list, total: data.total}
      break
    default:
      if (data) ret = {data}
      break
  }
  return ret
}
