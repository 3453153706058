import React, {useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Button as AdminButton, crudGetOne} from 'react-admin'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import {dataProvider} from '../../App'
import {useDispatch} from 'react-redux'
import TextField from '@material-ui/core/TextField'
import AdminIcon from '@material-ui/icons/Note'

const useStyles = makeStyles({
  dialog: {
    width: '80%'
  },
  textField: {
    width: '50%'
  },
  spanMargin: {
    margin: '5px'
  },
  statusField: {
    width: '70px'
  },
  descriptionField: {
    width: '100%'
  }
})

export default (props) => {
  const [isOpen, setOpen] = useState(false)
  const [memo, setMemo] = useState(null)
  const dispatch = useDispatch()
  const classes = useStyles()

  function handleShowModal() {
    setMemo(props.memo)
    setOpen(true)
  }

  function handleCancel() {
    setOpen(false)
  }

  function handleChange(e) {
    const {value} = e.target
    setMemo(value)
  }

  function validate() {
    try {
      if (memo && memo.length > 10000) throw new Error(`최대 입력 가능 글자 수는 10000자 입니다. 현재 ${memo.length}자`)
    } catch (e) {
      e.status = 100
      throw e
    }
  }

  async function handleDone(e) {
    e.stopPropagation()

    try {
      validate()
      const {id, basePath, resource, type} = props
      const params = {memo}
      if (type) params.type = type
      const {data} = await dataProvider('UPDATE_MEMO', resource, {id, data: params})

      if (data) {
        dispatch(crudGetOne(resource, id, basePath, true))
      } else {
        alert('저장을 실패 하였습니다.')
        return
      }

      setOpen(false)
    } catch (e) {
      if (e.status === 100) alert(e.message)
      else {
        alert('저장을 실패 하였습니다.')
      }
    }
  }

  const DialogButton = () => {
    return (
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          취소
        </Button>
        <Button onClick={handleDone} color="primary">
          확인
        </Button>
      </DialogActions>
    )
  }

  return (
    <section>
      <AdminButton label={'메모'} onClick={handleShowModal} disabled={props.disabled}>
        <AdminIcon />
      </AdminButton>
      <div>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          aria-labelledby="confirmation-dialog-title"
          open={isOpen}
          classes={{paper: classes.dialog}}
        >
          <DialogContent>
            <TextField
              style={{width: '100%'}}
              margin="normal"
              multiline
              name="memo"
              onChange={handleChange}
              value={memo}
            />
          </DialogContent>
          <DialogButton />
        </Dialog>
      </div>
    </section>
  )
}
