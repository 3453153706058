import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import { Button as UpdateButton, Loading } from 'react-admin';
import Button from '@material-ui/core/Button';
import DownloadIcon from '@material-ui/icons/ImportExport'
import TextField from '@material-ui/core/TextField'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import {dataProvider} from "../../App";
import momentTimezone from 'moment-timezone'

const styles = theme => ({
  dialog: {
    width: '100%',
  },
  button: {}
});

class RegistrationTempGoods extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isOpenConfirm : false,
      isLoading: false,
      startDate: momentTimezone.tz(new Date(), 'Asia/Seoul').format('YYYY-MM-DD'),
      endDate: momentTimezone.tz(new Date(), 'Asia/Seoul').format('YYYY-MM-DD'),
      createList: [],
      succeedCount: 0
    }
  }

  handleShowModal = async () => {
    this.setState({isLoading: false})
    this.setState({isOpen: true})
  }

  handleCancel = () => {
    this.setState({isOpen: false, isOpenConfirm: false})
  }

  handleGetOutsideGoods = async () => {
    const {startDate, endDate} = this.state
    this.setState({isLoading: true, createList: []})
    const {data} = await dataProvider('GET_OUTSIDE_GOODS', 'goods', {
      startDate: momentTimezone.tz(new Date(startDate), 'Asia/Seoul').format('YYYY-MM-DD'),
      endDate: momentTimezone.tz(new Date(endDate), 'Asia/Seoul').format('YYYY-MM-DD'),
    })
    if (data && data.length > 0) {
      this.setState({isOpenConfirm: true, createList: data})
    }
    else if (data && data.length === 0) {
      alert('모두 추가 되어 새로 추가할 상품이 없습니다.')
    }
    else {
      alert('상품 가져오기를 실패 하였습니다. 다시 시도해 주세요.')
    }
    this.setState({isLoading: false})
    this.setState({isOpen: false})
  }

  handleCreateOutsideGoods = async () => {
    const {createList} = this.state
    let succeedCount = 0
    this.setState({isLoading: true, succeedCount})

    for (const product of createList) {
      const {data} = await dataProvider('CREATE_OUTSIDE_GOODS', 'goods', {id: product.id})
      console.log(data)
      if (data.result) {
        ++succeedCount
        this.setState({succeedCount})
      }
      else {
        // alert(data)
      }
      // break
    }
    if (succeedCount > 0) {
      alert(`${succeedCount}/${createList.length}개 가져오기를 완료 했습니다.`)
      window.location.reload()
    }
    else {
      alert(`가져오기를 실패 하였습니다.`)
    }

    this.setState({isLoading: false, isOpen: false, isOpenConfirm: false, createList: []})
  }

  handleChange = (e) => {
    const {name, value} = e.target
    this.setState({[name]: value})
  }

  render() {
    const {isOpen, isOpenConfirm, createList, succeedCount, startDate, endDate} = this.state
    const {classes} = this.props

    const Progress = () => {
      const {isLoading} = this.state
      return isLoading ? <Loading loadingPrimary="" loadingSecondary="" /> : ''
    }

    const DialogButton = () => {
      const {isLoading} = this.state
      return isLoading === false ? <DialogActions>
        <Button onClick={this.handleCancel} color="primary">
          취소
        </Button>
        <Button onClick={isOpen ? this.handleGetOutsideGoods : this.handleCreateOutsideGoods} color="primary">
          확인
        </Button>
      </DialogActions> : ''
    }

    return (
      <section>
        <UpdateButton
          label={'상품 가져오기'}
          onClick={this.handleShowModal}
        >
          <DownloadIcon/>
        </UpdateButton>

        <div>
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={isOpen}
            classes={{paper: classes.dialog}}
          >
            <DialogContent>
              <div>
                <TextField
                  style={{width: '48%'}}
                  type={'date'}
                  name={'startDate'}
                  value={startDate}
                  onChange={this.handleChange}
                />
                ~<TextField
                style={{width: '48%'}}
                type={'date'}
                name={'endDate'}
                value={endDate}
                onChange={this.handleChange}
              />
              </div>
              <Progress/>

            </DialogContent>
            <DialogButton/>
          </Dialog>
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={isOpenConfirm}
            classes={{paper: classes.dialog}}
          >
            <DialogTitle id="confirmation-dialog-title">외부 상품 가져오기 2단계 {createList.length} 개를 가져옵니다.
              (최장 10분 대기 후 동작 없을시 새로고침 하고 추가된 상품을 확인해 주세요.) {succeedCount}/{createList.length} 완료
            </DialogTitle>
            <DialogContent>
              <Progress/>

            </DialogContent>
            <DialogButton/>
          </Dialog>
        </div>
      </section>
    )
  }
}

export default withStyles(styles)(RegistrationTempGoods)
