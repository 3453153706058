import React from 'react'
import {
  TopToolbar,
  CreateButton,
  Datagrid,
  DateField,
  List,
  TextField,
  SelectInput,
  Filter,
  SelectField
} from 'react-admin'
import Pagination from 'ra-ui-materialui/lib/list/Pagination'
import choices from './choices'

const Actions = ({basePath}) => (
  <TopToolbar>
    <CreateButton basePath={basePath} />
  </TopToolbar>
)

const ListFilter = (props) => (
  <Filter {...props}>
    <SelectInput source="type" emptyText="전체" choices={choices.type} alwaysOn />
  </Filter>
)

export default ({classes, ...props}) => {
  return (
    <List
      {...props}
      actions={<Actions />}
      filters={<ListFilter />}
      perPage={25}
      bulkActionButtons={false}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
    >
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false} />
        <SelectField source="type" sortable={false} choices={choices.type} />
        <TextField source="title" sortable={false} />
        <TextField source="name" sortable={false} />
        <DateField source="createdAt" locales="ko" showTime sortable={false} />
      </Datagrid>
    </List>
  )
}
