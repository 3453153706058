import {DELETE, GET_LIST, GET_MANY, GET_ONE, UPDATE,} from 'react-admin'
import {convertImagePath} from "./ImageHandler";

export function request(apiUrl, type, params) {
    let url = apiUrl + '/admin/reviews'

    let options = {}

    switch (type) {
        case GET_LIST:
            const {page, perPage} = params.pagination
            const {field, order} = params.sort

            options.params = {
                ...params.filter,
                sort: field,
                order: order,
                start: (page - 1) * perPage,
                perPage: perPage,
            }
            url += `/stocks/reviews`

            break
        case GET_MANY:
            if (params.ids.length === 1) url += `/${params.ids[0]}`
            break
        case GET_ONE:
            url += `/stocks/reviews/${params.id}`
            break
        case UPDATE:
            url += `/stocks/reviews/${params.id}`
            options.method = 'PUT'
            options.data = params.data
            break
        case DELETE:
            url += `/stocks/reviews/${params.id}`
            options.method = 'DELETE'
            break
        default:
            break
    }
    return {url, ...options}
}

export function response(res, type, params) {
    let ret = {}
    const {data} = res
    switch (type) {
        case GET_LIST:
            ret = data
            break
        case GET_ONE:
            ret = {data}
            break
        default:
            if (data) ret = {data}
            break
    }
    return ret
}
