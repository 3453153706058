import BookIcon from '@material-ui/icons/Book'
import BrandCreate from './BrandCreate'
import BrandEdit from './BrandEdit'
import BrandList from './BrandList'

export default {
  list: BrandList,
  create: BrandCreate,
  edit: BrandEdit,
  icon: BookIcon,
}
