import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {TopToolbar, Datagrid, DateField, Filter, List, TextField,} from 'react-admin'

const styles = theme => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }
})

const Actions = ({basePath,}) => (
  <TopToolbar>
  </TopToolbar>
)

const ListFilter = props => (
  <Filter {...props}>
  </Filter>
)

export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props}
          filters={<ListFilter/>}
          perPage={25}
          sort={{field: 'createdAt', order: 'DESC'}}
          actions={<Actions/>}
          bulkActionButtons={false}>
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false}/>
        <TextField source="productName" sortable={false}/>
        <TextField source="userName" sortable={false}/>
        <DateField source="expireAt" locales='ko' showTime/>
        <DateField source="createdAt" locales='ko' showTime/>
      </Datagrid>
    </List>
  )
})

