import React from 'react'
import {DateField, Edit, SimpleForm, NumberInput, TextInput,} from 'react-admin'
import {required} from '../../validators'
import EditActions from '../../custom/common/EditActions'
import EditToolbar from '../../custom/common/EditToolbar'

export default (props) => (
  <Edit {...props} actions={<EditActions/>}>
    <SimpleForm toolbar={<EditToolbar/>}>
      <TextInput disabled source='id'/>
      <TextInput source="keyword" validate={required}/>
      <NumberInput source="order" validate={required}/>
      <DateField source="createdAt" locales='ko' showTime/>
    </SimpleForm>
  </Edit>
)
