const status = [
  {id: 'registered', name: '등록'},
  {id: 'completed', name: '판매 완료'},
  {id: 'cancelled', name: '판매 취소'}
]

// {id: 'registered', name: '접수'},
// {id: 'waiting', name: '입고대기'},
// {id: 'warehousing', name: '입고'},
// {id: 'inspected', name: '검수완료'},
// {id: 'contactWaiting', name: '연락대기중'},
// {id: 'boxSent', name: '박스발송완료'},
// {id: 'adminCanceled', name: '거래취소'},
// {id: 'agreed', name: '고객동의'},
// {id: 'paid', name: '입금완료'},
// {id: 'cancelled', name: '취소'},
// enum('contactWaiting', 'boxSent', 'adminCanceled', 'registered', 'waiting', 'warehousing', 'inspected', 'agreed', 'paid', 'cancelled')
export {status}
