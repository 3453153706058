import BookIcon from '@material-ui/icons/Book'
import StockCreate from './StockCreate'
import StockEdit from './StockEdit'
import StockList from './StockList'
import StockShow from './StockShow'

export default {
  list: StockList ,
  create: StockCreate,
  edit: StockEdit,
  show: StockShow,
  icon: BookIcon,
}
