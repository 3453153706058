import BookIcon from '@material-ui/icons/Book';
import BlogList from  './BlogList';
import BlogCreate from './BlogCreate';
import BlogEdit from './BlogEdit';

export default {
    list : BlogList,
    create : BlogCreate,
    edit : BlogEdit,
    icon : BookIcon,
}
