import React from 'react'
import {Create, FormDataConsumer, SimpleForm, TextInput,} from 'react-admin'
import {required} from '../../validators'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'
import OptionSelect from './OptionSelect'

export default (({classes, ...props}) => {
  return (
    <Create {...props} actions={<CreateActions/>}>
      <SimpleForm defaultValue={{options: []}}
                  toolbar={<CreateToolbar/>}>
        <TextInput source="name" validate={required}/>
        <TextInput source="key" validate={required}/>
        <FormDataConsumer>{(props) => <OptionSelect {...props}/>}</FormDataConsumer>
      </SimpleForm>
    </Create>
  )
})

