import React, {Component} from 'react'
import classnames from 'classnames'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import {translate} from 'react-admin'
import compose from 'recompose/compose'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = {
  container: {width: '35em'},
  rightAlignedCell: {textAlign: 'right'},
  boldCell: {fontWeight: 'bold'},
}

class UserInspection extends Component {
  render() {
    const {classes, record, translate} = this.props
    const {inspections} = record
    const subPrice = inspections ? inspections.reduce((prev, curr) => {
      const option = curr.options.find(o => o.id === curr.userOptionId)
      if (option) prev += option.price
      return prev
    }, 0) : 0
    return (
      <Paper className={classes.container}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{translate('resources.stocks.fields.inspections.id')}</TableCell>
              <TableCell>{translate('resources.stocks.fields.inspections.name')}</TableCell>
              <TableCell>{translate('resources.stocks.fields.inspections.value')}</TableCell>
              <TableCell className={classes.rightAlignedCell}>
                {translate('resources.stocks.fields.inspections.price')}</TableCell>
            </TableRow>
          </TableHead>
          {inspections && <TableBody>
            {inspections.filter(i => i.userOptionId).map(
              item => {
                const options = item.options.find(o => o.id === item.userOptionId)
                return <TableRow key={item.id}>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{options ? options.name : ''}</TableCell>
                  <TableCell className={classes.rightAlignedCell}>{
                    options && options.price ? options.price.toLocaleString() : 0
                  }</TableCell>
                </TableRow>
              }
            )}
            <TableRow>
              <TableCell colSpan={2}/>
              <TableCell className={classes.rightAlignedCell}>
                {translate('resources.stocks.fields.basicPrice')}
              </TableCell>
              <TableCell className={classes.rightAlignedCell}>{
                record.basicPrice.toLocaleString()}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}/>
              <TableCell className={classes.rightAlignedCell}>
                예상  {translate('resources.stocks.fields.subPrice')}
              </TableCell>
              <TableCell className={classes.rightAlignedCell}>
                {inspections.reduce((prev, curr) => {
                  const option = curr.options.find(o => o.id === curr.userOptionId)
                  if (option) prev += option.price
                  return prev
                }, 0).toLocaleString()}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}/>
              <TableCell className={classnames(classes.boldCell, classes.rightAlignedCell)}>
                예상 {translate('resources.stocks.fields.price')}
              </TableCell>
              <TableCell className={classnames(classes.boldCell, classes.rightAlignedCell)}>{
                (record.basicPrice + subPrice).toLocaleString()}</TableCell>
            </TableRow>
          </TableBody>}
        </Table>
      </Paper>
    )
  }
}

const enhance = compose(
  translate,
  withStyles(styles),
)

export default enhance(UserInspection)
