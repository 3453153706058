import PostAddIcon from '@material-ui/icons/PostAdd'
import Create from './Create'
import Edit from './Edit'
import Show from './Show'
import List from './List'

export default {
  create: Create,
  list: List,
  edit: Edit,
  show: Show,
  icon: PostAddIcon
}
