import React from 'react'
import {
  TopToolbar,
  BooleanInput,
  Edit,
  FormDataConsumer,
  FormTab, ListButton,
  TabbedForm,
  TextInput,
  SelectInput,
  translate, NumberInput, GET_LIST,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin'
import {required} from '../../validators'
import withProductRelations from '../../hocs/withProductRelations'
import RelateCategories from './RelatedCategories'
import RelatedInspections from '../inspections/RelatedInspections'
import EditToolbar from '../../custom/common/EditToolbar'
import {dataProvider} from '../../App'

const EditActions = ({basePath, data}) => (
  <TopToolbar>
    <ListButton basePath={basePath}/>
  </TopToolbar>
)

class CategoryEdit extends React.Component {
  constructor(props){
    super(props)
    this.state={
      brandIdList : [],
      brandCategoryIdList : [],
      isRoot : true,
    }
    this.notRoot = this.notRoot.bind(this);
  }
  
  notRoot(value){
    this.setState({isRoot : value})
  }

  async componentDidMount(){
    const brandIdResult = await dataProvider(
      GET_LIST,
      'brands',
      {
        filter :{},
        pagination  : {page : 1, perPage: 1000},
        sort : {field:"id", order : "ASC"},
      }
    )
    this.setState({brandIdList : brandIdResult.data});

    const brandCategoryIdResult = await dataProvider(
      GET_LIST,
      'brandCategories',
      {
        filter : {},
        pagination :{page : 1, perPage : 1000},
        sort : {field :'id', order :'ASC'}
      }
    )
    this.setState({brandCategoryIdList : brandCategoryIdResult.data})
  }


  render() {
    const {categories, extras, inspections, translate, ...props} = this.props
    const {brandCategoryIdList, brandIdList, isRoot} = this.state

    return (
      <Edit {...props} actions={<EditActions/>}>
        <TabbedForm
          toolbar={<EditToolbar/>}
          initialValues={{}}>
          <FormTab label={translate('resources.categories.summary')}>
            <TextInput disabled source='id'/>
            <TextInput source="name" validate={required}/>
            <SelectInput source="brandCategoryId"
                         style={{paddingRight : '1em'}}
                         validate={required}
                         choices={brandCategoryIdList}/>
            <FormDataConsumer>
              {props => <SelectInput source="brandId"
                         label={translate('resources.categories.fields.brandId')}
                         style={{paddingRight : '1em'}}
                         validate={required}
                         choices={brandIdList.filter(data =>{
                           let hasCategory = false;
                           for(let i = 0; i< data.brandCategories.length; i++){
                             if(props.formData.brandCategoryId === data.brandCategories[i].id){
                               hasCategory = true;
                               break;
                             }
                           }
                           return hasCategory
                         })}/>}
            </FormDataConsumer>
            <FormDataConsumer>
              {props => <RelateCategories {...props} categories={categories}  where={"category"} notRoot={this.notRoot}/>}
            </FormDataConsumer>
            <FormDataConsumer>
              {props =>{
                return !props.formData.parentId && isRoot? 
                <ArrayInput 
                  source="selectItems"
                  label={translate('resources.categories.fields.selectItems')}>
                  <SimpleFormIterator>
                    <TextInput 
                      source="selectItem"
                      label={translate('resources.categories.fields.selectItem')}></TextInput>
                  </SimpleFormIterator>
                </ArrayInput> : null
              }}
            </FormDataConsumer>
            <BooleanInput source='onDeal' validate={required}/>
            <NumberInput source='order' validate={required}/>
          </FormTab>
          <FormTab label={translate('resources.inspections.name')}>
            <FormDataConsumer>
              {props => <RelatedInspections {...props} inspections={inspections}/>}
            </FormDataConsumer>
          </FormTab>
        </TabbedForm>
      </Edit>
    )
  }
}

export default translate(withProductRelations(CategoryEdit))
