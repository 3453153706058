import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  TopToolbar,
  CreateButton,
  Datagrid,
  DateField,
  Filter,
  List,
  NumberField,
  SearchInput,
  SelectField,
  TextField,
} from 'react-admin'

const styles = theme => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }
})
const Actions = ({basePath,}) => (
  <TopToolbar>
    <CreateButton basePath={basePath}/>
  </TopToolbar>
)

const ListFilter = props => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn/>
  </Filter>
)

export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props}
          actions={<Actions/>}
          filters={<ListFilter/>}
          perPage={25}
          bulkActionButtons={false}>
      <Datagrid rowClick="show">
        <TextField source="id"/>
        <TextField source="productName" sortable={false}/>
        <TextField source="productCode" sortable={false}/>
        <SelectField source="source" choices={[
          {id: 'it24', name: '아이티샵'},
          {id: 'joonggo', name: '중고나라'},
        ]}/>
        <SelectField source="type" choices={[
          {id: 'buy', name: '매입'},
          {id: 'sell', name: '판매'},
        ]}/>
        <NumberField source="price" sortable={false}/>
        <DateField source="date" locales='ko'/>
        <DateField source="createdAt" locales='ko' sortable={false}/>
      </Datagrid>
    </List>
  )
})

