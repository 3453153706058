import {
    CREATE,
    GET_LIST,
    GET_ONE,
    UPDATE,
} from 'react-admin'
import {uploadToS3, convertImagePath} from './ImageHandler'
import {dataProvider} from "../../App";

export async function request(apiUrl, type, params) {
    let url = apiUrl + '/admin/goods'
    let options = {}
    switch (type) {
        case CREATE:
        case UPDATE:
        case GET_LIST:
            const {page, perPage} = params.pagination
            const {field, order} = params.sort

            options.params = {
                ...params.filter,
                sort: field,
                order: order,
                start: (page - 1) * perPage,
                perPage: perPage,
            }
            url += `/deals/goods`

            break
        case GET_ONE:
            url += `/deals/goods/${params.id}`
            break
        case 'GET_DEALS_PURCHASE':
            options.params = {
                sort: params.sort,
                id: params.id,
                start: params.page * params.perPage,
                perPage: params.perPage,
            }

            url += `/deals/goods/${params.id}`
            break
        case 'GET_DEALS_SALE':
            options.params = {
                sort: params.sort,
                id: params.id,
                start: params.page * params.perPage,
                perPage: params.perPage,
            }

            url += `/deals/goods/${params.id}`
            break
        default:
            break
    }

    return {url, ...options}
}

export async function response(res, type, params) {
    let ret = {}
    const {data} = res

    switch (type) {
        case UPDATE:
            ret = params
            break
        case GET_ONE:
            ret = data
            break
        case GET_LIST:
            data.data.forEach(row => {
                row.id = row.week;
                if (!row['판매 금액']) row['판매 금액'] = 0
                if (!row['매입 금액']) row['매입 금액'] = 0
            })
            let result = findDiffFromLastTerm(data);
            ret = result;
            break
        case 'GET_DEALS_PURCHASE':
            data.data.id = data.id;

            for(let i = 0; i < data.data.purchase.length; i++) {
                let result = await dataProvider(
                    GET_ONE,
                    "stocks",
                    {
                        id: data.data.purchase[i].stockId
                    })
                if(result.data.productName) data.data.purchase[i].productName = result.data.productName
                if(result.data.seller) data.data.purchase[i].seller = result.data.seller.name
            }

            data.data.purchase.forEach(row => row.id = row.stockId)
            data.data.data = data.data.purchase
            data.data.total = data.total
            delete(data.data.purchase);

            ret = data.data;
            break;
        case 'GET_DEALS_SALE':
            data.data.id = data.id;

            for(let i = 0; i < data.data.sell.length; i++) {
                let result = await dataProvider(
                    GET_ONE,
                    "orders",
                    {
                        id: data.data.sell[i].orderId
                    })
                if(result) {
                    data.data.sell[i].buyerName = result.data.buyerName
                }
            }

            data.data.sell.forEach(row => row.id = row.orderId)
            data.data.data = data.data.sell
            data.data.total = data.total
            delete(data.data.sell);

            ret = data.data;
            break;
        default:
            if (data) ret = {data}
            break
    }
    return ret
}

const findDiffFromLastTerm = (data) => {
    for(let i = 0; i < data.data.length - 1; i++) {
        let total_1 = data.data[i]['판매 금액'] - data.data[i]['매입 금액']
        let total_2 = data.data[i+1]['판매 금액'] - data.data[i+1]['매입 금액']

        data.data[i].diffFromLastTerm = Math.ceil((total_1 - total_2) / Math.abs(total_2) * 100) + '%'
        data.data[i].total = total_1;

    }
    data.data[data.data.length-1].diffFromLastTerm = null;
    data.data[data.data.length-1].total = data.data[data.data.length-1]['판매 금액'] - data.data[data.data.length-1]['매입 금액'];

    return data;
}
