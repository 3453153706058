import {
  CREATE,
  DELETE,
  GET_LIST,
  GET_ONE,
  UPDATE
} from 'react-admin'
import {convertImagePath, convertSrc, uploadImage, uploadImageSrc, uploadToS3} from './ImageHandler'

export async function request(apiUrl, type, params) {
  let url = apiUrl + '/admin/events'
  let options = {}

  switch (type) {
    case CREATE:
    case UPDATE:
      if (type === UPDATE) {
        url += `/${params.id}`
        options.method = 'PUT'
      } else options.method = 'POST'

      if (!params.data.onSale) params.data.onSale = false
      if (params.data.blogs && params.data.blogs.length) {
        params.data.blogIds = params.data.blogs.map((blog) => blog.id)
        delete params.data.blogs
      }
      await uploadImageSrc(params.data, 'desktopImage', 'file')
      await uploadImageSrc(params.data, 'mobileImage', 'file')
      await uploadImageSrc(params.data, 'appImage', 'file')
      await uploadImageSrc(params.data, 'descriptionImage', 'file')
      options.data = params.data
      break
    case GET_ONE:
      url += `/${params.id}`
      break
    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break
    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res
  switch (type) {
    case GET_LIST:
      data.forEach(row => row.image = convertImagePath(row.mobileImage, 'small'))
      ret = {data, total: data.length}
      break
    case GET_ONE:
      data.desktopImage = convertSrc(data.desktopImage, 'large')
      data.mobileImage = convertSrc(data.mobileImage, 'large')
      data.appImage = convertSrc(data.appImage)
      data.descriptionImage = convertSrc(data.descriptionImage, 'large')
      ret = {data}
      break
    default:
      if (data) ret = {data}
      break
  }
  return ret
}
