import React from 'react'
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"

export default (props) => {
  const {delivery} = props
  return <Table>
    <TableHead>
      <TableRow>
        <TableCell>구분</TableCell>
        <TableCell>택배사</TableCell>
        <TableCell>송장번호</TableCell>
      </TableRow>
    </TableHead>
    <TableHead>
      <TableRow>
        <TableCell>판매자</TableCell>
        <TableCell>{delivery.sell.courierName ? delivery.sell.courierName : '미입력'}</TableCell>
        <TableCell>{delivery.sell.trackingNumber ? delivery.sell.trackingNumber : '미입력'}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>구매자</TableCell>
        <TableCell>{delivery.purchase.courierName ? delivery.purchase.courierName : '미입력'}</TableCell>
        <TableCell>{delivery.purchase.trackingNumber ? delivery.purchase.trackingNumber : '미입력'}</TableCell>
      </TableRow>
    </TableHead>
  </Table>
}
