import React from 'react'
import {
  TopToolbar, Datagrid, DateField, CreateButton,
  List, TextField, NumberField
} from 'react-admin'


const Actions = ({basePath,}) => (
  <TopToolbar>
    <CreateButton basePath={basePath}/>
  </TopToolbar>
)


export default (({classes, ...props}) => {
  return (
    <List {...props}
          actions={<Actions/>}
          sort={{field: 'createdAt', order: 'DESC'}}
          perPage={25}
          bulkActionButtons={false}>
      <Datagrid rowClick="show">
        <TextField source="id"/>
        <TextField source="name" sortable={false}/>
        <TextField source="rate" sortable={false}/>
        <TextField source="price" sortable={false}/>
        <DateField source="closeDate" locales='ko' showTime/>
        <DateField source="createdAt" locales='ko' showTime/>
      </Datagrid>
    </List>
  )
})

