import React from 'react'
import compose from 'recompose/compose'
import {withStyles} from '@material-ui/core/styles'
import {crudDelete, crudGetOne, translate} from 'ra-core'
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import TextField from '@material-ui/core/TextField'
import {connect} from 'react-redux'
import {couriers as choices} from '../../custom/delivery/choices'

const styles = theme => ({
  dialog: {
    width: '80%',
  },
  button: {}
});

class StockEditButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {isOpen: false, courierCode: '04', trackingNumber: '', isCourier: true}
    this.handleCancel = this.handleCancel.bind(this)
    this.handleDone = this.handleDone.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && prevProps.isOpen !== this.props.isOpen) {
      const {courierCode, trackingNumber} = this.props.record
      this.setState({isOpen: true, courierCode, trackingNumber: trackingNumber || ''})
    }
  }

  handleCancel() {
    this.setState({isOpen: false})
  }

  async handleDone(e) {
    e.stopPropagation()
    const {isCourier} = this.state
    let {courierCode, trackingNumber} = this.state
    if (!isCourier) {
      trackingNumber = null
    }
    await this.props.handleDone({courierCode, trackingNumber, isCourier})
  }

  handleChange(e) {
    const {name, value} = e.target
    this.setState({[name]: value})

    if (name === 'courierCode') {
      const courierCode = value
      console.log('isCourier : ', (courierCode !== '98' && courierCode !== '99'))
      this.setState({isCourier: (courierCode !== '98' && courierCode !== '99')})
    }
  }

  render() {
    const {courierCode, trackingNumber, isCourier} = this.state
    const {classes, translate, isOpen, handleCancel, handleDone} = this.props
    return (<Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      onEntering={this.handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={isOpen}
      classes={{paper: classes.dialog}}
    >
      <DialogTitle>배송 정보 입력</DialogTitle>
      <DialogContent>
        <RadioGroup
          ref={ref => {
            this.radioGroupRef = ref
          }}
          aria-label="Status"
          name="courierCode"
          value={courierCode}
          onChange={this.handleChange}
        >
          {choices.map(({id, name}) => (
            <FormControlLabel value={id} key={id} control={<Radio/>} label={name}/>
          ))}
        </RadioGroup>
        {
          isCourier &&
          <TextField label={translate('resources.orders.fields.trackingNumber')}
                     required={true}
                     style={{width: '100%'}}
                     margin="normal"
                     name='trackingNumber'
                     onChange={this.handleChange}
                     value={trackingNumber}/>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          취소
        </Button>
        <Button disabled={(isCourier && (!courierCode || !trackingNumber))}
                onClick={this.handleDone} color="primary">
          확인
        </Button>
      </DialogActions>
    </Dialog>)
  }
}

export default compose(
  connect(
    null,
    {
      dispatchCrudGetOne: crudGetOne,
      dispatchCrudDelete: crudDelete
    }
  ),
  translate,
  withStyles(styles)
)(StockEditButton)
