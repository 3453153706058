import BookIcon from '@material-ui/icons/Book'
import GoodsCreate from './GoodsCreate'
import GoodsEdit from './GoodsEdit'
import GoodsList from './GoodsList'
import GoodsShow from './GoodsShow'

export default {
  list: GoodsList,
  create: GoodsCreate,
  edit: GoodsEdit,
  show: GoodsShow,
  icon: BookIcon,
}
