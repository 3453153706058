import React from 'react'
import Chip from '@material-ui/core/Chip'

const styles = {
  main: {display: 'flex', flexWrap: 'wrap'},
  chip: {margin: 4},
}

const AccountField = ({record}) => {
  let types = []
  if (record.email) types.push('Email')
  if (record.facebook) types.push('Facebook')
  return (
    <span style={styles.main}>
        {
          types.map((type, i) => (
            <Chip
              key={i}
              style={styles.chip}
              label={type}
            />
          ))}
    </span>
  )
}

AccountField.defaultProps = {
  addLabel: true,
  source: 'accounts'
}
export default AccountField
