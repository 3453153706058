const couriers = [
  {id: '04', name: 'CJ대한통운'},
  {id: '01', name: '우체국택배'},
  {id: '24', name: 'CVSnet 편의점택배'},
  {id: '46', name: 'CU편의점택배'},
  {id: '98', name: '퀵'},
  {id: '99', name: '방문'}
]

export {couriers}
