import BookIcon from '@material-ui/icons/Book'
import EventCreate from './EventCreate'
import EventEdit from './EventEdit'
import EventList from './EventList'

export default {
  list: EventList,
  create: EventCreate,
  edit: EventEdit,
  icon: BookIcon,
}
