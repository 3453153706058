import {CREATE, UPDATE, GET_LIST, GET_ONE,DELETE} from 'react-admin'
import {convertImagePath, uploadToS3} from './ImageHandler'
import defaultImg from '../../defaultImg/thumbnail.png'

export async function request(apiUrl, type, params){
    let url = apiUrl + '/admin/brands/categories'
    let options={}
    let retData={}
    const data={...params.data}
    

    switch(type){
        case CREATE:
            options.method='POST'
            retData = await uploadToS3(data, 'image', 'image')
            options.data = retData.image?{
                name : retData.name,
                image : retData.image.src
            }:{
                name : retData.name,
            }
            break;
        case UPDATE:
            url+=`/${params.id}`
            options.method = 'PUT'
            retData= await uploadToS3(data, 'image', 'image',);

            let image=null;
            if(retData && retData.image && retData.image.src)
                image = retData.image.src
            else if(params.data.image)
                image = params.data.image.path;
            
            options.data = image ? { name : data.name, image : image, } : { name : data.name }
            break;
        case GET_LIST:
            options.method='GET'
            const {field, order}=params.sort;
            const {page, perPage}=params.pagination;
            options.params={
                sort : field,
                order : order,
                start : (page-1)*perPage,
                perPage : perPage,
            }
            break;
        case GET_ONE:
            url +=`/${params.id}`
            break;
        case DELETE:
            options.method='DELETE'
            url+=`/${params.id}`
            break;
        default:
            break;
    }
    return {url, ...options}
}

export function response(res, type, params){
    let ret={}
    const {data}=res;
    switch(type){
        case GET_LIST:
            data.list.forEach(row =>{
                row.image=convertImagePath(row.image, 'small');
                if(!row.image){ row.image = defaultImg; }
            })
            ret={data : data.list, total : data.total}
            break;
        case GET_ONE:
            const path = data.image
            data.image={path, src: convertImagePath(data.image, 'large')}
            if(!data.image.src){
                data.image={...data.image, src:defaultImg}
            }
            ret={data}
            break;
        default:
            if(data) ret = {data}
            break;
    }
    return ret;
}