import {
  CREATE,
  DELETE,
  GET_LIST,
  GET_ONE,
  UPDATE,
  GET_MANY
} from 'react-admin'
import {convertImagePath} from './ImageHandler'

export function request(apiUrl, type, params) {
  let url = apiUrl + '/admin/c2c/userSells'
  let options = {}

  switch (type) {
    case GET_LIST:
      const {page, perPage} = params.pagination
      const {field, order} = params.sort
      options.params = {
        ...params.filter,
        sort: field,
        order: order,
        start: (page - 1) * perPage,
        perPage: perPage,
      }
      break
    case CREATE:
      options.method = 'POST'
      options.data = params.data
      break
    case GET_MANY:
      if (params.ids.length === 1) url += `/${params.ids[0]}`
      break
    case GET_ONE:
      url += `/${params.id}`
      break
    case UPDATE:
      url += `/${params.id}`
      options.method = 'PUT'
      options.data = params.data
      break
    case DELETE:
      console.log(params)
      url += `/${params.id}`
      options.method = 'DELETE'
      break
    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res
  switch (type) {
    case GET_LIST:
      data.list.forEach((item) => {
        item.mainImage = convertImagePath(item.mainImage, 'small')
      })
      ret = {data: data.list, total: data.total}
      break
    case GET_ONE:
      data.mainImage = convertImagePath(data.mainImage, 'small')
      if (data.detailImages)
        data.detailImages = data.detailImages.map((item) => convertImagePath(item, 'small'))
      data.product.name = `[${data.product.code}]${data.product.title} ${data.product.name}`
      ret = {data}
      break
    case GET_MANY:
      if (params.ids.length === 1) {
        ret = {data: [data]}
      }
      else ret = {data}
      break
    default:
      if (data) ret = {data}
      break
  }
  return ret
}
