import BookIcon from '@material-ui/icons/Book'
import UserEdit from './UserEdit'
import UserShow from './UserShow'
import UserList from './UserList'

export default {
  list: UserList,
  edit: UserEdit,
  show: UserShow,
  icon: BookIcon,
}

