import React, { Component } from 'react';
import {GET_LIST, Responsive, withDataProvider} from 'react-admin';
import {dataProvider} from '../../App'
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import OrderCard from './OrderCard';
import StockCard from './StockCard';
import QuestionCard from './QuestionCard';

const styles = {
    flex: { display: 'flex',},
    areaTitle : {
        marginTop : '70px',
        marginBottom : '10px',
        fontSize : 40,
        fontWeight : 'bold',
    },
    card: { margin : '5px'},
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
};

class Dashboard extends Component {
    state = {
        perPageForStocks : 10,
        orderList : [],
        stocksRegistered : [],
        registeredTotal : 0,
        stocksWaiting :[],
        waitingTotal : 0,
        stocksInspected :[],
        inspectedTotal :0,
        questionList : [],
        questionTotal : 0,
    };

    componentDidMount = async () => {
        await this.fetchData();
    }

    componentDidUpdate = async (prevProps) => {
        // handle refresh
        if (this.props.version !== prevProps.version) {
            await this.fetchData();
        }
    }

    fetchData() {
        this.fetchOrders();
        this.fetchStocks('registered');
        this.fetchStocks('waiting');
        this.fetchStocks('inspected');
        this.fetchQuestions();
    }
    
    fetchOrders = async(startDate, endDate)=>{
        let filter;
        if(startDate && endDate){
            filter={
                status:'paid',
                startDate : startDate,
                endDate : endDate,
            }
        }else{
            filter={ status:'paid'}
        }

        let  orders  = await dataProvider(
            GET_LIST,
            'orders',
            {
                filter : filter,
                pagination: {page: 1, perPage: 1000},
                sort : {field :'createdAt', order : 'DESC' },
            }
        );
        
        let paidOrders=[];
        for(let i=0; i <orders.data.length; i++){
            let infoObj;
            if((orders.data[i].method === 'naverco' && orders.data[i].naverStatus === 'PAYED') || orders.data[i].method !== 'naverco'){
                infoObj={
                    id : orders.data[i].id,
                    createdAt : new Date(orders.data[i].createdAt),
                    buyerName : orders.data[i].buyerName,
                    goods : orders.data[i].name,
                    amount : orders.data[i].amount.toLocaleString(),
                    platform : orders.data[i].platform
                };
                paidOrders.push(infoObj);
            }
        }
        this.setState({orderList : paidOrders})
    }

    fetchStocks = async (filterType, page, startDate, endDate) =>{
        page=!page? 1 : page;

        let filter={};
        if(startDate && endDate){
            filter={
                status : filterType,
                startDate : startDate,
                endDate : endDate
            }
        }else{
            filter={status : filterType}
        }

        let stocks = await dataProvider(
            GET_LIST,
            'stocks',
            {
                filter : filter,
                pagination : {page : page, perPage: this.state.perPageForStocks},
                sort : {field : "createdAt", order : "DESC"}
            }
        )

        if(filterType === 'registered'){
            this.setState({
                stocksRegistered : stocks.data,
                registeredTotal : stocks.total,
            });
        }else if(filterType === 'waiting'){
            this.setState({
                stocksWaiting : stocks.data,
                waitingTotal : stocks.total,
            })
        }else if(filterType === 'inspected'){
            this.setState({
                stocksInspected : stocks.data,
                inspectedTotal : stocks.total,
            })
        }
    }

    fetchQuestions = async (filterType, page) => {
        page=!page? 1 : page;

        let questions = await  dataProvider(
            GET_LIST,
            'questions',
            {
                filter  : { answered: false},
                pagination : {page : page, perPage : this.state.perPageForStocks},
                sort : {field : "createdAt", order : "DESC"}
            }
        )
        this.setState({
            questionList : questions.data,
            questionTotal : questions.total,
        })
    }

    render() {
        const { perPageForStocks, orderList, stocksRegistered, registeredTotal, stocksWaiting, waitingTotal, stocksInspected, inspectedTotal, questionList, questionTotal} = this.state;

        return (
            <Responsive
                medium={
                    <div style={styles.flex}>
                        <div style={styles.leftCol}>
                            
                            <div style={styles.areaTitle}>신규 판매 및 신규 매입</div>
                            <div style={styles.flex}>
                                <div style={styles.card}>
                                    <OrderCard title={'신규 판매 주문'} list={orderList} fetchOrders={this.fetchOrders}></OrderCard>
                                </div>
                                <div style={styles.card}>
                                    <StockCard 
                                        type={'registered'} title={'신규 매입 주문'} stocks={stocksRegistered} total={registeredTotal}
                                        perPage={perPageForStocks} fetchStocks={this.fetchStocks}></StockCard>
                                </div>
                                <div style={styles.card}>
                                    <StockCard 
                                        type={'waiting'} title={'매입 입고 대기'} stocks={stocksWaiting} total={waitingTotal}
                                        perPage={perPageForStocks} fetchStocks={this.fetchStocks}></StockCard>
                                </div>
                                <div style={styles.card}>
                                    <StockCard 
                                        type={'inspected'} title={'매입 입금 대기'} stocks={stocksInspected} total={inspectedTotal}
                                        perPage={perPageForStocks} fetchStocks={this.fetchStocks}></StockCard>
                                </div>
                            </div>

                            <div style={styles.areaTitle}>사용자 문의</div>
                            <div style={styles.flex}>
                                <div style={styles.card}>
                                    <QuestionCard 
                                        title={'사용자 문의'} questions={questionList} total={questionTotal}
                                        perPage={perPageForStocks} fetchQuestions={this.fetchQuestions}/>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            />
        );
    }
}

const mapStateToProps = state => ({
    version: state.admin.ui.viewVersion,
});

export default compose(
    connect(mapStateToProps),
    withDataProvider
)(Dashboard);