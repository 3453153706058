import React from 'react'
import Chip from '@material-ui/core/Chip'

const styles = {
  main: {display: 'flex', flexWrap: 'wrap'},
  chip: {margin: 4},
};

const OptionField = ({record}) => {
  return (
    <span style={styles.main}>
        {record.options &&
        record.options.map((option, i) => (
          <Chip
            key={i}
            style={styles.chip}
            label={option.name}
          />
        ))}
    </span>
  )
}

OptionField.defaultProps = {
  addLabel: true,
  source: 'options'
}
export default OptionField
