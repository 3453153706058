import Icon from '@material-ui/icons/Redeem'
import CouponEdit from './CouponEdit'
import CouponList from './CouponList'
import CouponCreate from './CouponCreate'
import CouponShow from './CouponShow'

export default {
  create: CouponCreate,
  list: CouponList,
  edit: CouponEdit,
  show: CouponShow,
  icon: Icon,
}
