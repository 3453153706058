import React from 'react'
import {
  SimpleForm,
  Create,
  TextInput,
  translate,
} from 'react-admin'
import {required} from '../../validators'
import withProductRelations from '../../hocs/withProductRelations'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'

class ShopCreate extends React.Component {

  render() {
    const {shopCategories, translate, ...props} = this.props
    return (
      <Create {...props} actions={<CreateActions/>}>
        <SimpleForm toolbar={<CreateToolbar/>}
                    defaultValue={{parentIds: []}}>
          <TextInput source="name" validate={required}/>
          <TextInput source="accountId"/>
          <TextInput source="password"/>
          <TextInput source="url" />
          <TextInput source="memo"/>
        </SimpleForm>
      </Create>
    )
  }
}

export default translate(withProductRelations(ShopCreate))
