import React from 'react'
import {
  TopToolbar,
  DateField,
  ListButton,
  SelectField,
  Show,
  SimpleShowLayout,
  TextField,
  TabbedShowLayout,
  Tab,
  FileField,
  ReferenceManyField,
  Datagrid,
  Pagination,
  NumberField,
  useShowController,
  BooleanField,
  ArrayField
} from 'react-admin'
import {status} from './choices'
import {status as receiptStatus} from '../businessReceipts/choices'
import {status as stockStatus} from '../stocks/choices'
import BusinessStatusButton from './BusinessStatusButton'
import RequestReceiptButton from './RequestReceiptButton'
import PhoneField from '../../custom/common/PhoneField'

const ShowActions = ({basePath, data, resource}) => {
  return (
    <TopToolbar>
      <BusinessStatusButton basePath={basePath} record={data} resource={resource}/>
      <RequestReceiptButton basePath={basePath} record={data}/>
      <ListButton basePath={basePath}/>
    </TopToolbar>
  )
}

export default (props) => {
  const {record} = useShowController(props)
  return <Show {...props} actions={<ShowActions/>}>
    <SimpleShowLayout>
      <TabbedShowLayout>
        <Tab label="기본 정보">
          <TextField source="id"/>
          <SelectField source="status" choices={status}/>
          <TextField source="name"/>
          <TextField source="username"/>
          <TextField source="phone"/>
          <TextField source="email"/>
          <DateField source="createdAt" locales="ko" showTime/>
        </Tab>
        <Tab label="사업자 정보">
          <FileField source="licenseImage" title={"download"}/>
          <TextField source="licenseNumber"/>
        </Tab>
        <Tab label="은행 정보">
          <TextField source="bank.name"/>
          <TextField source="bank.account"/>
          <TextField source="bank.holder"/>
        </Tab>
        <Tab label="매각 내역">
          <ReferenceManyField addLabel={false}
                              reference="stocks"
                              link={''}
                              pagination={<Pagination/>}
                              sort={{field: 'createdAt', order: 'DESC'}}
                              filter={{businessId: record ? record.id : null}}
                              target="id">
            <Datagrid rowClick={'show'}>
              <TextField source="id" sortable={false}/>
              <TextField source="product.name" sortable={false}/>
              <TextField source="serial" sortable={false}/>
              <TextField source="seller.name" sortable={false}/>
              <PhoneField source="seller.phone" sortable={false}/>
              <NumberField source="price" sortable={false}/>
              <SelectField source="status" choices={stockStatus} sortable={false}/>
              <TextField source="inspector.name" sortable={false}/>
              <BooleanField source="receiptIssued" />
              <DateField source="createdAt" locales="ko"/>
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="현금 영수증 내역">
          <ReferenceManyField addLabel={false}
                              reference="businessReceipts"
                              link={''}
                              pagination={<Pagination/>}
                              sort={{field: 'id', order: 'DESC'}}
                              filter={{businessId: record ? record.id : null}}
                              target="id">
            <Datagrid rowClick={'show'}>
              <TextField source="id"/>
              <SelectField source="status" choices={receiptStatus} sortable={false}/>
              <TextField source="business.name" sortable={false}/>
              <TextField source="business.phone" sortable={false}/>
              <TextField source="business.email" sortable={false}/>
              <NumberField source="price" sortable={false}/>
              <DateField source="createdAt" locales="ko" showTime sortable={false}/>
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="현금 영수증 가능 내역">
          <NumberField source="receipt.totalPrice" sortable={false}/>
          <NumberField source="receipt.price" sortable={false}/>
          <ArrayField {...props} source="receipt.stocks" addLabel={false}>
            <Datagrid>
              <TextField label="resources.stocks.fields.id" source="id" sortable={false}/>
              <TextField label="resources.stocks.fields.product.name" source="product.name" sortable={false}/>
              <NumberField label="resources.stocks.fields.price" source="price" sortable={false}/>
              <SelectField label="resources.stocks.fields.status" source="status" choices={stockStatus} sortable={false}/>
              <BooleanField label="resources.stocks.fields.receiptIssued" source="receiptIssued" />
              <DateField label="resources.stocks.fields.createdAt" source="createdAt" locales="ko"/>
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </SimpleShowLayout>
  </Show>
}
