import React from 'react'
import {
  SelectInput,
  BooleanInput,
  Create,
  FormDataConsumer,
  FormTab,
  NumberInput,
  TabbedForm,
  TextInput,
  translate,
  GET_LIST,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin'

import {required} from '../../validators'
import withProductRelations from '../../hocs/withProductRelations'
import RelateCategories from './RelatedCategories'
import RelatedInspections from '../inspections/RelatedInspections'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'
import {dataProvider} from '../../App'


class CategoryCreate extends React.Component {
  constructor(props){
    super(props)
    this.state={
        brandIdList : [],
        brandCategroyIdList : [],
        selectedBrandCatId : null,
        isRoot : true,
    }
    this.notRoot = this.notRoot.bind(this);
  }

  notRoot(value){
    this.setState({isRoot : value})
  }

  async componentDidMount(){
    const brandIdResult = await dataProvider(
      GET_LIST, 
      'brands',
      {
        filter :{},
        pagination : {page : 1, perPage: 1000},
        sort:{field : "id", order : "ASC"},
      }
    )
    this.setState({brandIdList :brandIdResult.data});

    const brandCategoryIdResult = await dataProvider(
      GET_LIST,
      'brandCategories',
      {
        filter:{},
        pagination:{page :1, perPage : 1000},
        sort:{field :'id', order:'ASC'},
      }
    )
    this.setState({brandCategroyIdList : brandCategoryIdResult.data});
  }

  render() {
    const {categories, inspections, translate, ...props} = this.props
    const {brandCategroyIdList, brandIdList, selectedBrandCatId, isRoot} = this.state;
    const filteredBrandList = brandIdList.filter((object,index)=>{
      let hasCategory = false;
      for(let i=0; i<object.brandCategories.length; i++){
        if(selectedBrandCatId === object.brandCategories[i].id){
          hasCategory =true;
          break;
        }
      }
      return hasCategory
    })

    return (
      <Create {...props} actions={<CreateActions/>}>
        <TabbedForm
          toolbar={<CreateToolbar/>}
          initialValues={{parentIds: [], extras: [], inspections: [], onDeal: true}}>
          <FormTab label={translate('resources.categories.summary')}>
            <TextInput source="name" validate={required}/>
            <SelectInput source="brandCategoryId"
                         style={{paddingRight : '1em'}}
                         onChange={e =>{
                            this.setState({selectedBrandCatId : e.target.value});
                         }}
                         validate={required}
                         choices={brandCategroyIdList}/>
            <SelectInput source="brandId"
                        style={{paddingRight : '1em'}}
                        validate={required}
                        choices={selectedBrandCatId? filteredBrandList : brandIdList}/> 
            <FormDataConsumer>
              {props => <RelateCategories {...props} categories={categories} where={"category"} notRoot={this.notRoot}/>}
            </FormDataConsumer>
            {
              isRoot ? 
                (<ArrayInput 
                  source="selectItems"
                  label={translate('resources.categories.fields.selectItem')}>
                  <SimpleFormIterator>
                    <TextInput 
                      source="selectItem"
                      label={translate('resources.categories.fields.selectItem')}></TextInput>
                  </SimpleFormIterator>
                </ArrayInput>) : null
            }
            <BooleanInput source='onDeal' validate={required}/>
            <NumberInput source='order' validate={required}/>
          </FormTab>
          <FormTab label={translate('resources.inspections.name')}>
            <FormDataConsumer>
              {props => <RelatedInspections {...props} inspections={inspections}/>}
            </FormDataConsumer>
          </FormTab>
        </TabbedForm>
      </Create>
    )
  }
}

export default translate(withProductRelations(CategoryCreate))
