import React from 'react'
import {GET_LIST, SelectInput, translate,} from 'react-admin'
import {dataProvider} from '../../App'

class RelatedCategories extends React.Component {
  constructor(props) {
    super(props)
    this.state={
      initialChange : true,
      categoryIndex : null,
    }
    this.handleChange = this.handleChange.bind(this)
  }

  async handleChange(index, value) {
    const {formData} = this.props;

    this.setState({
      categoryIndex : index,
      initialChange : index === 0 && value === null ? false : true,
    });

    if (index < formData.parentIds.length - 1 && formData.parentIds[index] !== value) {
      formData.parentIds.splice(index,)
    }

    if (!formData.id && formData.parentIds[index] !== value && value !== null) {
      const data = await dataProvider(GET_LIST, 'inspections', {categoryId: value})
      this.props.formData.inspections = data.sort((a, b) => a.order - b.order)
    }

    // 현재 구현 불가능
    // if(index === 0 && value === null){
    //   this.props.formData.inspections.splice(0, );
    // }
  }

  componentDidUpdate(prevProps){
    const {formData, where} = this.props;

    if(formData.brandId !== prevProps.formData.brandId || formData.brandCategoryId !== prevProps.formData.brandCategoryId){
      if(where === "product"){
        if(!prevProps.formData.brandId){
          this.setState({initialChange : true });
        }else{
          this.setState({initialChange : false }, ()=>{formData.parentIds=[]});
        }
      }else{
        if(!prevProps.formData.brandId || !prevProps.formData.brandCategoryId){
          this.setState({initialChange : true});
        }else{
          this.setState({initialChange: false}, ()=>{formData.parentIds = []});
        }
      }
    }
  }

  render() {
    const {translate, categories, formData, where, notRoot} = this.props;
    const {initialChange, categoryIndex} = this.state;

    if (!formData || !formData.parentIds || !categories) return '';

    if(categoryIndex !== null){
      formData.parentIds.splice(categoryIndex+1, );
    }

    let choiceForFirst = categories.filter(item => {               
      if(where === "product"){
        return item.parentId === null && item.brandId === formData.brandId
      }
      if(formData.brandCategoryId && formData.brandId){
       return item.parentId === null && item.id !== formData.id && formData.brandCategoryId === item.brandCategoryId && formData.brandId === item.brandId
      }else{
       return item.parentId === null && item.id !== formData.id
      }                        
    })

    // 현재 parentId에 null 입력이 불가능 하므로 구현할 수 없음
    // if(where === "category" && formData.parentIds[0]){
    //   choiceForFirst.push({id : null, name : "선택취소"})
    // }

    return (
      <React.Fragment>
        <SelectInput source="parentIds[0]" optionText="name"
                     label={translate('resources.categories.fields.parentIds', {index: 1})}
                     style={{paddingRight: '1em'}}
                     onChange={async (e) => {
                       await this.handleChange(0, e.target.value)
                       if(where === "category" ){
                         notRoot(false);
                        //  현재 구현 불가능합니다.
                        //  if(e.target.value === null){
                        //    notRoot(true);
                        //  }else{
                        //    notRoot(false);
                        //  }
                       }
                     }}
                     choices={ choiceForFirst }/>
        {initialChange ? formData.parentIds.reduce((ret, id, i) => {
          const children = categories.filter(item => item.parentId === id && item.id !== formData.id)
          if (children.length > 0) {
            ret.push(<SelectInput source={`parentIds[${i + 1}]`} 
                                  optionText="name" key={id}
                                  label={translate('resources.categories.fields.parentIds', {index: i + 2})}
                                  style={{paddingRight: '1em'}}
                                  onChange={async (e) => {
                                    await this.handleChange(i + 1, e.target.value)
                                  }}
                                  choices={categories.filter(item => item.parentId === id&& item.id !== formData.id)}/>)
          }
          return ret;
        }, []) : null
        }
      </React.Fragment>
    )
  }

}

export default translate(RelatedCategories)