import BookIcon from '@material-ui/icons/Book'
import ShopCreate from './ShopCreate'
import ShopEdit from './ShopEdit'
import ShopList from './ShopList'

export default {
  list: ShopList,
  create: ShopCreate,
  edit: ShopEdit,
  icon: BookIcon,
}
