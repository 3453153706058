import React, {Component} from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import {translate} from 'react-admin'
import compose from 'recompose/compose'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = {
  container: {width: '32em', marginBottom: 20},
  rightAlignedCell: {textAlign: 'right'},
  boldCell: {fontWeight: 'bold'},
}

class UserInspection extends Component {
  render() {
    const {classes, record, translate} = this.props
    return (
      <Paper className={classes.container}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>수령자 정보</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{translate('resources.orders.fields.receiverName')}</TableCell>
              <TableCell>{record.receiverName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{translate('resources.orders.fields.receiverTel')}</TableCell>
              <TableCell>
                {record.receiverTel &&
                record.receiverTel.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{translate('resources.orders.fields.receiverAddress')}</TableCell>
              <TableCell>{record.address}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{translate('resources.orders.fields.zipCode')}</TableCell>
              <TableCell>{record.zipCode}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    )
  }
}

const enhance = compose(
  translate,
  withStyles(styles),
)

export default enhance(UserInspection)
