import React from 'react'
import {
  Datagrid, DateField,
  List, TextField
} from 'react-admin'

export default (({classes, ...props}) => {
  return (
    <List {...props}
          sort={{field: 'createdAt', order: 'DESC'}}
          filterDefaultValues={{type: 'direct'}}
          perPage={25}
          bulkActionButtons={false}>
      <Datagrid rowClick="show">
        <TextField source="id"/>
        <TextField source="code"/>
        <TextField source="name" sortable={false}/>
        <TextField source="rate" sortable={false}/>
        <TextField source="price" sortable={false}/>
        <DateField source="closeDate" locales='ko'/>
        <DateField source="createdAt" locales='ko' showTime/>
      </Datagrid>
    </List>
  )
})

