import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  TopToolbar,
  Datagrid,
  DateField,
  Filter,
  List,
  NumberField,
  SearchInput,
  SelectField, SelectInput,
  TextField,
} from 'react-admin'
import PhoneField from '../../custom/common/PhoneField'
import UploadNaverOrders from './UploadNaverOrders'

const styles = theme => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  search: {
    height:'3rem',
    padding: '0 0',
    margin: 0
  },
  select: {
    height:'3rem',
    padding: '0 0',
    margin: 0
  }
})

const Actions = props => (
  <TopToolbar>
    <UploadNaverOrders {...props} />
  </TopToolbar>
)

const statusChoices = [
  {id: 'ready', name: '가상계좌 발급'},
  {id: 'paid', name: '결제완료'},
  {id: 'sent', name: '배송출발'},
  {id: 'delivered', name: '배송완료'},
  {id: 'cancelled', name: '취소'},
]

const naverStatusChoices = [
  {id: 'PAYMENT_WAITING', name: '입금대기'},
  {id: 'PAYED', name: '결제완료'},
  {id: 'DELIVERING', name: '배송출발'},
  {id: 'DELIVERED', name: '배송완료'},
  {id: 'PURCHASE_DECIDED', name: '구매확정'},
  {id: 'EXCHANGED', name: '교환완료'},
  {id: 'CANCELED', name: '취소완료'},
  {id: 'RETURNED', name: '반품완료'},
  {id: 'CANCELED_BY_NOPAYMENT', name: '미입금 취소'},
  {id: null, name: '-'},
  {id: undefined, name: '-'}
]

const naverClaimTypeChoices = [
  {id: 'CANCEL', name: '취소'},
  {id: 'RETURN', name: '반품'},
  {id: 'EXCHANGE', name: '교환'},
  {id: 'PURCHASE_DECISION_HOLDBACK', name: '구매 확정 보류'},
  {id: 'ADMIN_CANCEL', name: '직권 취소'},
  {id: null, name: '-'},
  {id: undefined, name: '-'}
]

const naverClaimStatusChoices = [
  {id: 'CANCEL_REQUEST', name: '취소 요청'},
  {id: 'CANCELING', name: '취소 처리 중'},
  {id: 'CANCEL_DONE', name: '취소 처리 완료'},
  {id: 'CANCEL_REJECT', name: '취소 철회'},
  {id: 'RETURN_REQUEST', name: '반품 요청'},
  {id: 'COLLECTING', name: '수거 처리 중'},
  {id: 'COLLECT_DONE', name: '수거 완료'},
  {id: 'RETURN_DONE', name: '반품 완료'},
  {id: 'RETURN_REJECT', name: '반품 철회'},
  {id: 'EXCHANGE_REQUEST', name: '교환 요청'},
  {id: 'COLLECTING', name: '수거 처리 중'},
  {id: 'COLLECT_DONE', name: '수거 완료(교환)'},
  {id: 'EXCHANGE_REDELIVERING', name: '교환 재배송 중'},
  {id: 'EXCHANGE_DONE', name: '교환 완료'},
  {id: 'EXCHANGE_REJECT', name: '교환 거부'},
  {id: 'PURCHASE_DECISION_HOLDBACK', name: '구매 확정 보류'},
  {id: 'PURCHASE_DECISION_HOLDBACK_REDELIVERING', name: '구매 확정 보류 재배송 중'},
  {id: 'PURCHASE_DECISION_REQUEST', name: '구매 확정 요청'},
  {id: 'PURCHASE_DECISION_HOLDBACK_RELEASE', name: '구매 확정 보류 해제'},
  {id: 'ADMIN_CANCELING', name: '직권 취소 중'},
  {id: 'ADMIN_CANCEL_DONE', name: '직권 취소 완료'},
  {id: null, name: '-'},
  {id: undefined, name: '-'}
]

const ListFilter = props => (
  <Filter {...props}>
    <SearchInput placeholder={'주문번호/주문자/휴대전화'} className={props.classes.search} source="search" alwaysOn/>
    <SelectInput source="status" className={props.classes.select} choices={statusChoices} alwaysOn/>
  </Filter>
)

export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props}
          actions={<Actions/>}
          perPage={25}
          filters={<ListFilter classes={classes}/>}
          sort={{field: 'createdAt', order: 'DESC'}}
          bulkActionButtons={false}>
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false}/>
        <TextField source="buyerName" sortable={false}/>
        <PhoneField source="buyerPhone" sortable={false}/>
        <NumberField source="quantity" sortable={false}/>
        <NumberField source="amount" sortable={false}/>
        <SelectField source="method" choices={[
          {id: 'card', name: '카드'},
          {id: 'vbank', name: '가상계좌'},
          {id: 'naverco', name: '네이버페이'},
        ]}/>
        <SelectField source="status" choices={statusChoices}/>
        <SelectField source="naverStatus" choices={naverStatusChoices}/>
        <SelectField source="naverClaimType" choices={naverClaimTypeChoices}/>
        <SelectField source="naverClaimStatus" choices={naverClaimStatusChoices}/>
        <SelectField source="platform" choices={[
          {id: 'web', name: '웹'},
          {id: 'ios', name: 'IOS'},
          {id: 'android', name: '안드로이드'},
          {id: 'naver', name: '스마트스토어'},
        ]}/>
        <DateField source="createdAt" locales='ko'/>
      </Datagrid>
    </List>
  )
})

