import React from 'react'
import {ArrayInput, SimpleFormIterator, translate,} from 'react-admin'
import OrderedSelectInput from '../../custom/common/OrderedSelectInput'

class RelatedInspections extends React.Component {
  render() {
    const {translate, formData, inspections, ...rest} = this.props
    if (!formData) return ''
    return (
      <ArrayInput source="inspections"
                  label={translate('resources.products.inspections')}>
        <SimpleFormIterator>
          <OrderedSelectInput nameSource="inspections"
                              names={inspections}
                              orderSource="order"
                              formData={formData}
                              {...rest}/>
        </SimpleFormIterator>
      </ArrayInput>
    )
  }
}

export default translate(RelatedInspections)
