import BookIcon from '@material-ui/icons/Book';
import NoticeList from  './NoticeList';
import NoticeCreate from './NoticeCreate';
import NoticeEdit from './NoticeEdit';

export default {
    list : NoticeList,
    create : NoticeCreate,
    edit : NoticeEdit,
    icon : BookIcon,
}
