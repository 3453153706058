import BookIcon from '@material-ui/icons/Book'
import AdminCreate from './AdminCreate'
import AdminEdit from './AdminEdit'
import AdminShow from './AdminShow'
import AdminList from './AdminList'

export default (permissions) => {
  return {
    list: AdminList,
    create: AdminCreate,
    edit: permissions === 'admin' ? AdminEdit : null,
    show: AdminShow,
    icon: BookIcon,
  }
}
