import XLSX from 'xlsx'
import fileSaver from 'file-saver'

async function getFile (file) {
  try {
    return await readFileAsync(file)
  } catch (err) {
    throw (err)
  }
}

function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      const data = reader.result
      resolve(XLSX.read(data, {type: 'array'}));
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  })
}

async function getSheetData
  (fileData, sheetName, options = {header: 1, range: 1, blankrows: true, defval: null}) {

  try {
    return await XLSX.utils.sheet_to_json(fileData.Sheets[sheetName], options)
  } catch (err) {
    throw (err)
  }
}

async function write({data, fileName}) {
  let workbook = XLSX.utils.book_new()
  let worksheet = XLSX.utils.json_to_sheet(data, {skipHeader: true, raw: true})
  workbook.SheetNames.push('sheet1')
  workbook.Sheets['sheet1'] = worksheet
  let wopts = {bookType: 'xlsx', bookSST: false, type: 'binary'}
  let wbout = XLSX.write(workbook, wopts)
  fileSaver.saveAs(new Blob([s2ab(wbout)],
    {type: 'application/octet-stream'}), `${fileName}.xlsx`)

  function s2ab(s) {
    let buf = new ArrayBuffer(s.length)
    let view = new Uint8Array(buf)
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF
    return buf
  }
}

function date (excelDate) {
  try {
    const createdAt = new Date((excelDate - (25567 + 2))*86400*1000)
    createdAt.setHours(createdAt.getHours() - 9)
    return createdAt.toISOString()
  } catch (err) {
    throw (err)
  }
}


export default {getFile, getSheetData, write, date}
