import React from 'react'
import {
  TopToolbar,
  ListButton,
  SimpleShowLayout,
  TextField,
  SelectField,
  NumberField,
  DateField,
  TabbedShowLayout,
  Tab,
  ReferenceField,
  RichTextField,
  ShowController,
  CardContentInner
} from 'react-admin'
import * as choices from './choices'
import ImageClickField from '../../custom/common/ImageClickField'
import UserSellOrderStatusLog from './UserSellOrderStatus'
import UserInspections from '../../custom/inspection/UserInspections'
import UserSellOrderDelivery from './UserSellOrderDelivery'
import EnrollDeliveryInfo from '../../custom/common/EnrollDeliveryInfo'
import UpdateStatusButton from './UpdateStatusButton'
import UpdateMemoButton from './UpdateMemoButton'
import Card from '@material-ui/core/Card'
import UpdateAddressButton from './UpdateAddressButton'

const ShowActions = (props) => {
  const {basePath, data, resource} = props
  return (
    <TopToolbar>
      <ListButton basePath={basePath}/>
      {
        data &&
        <div>
          <UpdateStatusButton
            id={data.id}
            basePath={basePath}
            resource={resource}
            status={data.status}
            choices={choices.showStatus}
          />
          <UpdateMemoButton id={data.id} basePath={basePath} resource={resource} memo={data.adminMemo} />
          <UpdateAddressButton id={data.id} basePath={basePath} resource={resource} {...data} />
        </div>
      }
      <EnrollDeliveryInfo basePath={basePath} data={{...data, divisions: choices.divisions}} resource={resource} />
    </TopToolbar>
  )
}

export default (props) => {
  return <ShowController {...props}>
    {({isLoading, defaultTitle, ...controllerProps}) => {
      const record = controllerProps.record
      if (!record) return ''
      return <Card>
        <CardContentInner>
          <ShowActions basePath={controllerProps.basePath} resource={controllerProps.resource} data={record}/>
        </CardContentInner>
        <SimpleShowLayout  {...controllerProps}>
          <TabbedShowLayout>
            <Tab label="기본 정보">
              <TextField source="id"/>
              <SelectField source="status" choices={choices.showStatus}/>
              <ReferenceField source="user.id" reference="users" link="show" allowEmpty>
                <TextField source="id"/>
              </ReferenceField>
              <TextField source="user.name"/>
              <ReferenceField source="userSell.user.id" reference="users" link="show" allowEmpty>
                <TextField source="id"/>
              </ReferenceField>
              <TextField source="userSell.user.name"/>
              <ReferenceField source="userSell.id" reference="userSells" link="show" allowEmpty>
                <TextField source="id"/>
              </ReferenceField>
              <TextField source="userSell.title"/>
              <ImageClickField addLabel={true} source="userSell.mainImage"/>
              <NumberField source="price"/>
              <TextField source="inspectionPrice"/>
              <NumberField source="deliveryPrice"/>
              <NumberField source="amount"/>
              <DateField source="createdAt" locales="ko" showTime/>
            </Tab>
            <Tab label="상태 기록">
              <UserSellOrderStatusLog {...props} choices={choices} />
            </Tab>
            <Tab label="검수 항목">
              <UserInspections inspections={record?.userSell.product?.inspections} userInspections={record?.userSell.inspections}/>
            </Tab>
            <Tab label="구매자 배송지">
              <TextField source="buyerName"/>
              <TextField source="buyerPhone"/>
              <TextField source="buyerEmail"/>
              <TextField source="receiverName"/>
              <TextField source="receiverPhone"/>
              <TextField source="address"/>
              <TextField source="zipCode"/>
              <TextField source="memo"/>
            </Tab>
            <Tab label="구매/판매자 배송 정보">
              <UserSellOrderDelivery delivery={record?.delivery} />
            </Tab>
            <Tab label="관리자 메모">
              <RichTextField source="adminMemo" />
            </Tab>
          </TabbedShowLayout>
        </SimpleShowLayout>
      </Card>
    }}
  </ShowController>
}
