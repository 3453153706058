import React, {Fragment} from 'react'

import {
  ArrayInput,
  BooleanInput,
  CheckboxGroupInput, DateInput,
  Edit,
  FormDataConsumer,
  ImageField,
  ImageInput,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SelectArrayInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  NumberField,
  TextInput,
  translate,
} from 'react-admin'
import MUITextField from '@material-ui/core/TextField'
import {required} from '../../validators'
import EditActions from '../../custom/common/EditActions'
import EditToolbar from '../../custom/common/EditToolbar'
import ProductPreviewButton from '../products/ProductPreviewButton'
import SelectImageInput from '../../custom/common/SelectImageInput'
import withStyles from '@material-ui/core/styles/withStyles'

const ProductField = (props) => {
  return (
    <Fragment>
      <ReferenceField {...props} style={{display: 'inline'}}>
        <TextField source="name"/>
      </ReferenceField>
      <ProductPreviewButton id={props.record.productId}/>
    </Fragment>
  )
}

const QuantityField = ({classes, label, value, formData}) => {
  return <MUITextField label={label} margin="normal"
                       disabled value={value}/>
}

const inlineStyles = {
  root: {
    '& .ra-input': {
      display: 'inline-block',
    },
    '& section': {
      display: 'inline-flex',
    },
  },
}

const inlineNumberStyles = {
  root: {
    width: 100
  },
}

const inlineDateInputStyles = {
  root: {
    display: 'inline-block',
    marginRight: 20
  }
}

const InlineDisabledInput = withStyles(inlineNumberStyles)(TextInput)
const InlineNumberInput = withStyles(inlineNumberStyles)(NumberInput)
const InlineArrayInput = withStyles(inlineStyles)(ArrayInput)
const InlineDateInput = withStyles(inlineDateInputStyles)(DateInput)

let marginCheck = false;
let priceCheck = false;

class GoodsEdit extends React.Component {
  render() {
    const {translate, ...props} = this.props

    return (
      <Edit {...props} actions={<EditActions/>}>
        <SimpleForm
          toolbar={<EditToolbar/>}
          defaultValue={{}}>

          <ProductField source="productId" reference="products" linkType={'show'}
                        addLabel={true}/>

          <ReferenceField source="stockId" reference="stocks" allowEmpty linkType={'show'}>
            <TextField source="id"/>
          </ReferenceField>

          <TextField source='stockNumber'/>
          <NumberField source="buyPrice" />
          <TextInput source="name" validate={required}/>
          <TextInput source='serial'/>
          <TextInput source="naverCode"/>

          <FormDataConsumer>
            {({formData, ...rest}) => {
              const {buyPrice, originalPrice} = formData

              if (buyPrice && priceCheck) {
                formData.margin = Math.ceil((originalPrice - buyPrice) / originalPrice * 100)
              }

              return <NumberInput {...rest} source="originalPrice" onChange={(e) => {marginCheck = false; priceCheck = true;}}/>
            }}
          </FormDataConsumer>

          <FormDataConsumer>
            {({formData, ...rest}) => {
              const {originalPrice, buyPrice, margin} = formData

              if(buyPrice) {
                if(marginCheck) {
                  formData.originalPrice = Math.ceil(buyPrice / (1 - margin / 100) / 1000) * 1000
                } else if(!margin) {
                  formData.margin = Math.ceil((originalPrice - buyPrice) / originalPrice * 100)
                }
                return <NumberInput {...rest} source="margin" label="예상 마진(%)" onChange={(e) => {marginCheck = true; priceCheck = false;}}/>
              }
            }}
          </FormDataConsumer>

          <NumberInput source="discount" validate={required}/>
          <FormDataConsumer>
            {({formData, ...rest}) => {
              const {originalPrice, discount} = formData

              if (originalPrice) {
                if (discount < 100 || discount >= 0) {
                  formData.price = Math.ceil(originalPrice * (100 - discount) / 100 / 1000) * 1000
                } else {
                  delete formData.price
                }
              }
              return <NumberInput {...rest} source="price" validate={required}/>
            }}
          </FormDataConsumer>
          <BooleanInput source="onSale"/>
          <FormDataConsumer>
            {({formData, ...rest}) => {
              if (formData.optionType === 'single') {
                let count = 0
                formData.options.forEach(o => {
                  if (o && o.quantity) count += o.quantity
                })
                return <QuantityField label={translate('resources.goods.fields.quantity')}
                                      formData={formData}
                                      value={count}/>
              }
              return <NumberInput label="resources.goods.fields.quantity"
                                  source="quantity"
                                  validate={required}/>
            }}
          </FormDataConsumer>

          <RadioButtonGroupInput source="status" label="상태 설정" style={{width:'25rem'}} choices={[
            { id: 'none', name: '선택 안함' },
            { id: 'onRepair', name: '수리 중' },
            { id: 'onUseInside', name: '내부 사용 중' }
          ]} />

          <RadioButtonGroupInput source="optionType" choices={[
            {id: 'none', name: '설정안함'},
            {id: 'single', name: '설정함'}
          ]}/>

          <FormDataConsumer>
            {({formData, ...rest}) => {
              return formData.optionType === 'single' ?
                <InlineArrayInput label="resources.goods.fields.options" source="options">
                  <SimpleFormIterator>
                    <InlineDisabledInput label="resources.goods.fields.optionId" disabled source="id"/>
                    <TextInput label="resources.goods.fields.optionName"
                               source="name" style={{marginLeft: 20}} validate={required}/>
                    <InlineNumberInput label="resources.goods.fields.optionPrice"
                                       source="price"
                                       style={{marginLeft: 20}}
                                       validate={required}/>
                    <InlineNumberInput label="resources.goods.fields.quantity"
                                       source="quantity"
                                       style={{marginLeft: 20}}
                                       validate={required}/>
                  </SimpleFormIterator>
                </InlineArrayInput> : ''
            }}
          </FormDataConsumer>

          <CheckboxGroupInput source="properties" choices={[
            {id: 'sale', name: 'SALE'},
            {id: 'new', name: 'NEW'},
            {id: 'recommend', name: '추천'},
            {id: 'direct', name: '직구'}
          ]}/>

          <BooleanInput source="isTimeSale"/>
          <FormDataConsumer>
            {({formData}) => {
              return formData.isTimeSale ?
                <>
                  <InlineDateInput className={'date_input'} label="from" source="startDate" validate={required}/>
                  <InlineDateInput className={'date_input'}  label="to" source="endDate" validate={required}/>
                  <InlineNumberInput source="timeSalePrice" validate={required}/>
                </>: ''
            }}
          </FormDataConsumer>

          <ReferenceArrayInput source="eventIds" reference="events">
            <SelectArrayInput optionText="name"/>
          </ReferenceArrayInput>

          <FormDataConsumer>
            {({formData, ...rest}) => {
              return <ReferenceInput
                {...rest}
                label={translate('resources.goods.fields.titleImage')}
                source="titleImage.id"
                filter={{productId: formData.productId}}
                reference="files"
                validate={required}>
                <SelectImageInput optionText="src" title="title"/>
              </ReferenceInput>
            }}
          </FormDataConsumer>

          <ImageInput multiple source="mainImages" accept="image/*">
            <ImageField source="src" title="title"/>
          </ImageInput>

          <ImageInput multiple source="detailImages" accept="image/*">
            <ImageField source="src" title="title"/>
          </ImageInput>

          <BooleanInput source="isHtmlOverall" validate={required}/>

          <TextInput multiline source="overall" validate={required}/>
          <TextInput multiline source="memo"/>

        </SimpleForm>
      </Edit>
    )
  }
}

export default translate(GoodsEdit)
