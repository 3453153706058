import React, {useState, useEffect} from "react";
import {dataProvider, history} from "../../App";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";

import moment from 'moment'
import numeral from 'numeral'

const SellList = (props) => {

    const columns = [
        { id: 'orderId', label: '주문 번호', minWidth: 170 },
        { id: 'buyerName', label: '구매자', minWidth: 200 },
        { id: 'amount', label: '판매 가격', minWidth: 100},
        { id: 'createdAt', label: '판매 날짜', minWidth: 100, format: (value) => value.date()},
    ];

    function createData(orderId, buyerName, amount, createdAt) {
        return { orderId, buyerName, amount, createdAt };
    }

    let rows = [];

    if(props.record.sell) {
        props.record.sell.map(row => {
            rows.push(createData(row.orderId, row.buyerName, row.amount, row.createdAt));
        })
    }

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [sellList, setSellList] = useState([]);
    const [total, setTotal] = useState(0);

    useEffect(() => {

        async function fetchData() {
            const id = decodeURI((window.location.pathname).split('/')[2]);

            let options = {
                sort: 'sale',
                id: id,
                page: page,
                perPage: rowsPerPage
            }

            const {data, total} = await dataProvider('GET_DEALS_SALE', 'sales', options)
            setTotal(total);
            setSellList(data)
        }
        fetchData();

    }, [page, rowsPerPage]);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleCellClick = (row, e) => {
        e.preventDefault();

        history.push(`/orders/${row.orderId}/show`)
    }

    return (
        <Paper>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sellList.slice(0, rowsPerPage).map((row) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code} onClick={(e) => handleCellClick(row, e)}>
                                    {columns.map((column) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell key={column.id}>
                                                {column.id === 'createdAt' ? moment(value).format('YYYY-MM-DD') :
                                                    column.id === 'amount' ? numeral(value).format('0,0') :
                                                    value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    );
}

export default SellList;