import React from 'react'
import {Edit, NumberInput, SaveButton, SimpleForm, TextInput, Toolbar,} from 'react-admin'
import {required} from '../../validators'
import EditActions from '../../custom/common/EditActions'

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton submitOnEnter={false}/>
  </Toolbar>
)

export default (props) => (
  <Edit {...props} actions={<EditActions/>}>
    <SimpleForm toolbar={<EditToolbar/>} redirect="show">
      <TextInput disabled source='id'/>
      <NumberInput source="price" validate={required}/>
      <TextInput source="memo"/>
    </SimpleForm>
  </Edit>
)
