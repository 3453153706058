import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {TopToolbar, Datagrid, DateField, List, TextField, ImageField, BooleanField} from 'react-admin'
import {history} from "../../App";

const styles = theme => ({
  root: {
    '& > div': {
      overflow: 'visible'
    }
  },
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }
})

const ThumbnailImageField = withStyles({image: {height: 60}})(ImageField)

const Actions = ({basePath,}) => (
  <TopToolbar>
  </TopToolbar>
)

export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props}
          actions={<Actions/>}
          bulkActionButtons={false}>
      <Datagrid rowClick={(record, resource, row) =>
        history.push(
          `/products/${row.productId}/show?redirectUrl=${encodeURIComponent(props.location.pathname+props.location.search)}`
        )}>
        <TextField source="id" sortable={false}/>
        <ThumbnailImageField source="titleImage" sortable={false}/>
        <TextField source="title" sortable={false}/>
        <TextField source="serial" sortable={false}/>
        <TextField source="owner" sortable={false}/>
        <BooleanField source="isPurchased" sortable={false}/>
        <DateField source="createdAt" locales='ko' showTime sortable={false}/>
      </Datagrid>
    </List>
  )
})

