import React from 'react'
import {
  TopToolbar,
  TextInput,
  Edit,
  ImageField,
  ImageInput,
  ListButton,
  SimpleForm,
  SelectInput,
} from 'react-admin'
import {required} from '../../validators'
import EditToolbar from '../../custom/common/EditToolbar'

const EditActions = ({basePath, data}) => (
  <TopToolbar>
    <ListButton basePath={basePath}/>
  </TopToolbar>
)

export default (props) => (
  <Edit {...props} actions={<EditActions/>}>
    <SimpleForm toolbar={<EditToolbar/>}>
      <TextInput disabled source='id'/>
      <SelectInput source="type"
                   choices={[
                     {id: 'main', name: '메인'},
                     {id: 'goodsList', name: '상품리스트'},
                     {id: 'mainCenter', name: '메인 중앙'}
                   ]}
                   validate={required}/>
      <ImageInput source="imagePC" accept="image/*" validate={required}>
        <ImageField source="src" title="title"/>
      </ImageInput>
      <ImageInput source="imageMO" accept="image/*" validate={required}>
        <ImageField source="src" title="title"/>
      </ImageInput>
      <ImageInput source="imageAPP" accept="image/*">
        <ImageField source="src" title="title"/>
      </ImageInput>
    </SimpleForm>
  </Edit>
)
