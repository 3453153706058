import BookIcon from '@material-ui/icons/Book'
import PopupCreate from './PopupCreate'
import PopupEdit from './PopupEdit'
import PopupList from './PopupList'

export default {
  list: PopupList,
  create: PopupCreate,
  edit: PopupEdit,
  icon: BookIcon,
}
