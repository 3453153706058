import BookIcon from '@material-ui/icons/Book'
import DefaultImageCreate from './DefaultImageCreate'
import DefaultImageEdit from './DefaultImageEdit'
import DefaultImageList from './DefaultImageList'

export default {
  list: DefaultImageList,
  create: DefaultImageCreate,
  edit: DefaultImageEdit,
  icon: BookIcon,
}
