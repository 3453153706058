import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
    BooleanField,
    TopToolbar,
    Datagrid,
    DateField,
    Filter,
    List,
    SelectInput,
    TextField,
    translate,
} from 'react-admin'
import StarRatingField from '../../custom/common/StarRatingField'

const styles = {
    title: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    select: {
        height:'3rem',
        padding: '0 0',
        margin: 0
    },
    content: {
        width: '300px',
        overflow: 'hidden'
    }
}

const Actions = ({basePath,}) => (
    <TopToolbar>
    </TopToolbar>
)

const ListFilter = props => (
    <Filter {...props}>
        <SelectInput source="replied" className={props.classes.select}choices={[
            {id: false, name: '답변안함'},
            {id: true, name: '답변함'}
        ]} alwaysOn/>
    </Filter>
)

export default withStyles(styles)(translate(({classes, translate, ...props}) => {
    return (
        <List {...props}
              filters={<ListFilter classes={classes}/>}
              classes={classes}
              perPage={25}
              sort={{field: 'createdAt', order: 'DESC'}}
              actions={<Actions/>}
              bulkActionButtons={false}>
            <Datagrid rowClick="show">
                <TextField source="id" sortable={false}/>
                <TextField source="stockId" label="재고 번호"sortable={false}/>
                <TextField source="product.name" label="상품명" sortable={false}/>
                <StarRatingField source="rating" label="평점" sortable={false}/>
                <BooleanField source="replied" label="답변" sortable={false}/>
                <DateField source="createdAt" label="생성일" locales='ko' showTime sortable={false}/>
            </Datagrid>
        </List>
    )
}))

