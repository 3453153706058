import React from 'react'
import {
  TopToolbar,
  DateField,
  ListButton,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  translate,
} from 'react-admin'
import ReviewReplyButton from './ReviewReplyButton'
import CustomDeleteButton from '../../custom/common/CustomDeleteButton'
import StarRatingField from '../../custom/common/StarRatingField'

const ShowActions = ({basePath, resource, data = {}, ...props}) => {
  return (
    <TopToolbar>
      <ListButton basePath={basePath}/>
      {data.id && <ReviewReplyButton basePath={basePath} record={data}/>}
      {data.id && <CustomDeleteButton basePath={basePath} resource={resource} record={data}
                                      submitOnEnter={false}
                                      variant="dense"/>}
    </TopToolbar>
  )
}

class ReviewShow extends React.Component {
  render() {
    const {translate, ...props} = this.props
    return <Show {...props} actions={<ShowActions/>}>
      <SimpleShowLayout>
        <ReferenceField source="userId" reference="users" link="show">
          <TextField source="name"/>
        </ReferenceField>
        <ReferenceField source="orderId" reference="orders" link="show">
          <TextField source="id"/>
        </ReferenceField>
        <ReferenceField source="goodsId" reference="goods" link="show">
          <TextField source="name"/>
        </ReferenceField>
        <StarRatingField source="rating"/>
        <TextField style={{whiteSpace: 'pre'}} source="content"/>
        <TextField style={{whiteSpace: 'pre'}} source="reply"/>
        <DateField source="createdAt" locales="ko" showTime/>
      </SimpleShowLayout>
    </Show>
  }
}

export default translate(ReviewShow)
