import BookIcon from '@material-ui/icons/Book'
import CategoryCreate from './CategoryCreate'
import CategoryEdit from './CategoryEdit'
import CategoryList from './CategoryList'

export default {
  list: CategoryList,
  create: CategoryCreate,
  edit: CategoryEdit,
  icon: BookIcon,
}
