import {
  CREATE,
  DELETE,
  GET_LIST,
  GET_MANY,
  GET_ONE,
  UPDATE,
} from 'react-admin'
import {convertImagePath} from './ImageHandler'

export function request(apiUrl, type, params) {
  let url = apiUrl + '/admin/orders'
  let options = {}
  switch (type) {
    case CREATE:
      options.method = 'POST'
      options.data = params.data
      break
    case GET_MANY:
      if (params.ids.length === 1) url += `/${params.ids[0]}`
      break
    case GET_LIST:
      const {page, perPage} = params.pagination
      const {field, order} = params.sort
      options.params = {
        ...params.filter,
        sort: field,
        order: order,
        start: (page - 1) * perPage,
        perPage: perPage,
      }
      break
    case GET_ONE:
      url += `/${params.id}`
      break
    case UPDATE: {
      const data = {...params.data}
      url += `/${params.id}`
      options.method = 'PUT'
      options.data = data
      break
    }
    case 'UPDATE_STATUS': {
      const data = {...params.data}
      url += `/${params.id}/status`
      options.method = 'PUT'
      options.data = data
      break
    }
    case 'UPDATE_DELIVERY': {
      const data = {...params.data}
      url += `/${params.id}/delivery`
      options.method = 'PUT'
      options.data = data
      break
    }
    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break
    case 'DELETE_FORCE': {
      url += `/${params.id}/force`
      options.method = 'DELETE'
      break
    }
    case 'POST_UPLOAD_NAVER_ORDERS':
      options.method = 'POST'
      options.data = params
      break;
    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res
  switch (type) {
    case UPDATE:
      ret = params
      break
    case GET_LIST:
      ret = data
      break
    case GET_MANY:
      if (params.ids.length === 1) ret = {data: [data]}
      else ret = {data}
      break
    case GET_ONE:
      data.goods.forEach(g => g.titleImage = convertImagePath(g.titleImage, 'small'))
      if (data.vbank && data.vbank.expire) {
        data.vbank.expire *= 1000
      }
      ret = {data}
      break
    default:
      if (data) ret = {data}
      break
  }
  return ret
}
