import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import { Button as UploadButton, Loading } from 'react-admin';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button';
import FileUpload from '@material-ui/icons/ImportExport'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import {dataProvider} from "../../App";
import xlsx from '../../utils/xlsx'

const styles = theme => ({
    dialog: {
        width: '100%',
    },
    button: {}
});

class UploadNaverOrders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDialog: false,
            isLoading: false
        }
    }

    handleShowModal = async () => {
        this.setState({isLoading: false})
        this.setState({showDialog: true})
    }

    handleUpdate = async () => {
        this.setState({isLoading: true})
        const {file} = this.state

        const orders = await this.getFileData(file)
        if (!orders) {
            this.setState({isLoading: false})
            return
        }
        if (orders.length > 0) {
            console.log(orders)
            const {data} = await dataProvider('POST_UPLOAD_NAVER_ORDERS', 'orders', {type: 'naver', orders})
            if (data && data.success) {
                alert('완료 되었습니다.')
            }
            else {
                alert(JSON.stringify(data))
            }
            this.setState({isLoading: false})
            this.setState({showDialog: false})
        }
        else {
            alert('업로드할 데이터가 없습니다.')
            this.setState({isLoading: false})
        }
    }

    getFileData = async (file) => {
        try {
            const fileData = await xlsx.getFile(file)
            const result = []
            for (const sheetName of fileData.SheetNames) {
                const xlsxGoodsList = await xlsx.getSheetData(fileData, sheetName)
                for (let i = 0; i < xlsxGoodsList.length; i++) {

                    let errorMessage = null
                    if (!xlsxGoodsList[i][0]) errorMessage = '상품 주문 번호 오류'
                    if (!xlsxGoodsList[i][1]) errorMessage = '주문 번호 오류'
                    if (!xlsxGoodsList[i][2]) errorMessage = '주문 일시 오류'
                    if (!xlsxGoodsList[i][3]) errorMessage = '주문 상태 오류'
                    if (!xlsxGoodsList[i][5]) errorMessage = '상품 번호 오류'
                    if (!xlsxGoodsList[i][8]) errorMessage = '수량 오류'
                    if (!xlsxGoodsList[i][9]) errorMessage = '구매자 명 오류'
                    if (!xlsxGoodsList[i][11]) errorMessage = '수취인 명 오류'

                    if (errorMessage) {
                        alert(`${i+1}번째 줄의 ${errorMessage}`)
                        return
                    }

                    result.push({
                        goodsOrderId: xlsxGoodsList[i][0].toString(),
                        id: xlsxGoodsList[i][1].toString(),
                        createdAt: xlsx.date(xlsxGoodsList[i][2]),
                        status: xlsxGoodsList[i][3].toString(),
                        goodsId: xlsxGoodsList[i][5].toString(),
                        quantity: parseInt(xlsxGoodsList[i][8], 10),
                        buyerName: xlsxGoodsList[i][9].toString(),
                        receiverName: xlsxGoodsList[i][11].toString()
                    })
                }
            }
            return result
        }
        catch (err) {
            throw(err)
        }
    }

    handleCancel = () => {
        this.setState({showDialog: false})
    }

    handleChange = (e) => {
        const {name} = e.target

        this.setState({[name]: e.target.files[0]})
    }

    render() {
        const {showDialog} = this.state
        const {classes, type} = this.props

        const Progress = () => {
            const {isLoading} = this.state
            return isLoading ? <Loading loadingPrimary="" loadingSecondary="" /> : ''
        }

        const DialogButton = () => {
            const {isLoading} = this.state
            return isLoading === false ? <DialogActions>
                <Button onClick={this.handleCancel} color="primary">
                    취소
                </Button>
                <Button onClick={this.handleUpdate} color="primary">
                    확인
                </Button>
            </DialogActions> : ''
        }

        return (
          <section>
              <UploadButton
                label={'스마트스토어 주문 업로드'}
                onClick={this.handleShowModal}
              >
                  <FileUpload/>
              </UploadButton>

              <div>
                  <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    maxWidth="xs"
                    // onEntering={this.handleEntering}
                    aria-labelledby="confirmation-dialog-title"
                    open={showDialog}
                    classes={{paper: classes.dialog}}
                  >
                      <DialogTitle id="confirmation-dialog-title">스마트스토어 주문을 업로드 하시겠습니까?</DialogTitle>
                      <DialogContent>
                          <div>
                              <TextField
                                style={{width: '100%'}}
                                type={'file'}
                                name={'file'}
                                onChange={this.handleChange}
                              />
                          </div>
                          <Progress/>

                      </DialogContent>
                      <DialogButton/>
                  </Dialog>
              </div>
          </section>
        )
    }
}

export default withStyles(styles)(UploadNaverOrders)
