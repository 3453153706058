import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {makeStyles} from '@material-ui/core/styles'
import {fade} from '@material-ui/core/styles/colorManipulator'
import {Button, Confirm, crudDelete, useTranslate} from 'react-admin'
import ActionDelete from '@material-ui/icons/Delete'
import classnames from 'classnames'

const useStyles = makeStyles( theme => ({
  deleteButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      }
    }
  }
}))

export default props => {
  const [isOpen, setOpen] = useState(false)
  const translate = useTranslate()
  const classes = useStyles()
  const dispatch = useDispatch()

  function handleClick () {
    setOpen(true)
  }

  function handleDialogClose () {
    setOpen(false)
  }

  function handleConfirm () {
    const {resource, data: record, basePath} = props
    dispatch(crudDelete(resource, record.id, record, basePath))
    setOpen(true)
  }

  const {label = 'ra.action.delete', className, resource, data: record} = props
  return (
    <div>
      <Button
        onClick={handleClick}
        label={label}
        className={classnames('ra-delete-button', classes.deleteButton, className)}
        key="button"
      >
        <ActionDelete/>
      </Button>
      <Confirm
        isOpen={isOpen}
        confirmColor="warning"
        title={translate('ra.message.delete_title',
          {name: translate(`resources.${resource}.name`), id: record.id})}
        content={translate('ra.message.delete_content',
          {name: translate(`resources.${resource}.name`), id: record.id})}
        cancel={translate('ra.action.cancel')}
        confirm={translate('ra.action.confirm')}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </div>
  )
}
