import englishMessages from 'ra-language-english'
import treeEnglishMessages from 'ra-tree-language-english'
import {mergeTranslations} from 'react-admin'

export const messages = {
  ...mergeTranslations(englishMessages, treeEnglishMessages),
  ra: {
    action: {
      add_filter: '필터 추가',
      add: '추가',
      back: '뒤로',
      bulk_actions: '1 item selected |||| %{smart_count} items selected',
      cancel: '취소',
      close: '닫기',
      confirm: '확인',
      clear_input_value: 'Clear value',
      clone: '복사',
      create: '추가',
      delete: '삭제',
      edit: '수정',
      export: 'Export',
      list: '목록',
      ok: '확인',
      refresh: '새로고침',
      remove_filter: 'Remove this filter',
      remove: '삭제',
      save: '저장',
      search: '검색',
      show: '조회',
      sort: 'Sort',
      undo: 'Undo',
    },
    message: {
      about: 'About',
      are_you_sure: 'Are you sure?',
      cancel_content: '정말로 %{name} #%{id}을 취소하시겠습니까?',
      cancel_title: '%{name} #%{id} 취소',
      delete_content: '정말로 %{name} #%{id}을 삭제하시겠습니까?',
      delete_title: '%{name} #%{id} 삭제',
      details: 'Details',
      error:
        "A client error occurred and your request couldn't be completed.",
      invalid_form: '입력이 유효하지 않습니다. 에러를 확인해 주세요.',
      loading: 'The page is loading, just a moment please',
      no: 'No',
      not_found:
        'Either you typed a wrong URL, or you followed a bad link.',
      yes: 'Yes',

    },
    navigation: {
      no_results: '항목이 없습니다.',
      no_more_results:
        'The page number %{page} is out of boundaries. Try the previous page.',
      page_out_of_boundaries: 'Page number %{page} out of boundaries',
      page_out_from_end: 'Cannot go after last page',
      page_out_from_begin: 'Cannot go before page 1',
      page_range_info: '%{offsetBegin}-%{offsetEnd}, %{total}',
      page_rows_per_page: '페이지 행:',
      next: '다음',
      prev: '이전',
    },
    page: {
      create: '%{name} 생성',
      dashboard: '데시보드',
      edit: '%{name} #%{id}',
      error: 'Something went wrong',
      list: '%{name}',
      loading: 'Loading',
      not_found: 'Not Found',
      show: '%{name} #%{id}',
      empty: '리스트가 없습니다.'
    },
    input: {
      file: {
        upload_several: 'Drop some files to upload, or click to select one.',
        upload_single: 'Drop a file to upload, or click to select it.',
      },
      image: {
        upload_several: '이미지 여러 개 끌어다 넣거나, 클릭 후 여러 개 선택하십시오.',
        upload_single: '이미지를 끌어다 넣거나, 클릭해서 선택하십시오.',
      },
      references: {
        all_missing: 'Unable to find references data.',
        many_missing: 'At least one of the associated references no longer appears to be available.',
        single_missing: 'Associated reference no longer appears to be available.',
      },
    },
    auth: {
      user_menu: '메뉴',
      username: '사용자 이름',
      password: '패스워드',
      sign_in: '로그인',
      logout: '로그아웃',
      auth_check_error: '인증 에러',
      sign_in_error: '로그인 실패'
    },
    validation: {
      required: 'Required',
      minLength: 'Must be %{min} characters at least',
      maxLength: 'Must be %{max} characters or less',
      minValue: 'Must be at least %{min}',
      maxValue: 'Must be %{max} or less',
      number: 'Must be a number',
      email: 'Must be a valid email',
      oneOf: 'Must be one of: %{options}',
      regex: 'Must match a specific format (regexp): %{pattern}'
    },
    notification: {
      updated: 'Element updated |||| %{smart_count} elements updated',
      created: 'Element created',
      deleted: 'Element deleted |||| %{smart_count} elements deleted',
      bad_item: 'Incorrect element',
      item_doesnt_exist: 'Element does not exist',
      http_error: 'Server communication error',
      data_provider_error: 'dataProvider error. Check the console for details.',
      i18n_error: 'Cannot load the translations for the specified language',
      canceled: 'Action cancelled',
      logged_out: 'Your session has ended, please reconnect.'
    },
  },
  common: {
    name: '이름',
    value: '정보'
  },
  simple: {
    action: {
      close: 'Close',
      save: 'Save',
    },
  },
  auth: {
    userName: '사용자 이름',
    password: '패스워드',
    signIn: '로그인'
  },
  resources: {
    admins: {
      name: '관리자 설정',
      fields: {
        id: '번호',
        name: '아이디',
        adminName: '이름',
        affiliation: '소속',
        phone: '전화번호',
        email: '이메일',
        authority: '권한',
        affiliationId: '소속',
        emailNoti: '이메일 노티',
        password: '비밀번호',
        createdAt: '생성일',
        updatedAt: '수정일'
      }
    },
    devPosts: {
      name: '개발 업데이트 공지',
      fields: {
        type: '게시글 타입',
        id: 'ID',
        title: '제목',
        content: '내용',
        name: '작성자',
        createdAt: '생성일'
      }
    },
    operatorPosts: {
      name: '운영자 게시판',
      fields: {
        type: '게시글 타입',
        id: 'ID',
        title: '제목',
        content: '내용',
        name: '작성자',
        createdAt: '생성일'
      }
    },
    brands: {
      name: '브랜드',
      fields: {
        name: '브랜드 이름',
        image : '사진',
        brandCategories:'브랜드 카테고리',
        brandCategoryList : '브랜드 카테고리',
        keywords : '키워드',
        createdAt: '생성일',
        parentIds: '%{index} 번째 카테고리',
        brandCategoryIndex: '%{index} 번째 브랜드 카테고리',
      }
    },
    brandCategories: {
      name: '브랜드 카테고리',
      fields:{
        name: '카테고리 이름',
        image : '사진',
        createdAt: '생성일'
      }
    },
    categories: {
      name: '', // 카테고리 페이지에서 List를 2개 사용하기 때문에 어쩔 수 없이 빈칸으로 두었습니다.
      summary: '기본 정보',
      inspections: '검수 항목',
      fields: {
        name: '카테고리 이름',
        brandCategoryId : '브랜드 카테고리',
        brandId : '브랜드',
        extraIds: '추가정보',
        onDeal: '매입 가능여부',
        parentIds: '%{index}번째 카테고리',
        selectItems : '문구 설정',
        selectItem : '문구 설정',
        order: '순서',
      }
    },
    events: {
      name: '이벤트',
      fields: {
        name: '이벤트 이름',
        createdAt: '생성일',
        onSale: '노출 여부',
        backgroundColor: '백그라운드 색상(코드)',
        image: '이미지',
        desktopImage: '웹 배너',
        mobileImage: '모바일 배너',
        appImage: '앱 배너',
        descriptionImage: '설명 이미지'
      }
    },
    goods: {
      name: '상품 관리',
      fields: {
        id: '상품 번호',
        productId: '품목',
        name: '상품명',
        naverCode: '네이버 코드',
        originalPrice: '정가',
        discount: '할인율(%)',
        buyPrice: '매입가',
        price: '판매가',
        onSale: '노출 여부',
        titleImage: '대표 이미지',
        mainImages: '제품 이미지',
        detailImages: '상세 이미지',
        properties: '속성',
        eventIds: '이벤트',
        isHtmlOverall: '상세 설명 html 적용 여부',
        overall: '상세 설명',
        memo: '메모',
        creator: '생성자',
        createdAt: '생성일',
        stockNumber: '재고번호',
        serial: '시리얼번호',
        stockId: '매입 Id',
        quantity: '재고수량',
        reserved: '예약',
        optionType: '옵션설정',
        optionId: 'id',
        options: '옵션',
        optionName: '옵션명',
        optionPrice: '추가 가격',
        optionQuantity: '재고 수량',
        optionValue: '옵션값',
        wishListId: '구매희망 요청자',
        isTimeSale: '타임세일',
        division: '구분'
      }
    },
    inspections: {
      name: '검수 항목',
      fields: {
        name: '항목 이름',
        order: '순서',
        key: '키 이름',
        type: '종류',
        options: '옵션',
        onMain: '1차 선택'
      }
    },
    keywords: {
      name: '키워드 관리',
      fields: {
        createdAt: '생성일',
        keyword: '키워드',
        order: '순서',
        site :'사이트 선택'
      }
    },
    orders: {
      name: '주문 관리',
      fields: {
        amount: '결제 금액',
        buyerName: '주문자',
        buyerPhone: '휴대전화',
        createdAt: '주문일',
        deliveryFee: '배송비',
        discountPrice: '할인금액',
        goods: '상품',
        id: '주문 번호',
        method: '결제 수단',
        memo: '주문자 메모',
        pccc: '개인통관고유부호',
        name: '상품명',
        optionName: '옵션명',
        point: '포인트',
        price: '판매가',
        quantity: '수량',
        receiverName: '수령자명',
        receiverTel: '연락처',
        receiverAddress: '주소',
        status: '주문 상태',
        platform: '플랫폼',
        naverStatus: '네이버 주문 상태',
        naverClaimType: '네이버 클레임 타입',
        naverClaimStatus: '네이버 클래임 상태',
        stockNumber: '재고번호',
        title: '타이틀',
        titleImage: '이미지',
        trackingNumber: '송장번호',
        userId: '사용자',
        vbank: {
          expire: '만료시간',
          holder: '가상계좌 예금주',
          name: '가상계좌 은행',
          number: '가상계좌 번호',
        },
        vbankRefund: {
          bank: '환불계좌 은행',
          holder: '환불계좌 예금주',
          account: '환불계좌 번호',
        },
        zipCode: '우편번호'
      }
    },
    prices: {
      name: '가격 관리',
      fields: {
        titleImage: '대표 이미지',
        productId: '품목',
        name: '제품명',
        code: '제품코드',
        price: '판매가',
        buyPrice: '매입가',
        history: '수정내역',
        memo: '메모',
        createdAt: '생성일',
        updatedAt: '수정일',
      }
    },
    products: {
      name: '품목',
      summary: '기본 정보',
      extras: '추가 정보',
      inspections: '검수 항목',
      price: '가격',
      fields: {
        buyPrice: '매입가',
        title: '타이틀',
        name: '제품명',
        code: '제품코드',
        year: '출시년도',
        price: '판매가',
        onDeal: '매입 가능여부',
        brandId: '브랜드',
        brandName : '브랜드',
        titleImage: '대표 이미지',
        size: '사이즈',
        images: '제품 이미지',
        extras: '추가정보',
        prefix: '재고번호 접두사',
        createdAt: '생성일',
        inspections: '검수 항목',
        order: '순서',
        shopGoodsId: '해당 샵의 상품 ID',
        banners: '배너',
        type: '타입',
        path: '경로',
        image: '이미지',
        blogs: '블로그'
      }
    },
    questions: {
      name: '문의 관리',
      fields: {
        answer: '답변내용',
        answered: '답변여부',
        answeredAt: '답변일',
        createdAt: '생성일',
        name: '문의자',
        goodsId: '상품',
        productId: '품목',
        question: '문의내용',
        type: '문의유형',
        userId: '문의자'
      }
    },
    researches: {
      name: '가격 조사',
      fields: {
        productName: '제품명',
        productCode: '제품코드',
        source: '출처',
        type: '구분',
        price: '금액',
        memo: '메모',
        date: '날짜',
        researchName: '조사자',
        inspections: '검수항목',
        createdAt: '생성일',
      }
    },
    reviews: {
      name: '구매평 관리',
      fields: {
        createdAt: '생성일',
        content: '내용',
        goodsId: '상품',
        name: '상품명',
        keyword: '키워드',
        orderId: '주문',
        rating: '평점',
        reply: '답변',
        replied: '답변',
        userId: '사용자'
      }
    },
    stockReviews: {
      name: '매각 후기 관리',
      fields: {
          createdAt: '생성일',
          content: '내용',
          name: '상품명',
          rating: '평점',
          reply: '답변',
          replied: '답변',
          userId: '사용자',
          stockId: '재고 번호',
          productId: '상품'
      }
    },
    stocks: {
      name: '매입 관리',
      fields: {
        id: '접수번호',
        goodsId: '생성한 상품',
        productId: '품목',
        serial: '시리얼 번호',
        price: '매입금액',
        basicPrice: '기본금액',
        subPrice: '차감금액',
        order: '순서',
        memo: '메모',
        systemImage: '시스템 이미지',
        images: '검수 이미지',
        createdAt: '접수일',
        sellerId: '판매 유저',
        seller: {
          name: '판매자 이름',
          phone: '휴대 전화번호',
          bank: '입금은행',
          bankHolder: '예금주',
          bankAccount: '계좌번호',
          email: '이메일',
          birth: '생년월일',
          address: {
            zip: '우편번호',
            basic: '기본 주소',
            detail: '상세 주소'
          }
        },
        status: '상태',
        product: {
          name: '품목 이름'
        },
        inspector: {
          name: '검수자'
        },
        inspections: {
          id: 'ID',
          name: '이름',
          value: '선택결과',
          price: '금액',
          memo: '메모'
        },
        type: '종류',
        options: '옵션',
        overall: '검수총평',
        receiptIssued: '현금영수증 발급여부'
      }
    },
    users: {
      name: '사용자 관리',
      summary: '기본 정보',
      orderHistory: '구매 내역',
      questionHistory: '문의 내역',
      sellHistory: '판매 내역',
      devices: '보유 기기',
      fields: {
        id: '번호',
        name: '이름',
        phone: '전화번호',
        email: '이메일',
        accounts: '가입방식',
        orderCount: '구매 건수',
        orderPrice: '구매 금액',
        point: '포인트',
        createdAt: '가입일',
        devices: {
          createdAt: '생성일',
          serial: '시리얼 번호'
        },
        isDel: '탈퇴 여부'
      }
    },
    wishList: {
      name: '요청 관리',
      fields: {
        createdAt: '생성일',
        expireAt: '만료일',
        memo: '요청사항',
        goodsId: '상품명',
        productName: '품목명',
        productId: '품목명',
        userId: '요청자',
        userName: '요청자'
      }
    },
    affiliations: {
      name: '소속 관리',
      fields: {
        id: '번호',
        affiliation: '소속'
      }
    },
    emailSettings: {
      name: '이메일 노티 설정',
      fields: {
        id: '번호',
        name: '이름',
        isNoti: '노티'
      }
    },
    devices: {
      name: '등록 디바이스 관리',
      fields: {
        id: '번호',
        titleImage: '대표 이미지',
        title: '타이틀',
        serial: '시리얼',
        owner: '소유자',
        isPurchased: '구매 자동 등록 여부',
        createdAt: '생성일'
      }
    },
    defaultImages: {
      name: '기본 상세 이미지',
      fields: {
        id: '번호',
        image: '이미지',
        division: '구분',
        createdAt: '생성일'
      }
    },
    points: {
      fields: {
        id: '번호',
        point: '포인트',
        description: '설명',
        createdAt: '생성일'
      }
    },
    coupons: {
      fields: {
        id: '번호',
        type: '타입',
        isDirect: '코드 직접 입력',
        code: 'CODE',
        name: '이름',
        rate: '정률제',
        price: '정액제',
        benefit: {
          type: '할인 타입',
          value: '할인율 또는 금액',
          conditionPrice: '사용 조건 금액'
        },
        conditionPrice: '사용 조건 금액',
        description: '설명',
        expire: {
          type: '기간 유형',
          term: '기간(일)',
          startDate: '사용 시작일',
          endDate: '사용 종료일'
        },
        term: '기간',
        startDate: '시작일',
        endDate: '종료일',
        limit: '등록 제한',
        count: '등록수',
        closeDate: '쿠폰 등록 마감일',
        isDel: '삭제 여부',
        createdAt: '생성일'
      }
    },
    couponsBases: {
      fields: {
        id: '번호',
        type: '타입',
        isDirect: '코드 직접 입력',
        code: 'CODE',
        name: '이름',
        rate: '정률제',
        price: '정액제',
        benefit: {
          type: '할인 타입',
          value: '할인율 또는 금액',
          conditionPrice: '사용 조건 금액'
        },
        conditionPrice: '사용 조건 금액',
        description: '설명',
        expire: {
          type: '기간 유형',
          term: '기간(일)',
          startDate: '사용 시작일',
          endDate: '사용 종료일'
        },
        term: '기간',
        startDate: '시작일',
        endDate: '종료일',
        limit: '등록 제한',
        count: '등록수',
        closeDate: '쿠폰 등록 마감일',
        isDel: '삭제 여부',
        createdAt: '생성일'
      }
    },
    pointsEvents: {
      name: '포인트 이벤트 관리',
      fields: {
        name: '이름',
        point: '포인트',
        usable: '사용 여부'
      }
    },
    notices :{
      name : '공지사항 관리',
      fields :{
        type : '유형',
        title : '제목',
        createdAt : '생성일',
        site : '사이트 선택',
        siteId : '사이트 선택',
        content : '내용',
        image : '사진',
      }
    },
    businesses: {
      name: '기업 관리',
      fields: {
        id: 'id',
        status: '상태',
        name: '회사명',
        username: '이름',
        email: '이메일',
        phone: '전화번호',
        licenseImage: '사업자 파일',
        licenseNumber: '사업자 번호',
        bank: {
          name: '은행명',
          account: '계좌번호',
          holder: '예금주'
        },
        receipt: {
          price: '이번 현금 영수증 가능 금액',
          totalPrice: '총 현금 영수증 가능 금액'
        },
        createdAt : '생성일'
      }
    },
    businessReceipts: {
      name: '현금 영수증 발급 관리',
      fields: {
        id: 'id',
        status: '상태',
        business: {
          name: '회사명',
          phone: '연락처',
          email: '이메일'
        },
        price: '금액',
        product: {
          name: '품목명'
        },
        createdAt: '생성일'
      }
    },
    dashboard: {
      newOrders: '신규 구매',
      notPaids: '미입금 주문',
      monthly_revenue: 'Monthly Revenue',
      new_orders: 'New Orders',
      pending_reviews: 'Pending Reviews',
      new_customers: 'New Customers',
      pending_orders: 'Pending Orders',
      order: {
        items:
          'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
      },
      welcome: {
        title: 'Welcome to react-admin demo',
        subtitle:
          "This is the admin of an imaginary poster shop. Fell free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
        aor_button: 'react-admin site',
        demo_button: 'Source for this demo',
      },
    },
    banners: {
      name: '배너 관리',
      fields: {
        id: '번호',
        type: '구분',
        image1: 'pc',
        image2: 'mobile',
        imagePC: 'pc',
        imageMO: 'mobile',
        imageAPP: 'app'
      }
    },
    shops: {
      name: '판매처',
      fields: {
        code: 'ID',
        name: '판매처 이름',
        accountId: '아이디',
        password: '비밀번호',
        url: 'URL',
        memo: '판매처 메모',
        createdAt: '생성일'
      }
    },
    popups: {
      name: '팝업 관리',
      fields: {
        id: '번호',
        site: 'site',
        type: '타입',
        web: '웹 이미지',
        mobile: '모바일 이미지',
        order: '순서',
        startDate: '시작 날짜 (이상)',
        endDate: '마감 날짜 (이하)',
        alwaysOn: '항상 팝업 여부', // (켜져 있으면 하루 동안 열지 않기를 적용 하지 않는다.)
        createdAt: '생성일'
      }
    },
    faqs: {
      name: 'FAQ 관리',
      fields: {
        id: '번호',
        site: '대상 사이트',
        siteId: '대상 사이트',
        title: '제목',
        content: '내용',
        order: '순서',
        createdAt: '생성일'
      }
    },
    sales: {
      name: '매출 및 매입 관리'
    },
    blogs: {
      name: '블로그 관리',
      fields: {
        id: '번호',
        title: '제목',
        subTitle: '부제',
        image: '이미지',
        link: '링크',
        createdAt: '생성일'
      }
    },
    userSells: {
      name: '직거래 상품 관리',
      fields: {
        id: '번호',
        title: '제목',
        status: '상태',
        mainImage: '메인 이미지',
        detailImages: '디테일 이미지',
        price: '판매 금액',
        product: {
          id: '품목 번호',
          name: '품목 이름'
        },
        user: {
          id: '판매자 번호',
          name: '판매자명'
        },
        description: '상세 설명',
        likedCount: '좋아요 카운트',
        createdAt: '생성일'
      }
    },
    userSellOrders: {
      name: '직거래 주문 관리',
      fields: {
        id: '번호',
        status: '상태',
        price: '판매금액',
        inspectionPrice: '검수비',
        deliveryPrice: '배송비',
        amount: '결제금액',
        buyerName: '구매자명',
        buyerPhone: '구매자 연락처',
        buyerEmail: '구매자 이메일',
        receiverName: '배송지명',
        receiverPhone: '배송지 연락처',
        address: '배송지',
        zipCode: '배송지 우편번호',
        adminMemo: '관리자 메모',
        userSell: {
          id: '판매 번호',
          title: '제목',
          mainImage: '이미지',
          user: {
            id: '판매자 번호',
            name: '판매자명'
          },
        },
        user: {
          id: '구매자 번호',
          name: '구매자명'
        },
        createdAt: '생성일'
      }
    }
  },
  Unauthorized: 'Unauthorized',
  user: {
    list: {
      search: 'Search',
    },
    form: {
      summary: 'Summary',
      security: 'Security',
    },
    edit: {
      title: 'User "%{title}"',
    },
    action: {
      save_and_add: 'Save and Add',
      save_and_show: 'Save and Show',
    },
  },
  errors: {
    common: {
      required: '필수 입력 필드',
      number: '숫자만 입력 가능함'
    },
    auth: {
      notValidUserName: '유효한 이름이 아님',
      notValidPassword: '유효한 패스워드가 아님'
    },
  },
  NotFound: '찾을 수 없습니다.',
  AlreadyInUse: '이미 사용중입니다.',
  SamePassword: '기존 패스워드와 동일합니다.'
}

export default messages
