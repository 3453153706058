import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  BooleanField,
  TopToolbar,
  CreateButton,
  Datagrid,
  DateField,
  Filter,
  ImageField,
  List,
  NumberField,
  SearchInput,
  TextField,
  translate,
} from 'react-admin'

const styles = theme => ({})

const ThumbnailImageField = withStyles({image: {height: 60}})(ImageField)

const ListActions = ({basePath, ...rest}) => {
  return <TopToolbar>
    <CreateButton basePath={basePath}/>
  </TopToolbar>
}

const ListFilter = props => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn/>
  </Filter>
)

export default withStyles(styles)(translate(({classes, translate, ...props}) => {
  return (
    <List {...props}
          actions={<ListActions/>}
          filters={<ListFilter/>}
          perPage={25}
          bulkActionButtons={false}>
      <Datagrid rowClick="show">
        <TextField source="id"/>
        <ThumbnailImageField source="titleImage" sortable={false}/>
        <TextField source="title" sortable={false}/>
        <TextField source="name" sortable={false}/>
        <TextField source="code" sortable={false}/>
        <TextField source="year" sortable={false}/>
        <NumberField source="price"/>
        <NumberField source="buyPrice"/>
        <BooleanField source="onDeal" sortable={false}/>
        <DateField source="createdAt" locales='ko' sortable={false}/>
      </Datagrid>
    </List>
  )
}))

