import React from 'react'
import {Edit, FormDataConsumer, SimpleForm, TextInput,} from 'react-admin'
import {required} from '../../validators'
import EditActions from '../../custom/common/EditActions'
import EditToolbar from '../../custom/common/EditToolbar'
import OptionSelect from './OptionSelect'

export default (({classes, ...props}) => {
  return (
    <Edit {...props} actions={<EditActions/>}>
      <SimpleForm toolbar={<EditToolbar/>}>
        <TextInput source="name" validate={required}/>
        <TextInput source="key" validate={required}/>
        <FormDataConsumer>{(props) => <OptionSelect {...props}/>}</FormDataConsumer>
      </SimpleForm>
    </Edit>
  )
})

