import React from 'react'
import {connect} from 'react-redux'
import compose from 'recompose/compose'
import {
  Edit,
  FormDataConsumer,
  FormTab,
  GET_ONE,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  TabbedForm,
  TextInput,
  Toolbar,
  translate
} from 'react-admin'
import {required} from '../../validators'
import SelectCategories from '../categories/SelectCategories'
import EditActions from '../../custom/common/EditActions'
import {dataProvider} from '../../App'
import TextField from '@material-ui/core/TextField'

const BasicPriceField = ({classes, label, value}) => {
  return <TextField label={label} margin="normal"
                    disabled value={value}/>
}

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton submitOnEnter={false}/>
  </Toolbar>
)

const StatusInput = (props) => {
  let choices = [
    {id: 'inspected', name: '검수완료'},
    {id: 'agreed', name: '고객동의'},
    {id: 'paid', name: '입금완료'},
    {id: 'cancelled', name: '취소'},
  ]
  return <SelectInput {...props} choices={choices}/>
}

class StockEdit extends React.Component {
  constructor(props) {
    super(props)
    const inspections = props.formData
    this.state = {product: null, productId: null, inspections: []}
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && this.props.formValues && !this.state.productId) {
      console.log(this.props.formValues)
      const value = this.props.formValues.productId
      const {status} = this.props.formValues
      if (status === 'registered' || status === 'waiting' || status === 'warehousing') {
        this.props.formValues.status = 'inspected'
      }
      this.setState({productId: value})
      dataProvider(GET_ONE, 'products', {id: value})
        .then(({data}) => {
          this.setState({product: data})
          console.log(data)
        })
    }
  }

  render() {
    const {categories, translate, basePath, id, resource, location, match} = this.props
    const {inspections} = this.props
    return (
      <Edit basePath={basePath} id={id} resource={resource} location={location} match={match} actions={<EditActions/>}>
        <TabbedForm
          redirect={'show'}
          toolbar={<EditToolbar/>}
          defaultValue={{images: []}}>
          <FormTab label={translate('resources.products.summary')}>
            <FormDataConsumer>
              {props => <SelectCategories {...props} categories={categories}/>}
            </FormDataConsumer>

            <FormDataConsumer>
              {(props) => {
                return <ReferenceInput
                  disabled
                  source="productId"
                  resource={props.resource}
                  reference="products"
                  onChange={(e, value) => this.handleProductChange(props.formData, props.dispatch, value)}
                  validate={required}>
                  <SelectInput optionText="name"/>
                </ReferenceInput>
              }
              }
            </FormDataConsumer>

            <StatusInput source="status"/>

            <TextInput source="serial"/>
            <TextInput multiline source="memo"/>
            <ImageInput source="systemImage" accept="image/*" >
              <ImageField source="src" title="title"/>
            </ImageInput>

            <ImageInput multiple source="images" accept="image/*">
              <ImageField source="src" title="title"/>
            </ImageInput>
          </FormTab>
          <FormTab label='판매자 정보'>
            <TextInput source="seller.name"/>
            <TextInput source="seller.phone"/>
            <TextInput source="seller.bank"/>
            <TextInput source="seller.bankAccount"/>
            <TextInput source="seller.bankHolder"/>
            <TextInput source="seller.email"/>
            <TextInput source="seller.birth"/>
            <TextInput source="seller.address.zip"/>
            <TextInput source="seller.address.basic"/>
            <TextInput source="seller.address.detail"/>
          </FormTab>
          <FormTab label={translate('resources.inspections.name')}>
            <FormDataConsumer>
              {({formData}) => {
                return formData.inspections.map((inspection, index) => {
                  if (inspection.optionId) {
                    const price = inspection.price
                    if (!!inspection.options.find(o => o.price === price)) {
                      formData.inspections[index].price =
                        inspection.options.find(o => o.id === inspection.optionId).price
                    }
                  }
                  return <div key={inspection.id}>
                      <TextInput disabled label={translate('resources.stocks.fields.inspections.id')}
                                 source={`inspections[${index}].id`}/>
                      <SelectInput label={inspection.name}
                                   source={`inspections[${index}].optionId`}
                                   choices={inspection.options}
                                   onChange={(e) => {
                                     this.props.formValues.inspections[index].price =
                                       inspection.options.find(o => o.id === e.target.value).price
                                     this.setState({})
                                   }}

                                   optionValue={'id'}
                                   style={{marginLeft: 20}}
                                   validate={required}/>
                      <NumberInput label={translate('resources.stocks.fields.inspections.price')}
                                   disabled={!formData.inspections[index] || !formData.inspections[index].optionId}
                                   source={`inspections[${index}].price`}
                                   style={{marginLeft: 20}}
                                   validate={required}/>
                      <TextInput label={translate('resources.stocks.fields.inspections.memo')}
                                 source={`inspections[${index}].memo`}
                                 style={{marginLeft: 20}}/>
                    </div>
                  }
                )
              }}
            </FormDataConsumer>
            {this.state.product && <BasicPriceField label={translate('resources.stocks.fields.basicPrice')}
                                                    value={this.state.product.buyPrice}/>}
            <FormDataConsumer>
              {({formData}) => {
                if (!this.state.product || !formData.inspections) return null
                let subPrice = 0
                formData.inspections.filter(i => i.optionId)
                  .forEach(i => subPrice += i.price)
                return <BasicPriceField label={translate('resources.stocks.fields.subPrice')} value={subPrice}/>
              }}
            </FormDataConsumer>
            <FormDataConsumer>
              {({formData}) => {
                if (!this.state.product || !formData.inspections) return null
                return <NumberInput label={translate('resources.stocks.fields.price')}
                                    source="price" validate={required}/>
              }}
            </FormDataConsumer>
            <TextInput multiline source="overall"/>
          </FormTab>
        </TabbedForm>
      </Edit>
    )
  }
}

const mapStateToProps = (state, props) => {
  const form = state.admin.resources[props.resource]
  if (form) {
    return {formValues: form.data[props.id]}
  }
  return {}
}

const enhance = compose(
  translate,
  connect(
    mapStateToProps,
  )
)

export default enhance(StockEdit)

