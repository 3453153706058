import {
  CREATE, DELETE,
  GET_LIST, GET_MANY,
  GET_ONE,
  UPDATE,
  GET_MANY_REFERENCE
} from 'react-admin'
import {convertImagePath, uploadToS3} from './ImageHandler'
import _ from 'lodash'

export async function request(apiUrl, type, params) {
  let url = apiUrl + '/admin/stocks'
  let options = {}

  switch (type) {
    case CREATE:
    case UPDATE: {
      const data = _.omitBy(params.data, _.isNull)
      if (type === UPDATE) {
        url += `/${params.id}`
        options.method = 'PUT'
      } else {
        options.method = 'POST'
        delete data.parentIds
      }
      if (data.seller && data.seller.phone) {
        data.seller.phone = data.seller.phone.trim().replace(/-/g, '')
      }
      if (data.systemImage) options.data = await uploadToS3(data, 'systemImage', 'image')
      options.data = await uploadToS3(data, 'images', 'image')
      break
    }
    case 'UPDATE_STATUS': {
      const data = {...params.data}
      url += `/${params.id}/status`
      options.method = 'PUT'
      options.data = data
      break
    }
    case 'UPDATE_MEMO': {
      url += `/${params.id}/memo`
      options.method = 'PUT'
      options.data = params.data
      break
    }
    case GET_MANY_REFERENCE:
    case GET_LIST:
      const {categoryId} = params
      if (categoryId) {
        options.params = params
      }
      else {
        const {page, perPage} = params.pagination
        const {field, order} = params.sort
        options.params = {
          ...params.filter,
          sort: field,
          order: order,
          start: (page - 1) * perPage,
          perPage: perPage,
        }
      }
      break
    case GET_ONE:
      url += `/${params.id}`
      break
    case GET_MANY:
      if (params.ids.length === 1) url += `/${params.ids[0]}`
      break
    case DELETE:
      options.method = 'DELETE'
      url += `/${params.id}`
      break
    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res
  switch (type) {
    case GET_MANY_REFERENCE:
    case GET_LIST:
      data.data.forEach((item) => {
        if (!item.serial) item.serial = '-'
        if (!item.admin) item.admin = '-'
        if (item.seller) {
          if (!item.seller.name) item.seller.name = '-'
          if (!item.seller.phone) item.seller.phone = '-'
        }
        if (!item.inspector.name) item.inspector.name = '-'
      })
      ret = data
      break
    case GET_MANY:
      if (params.ids.length === 1) ret = {data: [data]}
      else ret = {data}
      break
    case GET_ONE:
      if (data.systemImage) data.systemImage.src = convertImagePath(data.systemImage.src, 'large')
      data.images.forEach(image => image.src = convertImagePath(image.src, 'large'))
      data.inspections = data.inspections.sort((a, b) => a.order - b.order)
      ret = {data}
      break
    default:
      if (data) ret = {data}
      break
  }
  return ret
}
