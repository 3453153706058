import React from 'react'
import {Create, SimpleForm, SelectInput, ImageInput, ImageField, GET_LIST, translate} from 'react-admin'
import {required} from '../../validators'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'
import {dataProvider} from "../../App";
import * as choices from './choices'

class DefaultimageCreate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {division: []}
  }

  async componentDidMount() {
    const division = choices.division

    const result = await dataProvider(GET_LIST, 'defaultImages', {filter: {}})

    result.data.forEach(data => {
      division.forEach(item => {
        if (item.id === data.division) {
          item.disabled = true
        }
      })
    })

    this.setState({division})
  }

  render() {
    const {division} = this.state
    return <Create {...this.props} actions={<CreateActions/>}>
      <SimpleForm
        toolbar={<CreateToolbar/>}>
        <SelectInput source="division" choices={division} validate={required}/>
        <ImageInput source="image" accept="image/*" validate={required}>
          <ImageField source="src" title="title"/>
        </ImageInput>
      </SimpleForm>
    </Create>
  }
}

export default translate(DefaultimageCreate)
