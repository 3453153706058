import React from 'react'
import {Create, SimpleForm, TextInput, SelectInput, NumberInput, DateInput, FormDataConsumer, BooleanInput} from 'react-admin'
import {required} from '../../validators'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'
import {types, benefitTypes, expireTypes} from './couponChoices'

const CustomDateInput = () => {
  return (
    <div style={{width: '500px'}}>
      <DateInput label={'resources.coupons.fields.expire.startDate'}
                 source="expire.startDate"
                 style={{marginRight: '10px', marginBottom: '-14px'}}
                 validate={required}
      />
      <DateInput label={'resources.coupons.fields.expire.endDate'}
                 source="expire.endDate"
                 style={{marginRight: '10px', marginBottom: '-14px'}}
                 validate={required}
      />
    </div>
  )
}

const ConditionPrice = () => {
  return (
    <div>
      <h5 style={{margin: '0px'}}>0원 이면 조건 없음</h5>
      <NumberInput label={'resources.coupons.fields.benefit.conditionPrice'} source="benefit.conditionPrice" validate={required}/>
    </div>
  )
}

const Limit = () => {
  return (
    <div>
      <h5 style={{margin: '0px'}}>0이면 무제한 등록 가능</h5>
      <NumberInput label={'resources.coupons.fields.limit'} source="limit" validate={required}/>
    </div>
  )
}

export default (props) => {
  return (
    <Create {...props} actions={<CreateActions/>}>
      <SimpleForm
        toolbar={<CreateToolbar/>}
        initialValues={{
          benefit: {type: null, value: null, conditionPrice: 0},
          expire: {type: 'term'},
          limit: 100,
          isDirect: false
        }}
      >
        <SelectInput source="type" choices={types} validate={required}/>
        <BooleanInput source="isDirect" validate={required}/>
        <FormDataConsumer>
          {({formData}) => {
            return formData && formData.isDirect &&
              <TextInput source="code" validate={required}/>
          }

          }
        </FormDataConsumer>


        <TextInput source="name" validate={required}/>
        <SelectInput source="benefit.type" choices={benefitTypes} validate={required}/>
        <NumberInput source="benefit.value" validate={required}/>

        <ConditionPrice/>

        <h5>term선택 시 등록 시점 기간 적용, 자동 발급 시는 발급 시점 기간 적용</h5>
        <SelectInput source="expire.type" choices={expireTypes} validate={required}/>
        <FormDataConsumer>
          {({formData}) => {
            return formData && formData.expire && formData.expire.type === 'term' ?
              <NumberInput label={'resources.coupons.fields.expire.term'} source="expire.term" validate={required}/>
              :
              <CustomDateInput />
          }

          }
        </FormDataConsumer>
        <Limit/>
        <TextInput source="description"/>
        <DateInput source="closeDate" validate={required}/>
      </SimpleForm>
    </Create>
  )
}
