import React from 'react'
import {Edit, SimpleForm, TextInput,} from 'react-admin'
import {required} from '../../validators'
import EditActions from '../../custom/common/EditActions'
import EditToolbar from '../../custom/common/EditToolbar'

export default (props) => (
  <Edit {...props} actions={<EditActions/>}>
    <SimpleForm toolbar={<EditToolbar/>}>
      <TextInput disabled source='id'/>
      <TextInput source="affiliation" validate={required}/>
    </SimpleForm>
  </Edit>
)
